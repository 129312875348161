// import { useTranslation } from "react-i18next";
// import './i18n/config';
// export const Settings = () => {
//   const { t } = useTranslation();

//   return (
//   <h1>{t("settings_logout")}</h1>
//       );
//   }
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button,Link,Divider } from "@mui/material";
import { Container } from "@mui/material";


import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import IconButton from "@mui/material/IconButton";
import { ExpandMore, ImportContacts } from "@mui/icons-material";





import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SubHeader from "../../Layout/Subheader";

import * as settings_service from "../../Services/settings";

import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/config";

// const Set = () => {

// fetch
// var trans = fetch("translationsEN.                   json")
// fetch('./translationsEN.json')
// .then(response => response.json())
// .then(response => console.log(JSON.stringify(response)))

 // trans["dashboard_cost_centre"]


const IOSSwitch = styled((props) => (
  
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#1976d2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));


 



export const Settings = (props) => {
  const { t } = useTranslation();

  

    const [age, setAge] = React.useState('');

    const [me, setMe] = React.useState('');


  const handleChange = (event) => {

    localStorage.setItem('mylang', JSON.stringify(event.target.value));
    i18n.changeLanguage(event.target.value)
    // setAge(event.target.value);
    props.setLang(event.target.value); 
    console.log("event value..." , event.target.value)
    // console.log("------------------>",lang)
  };

  const access_token=localStorage.getItem("access_token")
    

  const refresh_token=localStorage.getItem("refresh_token")

  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`

  React.useEffect(() => {

    settings_service.getMe(setMe);

}, []);
  

  return (
    <><SubHeader backbutton={true} btnUrl={dashurl} title={t("settings_settings")} /><TableContainer component={Paper}>

      <Table aria-label="simple table">
        <TableHead>
          <TableRow key="5">
            <TableCell>

              <Grid 
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
                xs={12}
                sm={6}
              >
                
                <Grid >

                  <AccountCircleOutlinedIcon fontSize="large" sx={{ color: '#004bA0' }} />
                </Grid>
                <Grid>
                  <Typography marginLeft={2} className="settingsemployeenamelabel" >

                    {me?me.name:"Not Available"}
                  </Typography>

                  <Typography marginLeft={2} className="settingsemployeemaillabel">
                    <Box sx={{ color: '#757575' }}>{me?me.email:"Not Available"}</Box>
                  </Typography>
                </Grid>
                
              </Grid>
            </TableCell>
            <TableCell align="right" />
          </TableRow>


          {/* <TableRow key="1">
            <TableCell>Lorem ipsum dolor</TableCell>
            <TableCell align="right">

              <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />} />

            </TableCell>
          </TableRow> */}


          
          <TableRow key="2">
            <TableCell>{t("settings_app_language")}</TableCell>
            <TableCell align="right">
              <FormControl variant="standard" sx={{ width: '100%' }}>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.lang}
                  label="my langauge"
                  onChange={handleChange}
                >
                  <MenuItem value={"en"}>EN</MenuItem>
                  <MenuItem value={"de"}>DE</MenuItem>

                </Select>
              </FormControl>
            </TableCell>
          </TableRow>


          <TableRow key="2">
          <TableCell><Link href="https://forms.office.com/r/QJtbXUezSD" target="_blank"  sx={{  color: "#004bA0" }}>{t("settings_give_feedback")}</Link></TableCell>
            <TableCell align="right">
              
            </TableCell>
            
          </TableRow>

        
          <TableRow key="3">
            <TableCell>
            {/* <Link href="https://login.microsoftonline.com/64a1d55f-dfaa-4056-8c8a-f757374d5db4/oauth2/v2.0/logout" underline="none"> */}
            <Link href="https://backend.dev.commsapp.eathappy.structr.com/oauth/auth0/login?createTokens=true" underline="none">
            
            
              
              <Typography className="logoutlabel" >
              
                Log out
                </Typography>
              
              </Link>
              </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer></>
  );
};
