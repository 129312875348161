// import axios from "axios";

// let refreshToken = localStorage.getItem("refresh_token");
// let accessToken = localStorage.getItem("access_token");

// export function renewAccessToken() {
//   return axios.post(`https://backend.dev.commsapp.eathappy.structr.com/structr/rest/token`, { "refresh_token": refreshToken })
//     .then((response) => {
//       console.log("*****accesstoken*****", response.data.result.access_token)
//       console.log("******refreshtoken****", response.data.result.refresh_token)

//       // Update the access token with the new one
//       accessToken = response.data.result.access_token;
//       localStorage.setItem("access_token", accessToken);
//       localStorage.setItem("refresh_token", response.data.result.refresh_token);

//       console.log('Access token renewed!', response);

//       return accessToken;
//     })
//     .catch((error) => {
//       console.error('Failed to renew access token', error);
//       return null;
//     });
// }

// // Renew the access token every 5 minutes
// setInterval(() => {
//   console.log("Renewing access token...");
//   renewAccessToken()
//     .then((newAccessToken) => {
//       console.log("New access token:", newAccessToken);
//     });
// }, 5 * 60 * 1000);

// export default renewAccessToken;



///////////////////////////////////////////////////////////////////////////////////




import axios from "axios";


export function renewAccessToken() {

    let refreshToken = localStorage.getItem("refresh_token")
    let accessToken = localStorage.getItem("access_token")

  return axios.post(`https://backend.dev.commsapp.eathappy.structr.com/structr/rest/token`, { "refresh_token": refreshToken })
    .then((response) => {
      console.log("*****accesstoken*****", response.data.result.access_token)
      console.log("******refreshtoken****", response.data.result.refresh_token)

      // Update the access token with the new one
    //   accessToken = response.data.result.access_token;
      localStorage.setItem("access_token", response.data.result.access_token)
      localStorage.setItem("refresh_token", response.data.result.refresh_token)

      console.log('Access token renewed!', response);

      return accessToken;
    })
    .catch((error) => {
      console.error('Failed to renew access token', error);
      return null;
    });
}

// Renew the access token every 5 minutes
setInterval(() => {
  console.log("Renewing access token...");
  renewAccessToken();
}, 60000);

// ///////////////////////////////////////////////////////////////////

// import axios from "axios";

// let refreshToken = localStorage.getItem("refresh_token")

// export function renewAccessToken() {
//     var accessToken = ""
//   axios.post(`https://backend.dev.commsapp.eathappy.structr.com/structr/rest/token`, { "refresh_token": refreshToken })
//     .then((response) => {
//       console.log("*****accesstoken*****", response.data.result.access_token)
//       console.log("******refreshtoken****", response.data.result.refresh_token)

//       // Update the access token with the new one
//        accessToken = response.data.result.access_token;
//       localStorage.setItem("access_token", accessToken)
//       localStorage.setItem("refresh_token", response.data.result.refresh_token)

//       console.log('Access token renewed!', response);


//       // Now that the access token has been updated, you can use it to make API requests
//       // ...
//     })
//     .catch((error) => {
//       console.error('Failed to renew access token', error);
//     });
//     // setToken(accessToken)
// }

// // console.log("initial access token", accessToken);

// // Renew the access token every 5 minutes
// setInterval(() => {
//     console.log("Renewing access token...");
//     renewAccessToken();
// }, 60000); // renew every 5 minutes
