import axios from "axios";

export function getTemperature(setTemperature, posId) {
  const access_token = localStorage.getItem("access_token");
  axios
    .post(
       `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_Temperature_Data/getTemperatureDetails/details`, 
       {"costCenter":posId},
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  let temperature = []
  // console.log(result)
  temperature = result
setTemperature(temperature)

    });





}

