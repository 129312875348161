import axios from "axios";

export function getContacts(setContacts, posId, setShoptype) {
  const access_token = localStorage.getItem("access_token");
  axios
    .get(
       `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/costcenterwithpositions`,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  let contact = []
  // console.log(result)
  contact = result.positions
  setShoptype(result.shopType)
setContacts(contact)

    });





}