import React, {useRef} from 'react'
import Webcam from 'react-webcam'


const AllCameras = () => {

    const [devices,setDevices] = React.useState([]);
    const handledevices = React.useCallback((mediaDevices) => 
        setDevices(mediaDevices.filter(({kind}) => kind=== "videoinput"))
        [setDevices]
    )

    React.useEffect(() =>{
        navigator.mediaDevices.enumerateDevices().then(handledevices)
    },[handledevices])

  return (
    <>
    {
        devices.map((device,key) =>(
            <dev key={key}>
                <Webcam
                audio={false}
                videoConstraints={{deviceId:device.deviceId}}
                />
                {device.label || 'Device ${key + 1}'}
            </dev>
        ))
    }
    </>
  )
}

export default AllCameras