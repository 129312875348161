import axios from "axios";

export function getData(setData, posId) {
  const accesstoken = localStorage.getItem("access_token");
  axios
    .get(
       `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/costcenterwithpos`,
      {
        headers: { Authorization: `Bearer ${accesstoken}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  let data = []
  // console.log(result)
  data = result
setData(data)

    });
}




// import axios from "axios";
// import { renewAccessToken } from "./token";

// export function getData(setData, posId) {
//   const accessToken = localStorage.getItem("access_token");
//   const refreshToken = localStorage.getItem("refresh_token");

//   const isTokenExpired = (accessTokenExpiration) => {
//     const now = new Date();
//     const expiration = new Date(accessTokenExpiration * 1000);

//     return now >= expiration;
//   };

//   if (isTokenExpired(localStorage.getItem("access_token_expiration"))) {
//     renewAccessToken()
//       .then((newAccessToken) => {
//         accessToken = newAccessToken;
//         localStorage.setItem("access_token", accessToken);
//         localStorage.setItem("access_token_expiration", Date.now() + (10 * 60 * 1000)); // Set the new expiration time to 10 minutes from now
//         console.log("New access token:", accessToken);
      
//         axios
//           .get(
//             `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/costcenterwithpos`,
//             {
//               headers: { Authorization: `Bearer ${accessToken}`,'Content-Type':'application/json' },
//             }
//           )
//           .then((res) => {
//             console.log(res.data);
//         const result = res.data.result
//         let data = []
//         // console.log(result)
//         data = result
//       setData(data)
//     });
//           });
//       }
           
//     }
  

export function getImages(setImages, posId) {
    const access_token = localStorage.getItem("access_token");
    axios
      .get(
         `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/lastWeekPictures`,
        {
          headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
        }
      )
      .then((res) => {
        console.log(res.data);
    const result = res.data.result
    let images = []
    
    result.poses.map((item, index) => {
       const lastWeekPictures = item.lastWeekPictures
       lastWeekPictures.map((item1) => {
            const sbdate = item1.submissionDateTime.split("–") 
            images.push({                                
                sharepointUrl : item1.sharepointUrl, submissionDate : sbdate[0], submissionTime : sbdate[1]
            })
       })
    })
    const sortedImages = images.sort(
      (objA, objB) => Number(objB.submissionDate) - Number(objA.submissionDate),
    );
    
    console.log("sorted images",sortedImages)
    setImages(sortedImages)
      });
      
}
