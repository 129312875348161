import React from 'react'
import { Layout } from './Layout';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Dashboardtest } from './screens/Dashboardtest';
import { ReportO } from './screens/ReportO';
import { TicketO } from './screens/TicketO';

import { Dashboard } from './screens/Dashboard';
import { Data } from './screens/Data/CA data';
// import { Checkin } from './screens/Data/CA Checkin';
import { Temperature } from './screens/Temperature/CA temp';
import { Contact } from './screens/Contact/CA contact';
import { TForm } from './screens/Tickets/CA form';
// import { Note } from './screens/Reports/CA note';
import { Reports } from './screens/Reports/CA reports';
import { Tickets } from './screens/Tickets/CA tickets';
import { Settings } from './screens/Setings/CA settings';
import { Report1 } from './screens/Reports/Ca report1';
import { Report2 } from './screens/Reports/Ca report2';
import { Report3 } from './screens/Reports/Ca report3';
import { Error } from './screens/Error';
import { Dashboard2 } from './screens/Dashboard2';
import { Data1 } from './screens/Data/CA data1';
import { Data2 } from './screens/Data/CA data2';
import { Data3 } from './screens/Data/CA data3';
import { Report11 } from './screens/Reports/CA report11';
import { Report12 } from './screens/Reports/CA report12';
import { Report22 } from './screens/Reports/CA report22';
import { Report23 } from './screens/Reports/CA report23';
import { Report24 } from './screens/Reports/CA report24';
import { Report25 } from './screens/Reports/CA report25';
import { CForm } from './screens/Contact/CA form';
import { TForm2 } from './screens/Tickets/CA form2';

import { Overview, overview } from './screens/Reports/Overview';
// import { Loginredirect } from './screens/loginredirect';
import AllCameras from './Components/allcamera';
import CameraT from './Components/Cameraticket';
import CameraSR from './Components/Camerashortreport';
import CameraLR from './Components/Cameralongreport'
import { Egg } from '@mui/icons-material';
import { Eegg } from './screens/Eegg';
import { Videorec } from './screens/testingrec';
import { Jira11 } from './screens/Jira 11';
import { Jira12 } from './screens/Jira 12';
import { Report11_de } from './screens/Reports/DemoCreatereport';
import { Report12_logistics } from './screens/Reports/DemoShortreport';
import { Cleaning } from './screens/Cleaning/Cleaning';
import { Report12_expansion } from './screens/Reports/ReportExpansion';
import { Report12_qs } from './screens/Reports/ReportQS';
import { Report12_marketing } from './screens/Reports/ReportMarketing';
import { Report12_hr } from './screens/Reports/ReportHR';
import { Report12_ops } from './screens/Reports/ReportOPS';
import * as tkn from './Services/token'

    function PrivateRoute({ isAuthenticated, children, ...rest }) {
        const auth = isAuthenticated;
        if (auth){
            tkn.renewAccessToken();
        }
        return auth ? (
            <Layout>
                {children}
            </Layout>
            
          )
           : window.location.replace("https://backend.dev.commsapp.eathappy.structr.com/oauth/auth0/login?createTokens=true");
      }


export const Urls = (props) => {

    
        
    // console.log(props)
    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // console.log(params)
    const access_token=localStorage.getItem("access_token")
    

    const refresh_token=localStorage.getItem("refresh_token")
    
    let isAuthenticated = false

    if (access_token!=undefined && refresh_token!=undefined){
        isAuthenticated = true
      }

// console.log(isAuthenticated)

    return (
        <BrowserRouter>
            <Routes>

            

           

                {/* <Route path="/Dashboardtest" element={<Layout><Dashboardtest {...props} /></Layout>} />
                <Route path="/ReportO" element={<Layout><ReportO {...props} /></Layout>} />
                <Route path="/TicketO" element={<Layout><TicketO {...props} /></Layout>} />
                <Route path="/Dashboard2" element={<Layout><Dashboard2 {...props} /></Layout>} />
                <Route path="/Data/Checkin" element={<Layout><Checkin {...props} /></Layout>} />
                <Route path="/Reports/note" element={<Layout><Note {...props} /></Layout>} />
                <Route path="/Data1" element={<Layout><Data1 {...props} /></Layout>} />
                <Route path="/Data3" element={<Layout><Data3 {...props} /></Layout>} /> */}

                <Route path="/" element={<Layout><Dashboard {...props} /></Layout>} />
                <Route path="/Dashboard" element={<Layout><Dashboard {...props} /></Layout>} />
                {/* urtls under perivate route below */}
                <Route path="/Settings" element={<PrivateRoute isAuthenticated={isAuthenticated}><Settings {...props} /></PrivateRoute>} />
                <Route path="/Data" element={<PrivateRoute isAuthenticated={isAuthenticated}><Data {...props} /></PrivateRoute>} />
                <Route path="/Temperature" element={<PrivateRoute isAuthenticated={isAuthenticated}><Temperature {...props} /></PrivateRoute>} />
                <Route path="/Contacts" element={<PrivateRoute isAuthenticated={isAuthenticated}><Contact {...props} /></PrivateRoute>} />
                <Route path="/Reports" element={<PrivateRoute isAuthenticated={isAuthenticated}><Reports {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report1" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report1 {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report2" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report2 {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report3" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report3 {...props} /></PrivateRoute>} />
                <Route path="/Tickets" element={<PrivateRoute isAuthenticated={isAuthenticated}><Tickets {...props} /></PrivateRoute>} />
                <Route path="/Tickets/form" element={<PrivateRoute isAuthenticated={isAuthenticated}><TForm {...props} /></PrivateRoute>} />
                <Route path="/Error" element={<PrivateRoute isAuthenticated={isAuthenticated}><Error {...props} /></PrivateRoute>} />
                <Route path="/Data2" element={<PrivateRoute isAuthenticated={isAuthenticated}><Data2 {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report11" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report11 {...props} /></PrivateRoute>} />
                {/* <Route path="/Reports/Report12" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report12 {...props} /></PrivateRoute>} /> */}
                <Route path="/Reports/Report22" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report22 {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report23" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report23 {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report24" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report24 {...props} /></PrivateRoute>} />
                {/* <Route path="/Contacts/form" element={<PrivateRoute isAuthenticated={isAuthenticated}><CForm {...props} /></PrivateRoute>} /> */}
                
                <Route path="/Eegg" element={<PrivateRoute isAuthenticated={isAuthenticated}><Eegg {...props} /></PrivateRoute>} />
                
                {/* need to uncomment for dev */}
                <Route path="/Tickets/form2" element={<PrivateRoute isAuthenticated={isAuthenticated}><TForm2 {...props} /></PrivateRoute>} />
                <Route path="/Reports/Report25" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report25 {...props} /></PrivateRoute>} />
                {/* <Route path="/Reports/Report12" element={<PrivateRoute isAuthenticated={isAuthenticated}><Report12 {...props} /></PrivateRoute>} /> */}
                
                
                
                <Route path="/Reports/Overview" element={<PrivateRoute isAuthenticated={isAuthenticated}><Overview {...props} /></PrivateRoute>} />
                {/* <Route path="/Data1" element={<PrivateRoute isAuthenticated={isAuthenticated}><Data1 {...props} /></PrivateRoute>} /> */}
                <Route path="/allcameras" element={<Layout><AllCameras {...props} /></Layout>} />
                <Route path="/cameraT" element={<Layout><CameraT {...props} /></Layout>} />
                <Route path="/CameraSR" element={<Layout><CameraSR {...props} /></Layout>} />
                <Route path="/CameraLR" element={<Layout><CameraLR {...props} /></Layout>} />

                {/* test */}
                {/* <Route path="/Reports/Report22" element={<Layout><Report22 {...props} /></Layout>} />
                <Route path="/Reports/Report25" element={<Layout><Report25 {...props} /></Layout>} />
                <Route path="/Reports/Report12" element={<Layout><Report12 {...props} /></Layout>} />
                <Route path="/Reports/Report23" element={<Layout><Report23 {...props} /></Layout>} />
                <Route path="/Reports/Report24" element={<Layout><Report24 {...props} /></Layout>} /> */}
                {/* <Route path="/Reports/Report23" element={<Layout><Report23 {...props} /></Layout>} /> */}
                {/* <Route path="/Reports/Report11" element={<Layout><Report11 {...props} /></Layout>} /> */}

                {/* <Route path="/Reports/Report25" element={<Layout><Report25 {...props} /></Layout>} />
                <Route path="/Tickets/form2" element={<Layout><TForm2 {...props} /></Layout>} /> */}

{/* <Route path="/Eegg" element={<Layout><Eegg {...props} /></Layout>} /> */}
<Route path="/Reports/Report12" element={<Layout><Report12 {...props} /></Layout>} />
<Route path="/vid" element={<Layout><Videorec {...props} /></Layout>} />
<Route path="/Jira11" element={<Layout><Jira11 {...props} /></Layout>} />
<Route path="/Jira12" element={<Layout><Jira12 {...props} /></Layout>} />
<Route path="/Reports/report11_de" element={<Layout><Report11_de {...props} /></Layout>} />
<Route path="/Reports/report12_logistics" element={<Layout><Report12_logistics {...props} /></Layout>} />
<Route path="/Reports/report12_expansion" element={<Layout><Report12_expansion {...props} /></Layout>} />
<Route path="/Reports/report12_qs" element={<Layout><Report12_qs {...props} /></Layout>} />
<Route path="/Reports/report12_marketing" element={<Layout><Report12_marketing {...props} /></Layout>} />
<Route path="/Reports/report12_hr" element={<Layout><Report12_hr {...props} /></Layout>} />
<Route path="/Reports/report12_ops" element={<Layout><Report12_ops {...props} /></Layout>} />


<Route path="/Cleaning" element={<Layout><Cleaning {...props} /></Layout>} />



            </Routes>
            
        </BrowserRouter>




    )
}
