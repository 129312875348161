import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { height } from "@mui/system";
import Container from "@mui/material/Container";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

import image from "../../Assets/Images/Chillers/chiller1.jpg";

import SubHeader from "../../Layout/Subheader";




import Fab from "@mui/material/Fab";

import { getData } from "../../Services/Data";
import * as data_service from "../../Services/Data";

import * as token_service from "../../Services/token"

import { getId } from "../../Services/Posid";
import * as id_service from "../../Services/Posid"

import NotFound from "../../Components/imagenotfound/NF.png"

import StoreIcon from '@mui/icons-material/Store';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from "react-i18next";






export const Data = () => {

  const {t}= useTranslation();
  const [images, setImages] = React.useState(null);
  

  const [data, setData] = React.useState(null);

//   const [accessToken, setAccessToken] = React.useState("");
//  const [refreshToken, setRefreshToken] = React.useState("");

  // const [token, setToken] = React.useState(null);

  const posId = localStorage.getItem("session_posId")
  React.useEffect(() => {
    data_service.getData(setData, posId);
  }, []);


console.log("@str*!!!!!!!!!!&&&& ", data)
  React.useEffect(() => {
    data_service.getImages(setImages, posId);
  }, []);

  
  const access_token=localStorage.getItem("access_token")
  const refresh_token=localStorage.getItem("refresh_token")


  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`
  console.log("newaccesstoken", access_token)
  console.log("newrefreshtoken", refresh_token)

  


  return (
    <><SubHeader backbutton={true} btnUrl={dashurl} title={t("dashboard_base_data")}/>
   
        <Box>
      <TableContainer component={Paper} sx={{ boxShadow: "none" ,  padding:2 }} >
        
      {
        data?
        
        <Table aria-label="simple-table" sx={{marginBottom:100}}>
        
          <TableBody >
          
            <TableRow key="1" sx={{ borderBottom: 0 }}>
              <TableCell
              component="th"
              scope="row"
              className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px" }}
              >
              {t("data_cost_centre")}
                <Typography className="datadatalabel" sx={{borderBottom: 0}}>{data.name}</Typography>
                <br></br>
                
                {t("data_market_status")} <br></br>
                
                {/* <Typography className="datadatalabel" sx={{borderBottom: 0}}> */}
                <List className="datadatalabel" sx={{borderBottom: 0}}>
                  {data.poses.map((item, index)=>{
                  return(
                    <>
                    {/* {item.status} */}

                    
          <ListItem disablePadding>
            {/* <ListItemButton> */}
              <ListItemIcon sx={{minWidth:18}}>
                {index+1+")"}
              </ListItemIcon>
              <ListItemText primary={t(item.status)}/>
            {/* </ListItemButton> */}
          </ListItem>
        

                    </>
                  )
                })}</List>
                {/* </Typography> */}
                <br></br>

                {t("data_market_type")} <br></br>
                <Typography className="datadatalabel" sx={{borderBottom: 0}}>{data.storeType}</Typography>
                <br></br>

                {t("data_opening_date")}<br></br>
                <Typography className="datadatalabel" sx={{borderBottom: 0}}>{data.openingDate}</Typography>
                <br></br>
                </TableCell>
                
                <Card style={{ backgroundColor: "#e8eaf6" }} sx={{marginTop:1,marginRight:1}}>
          <div style={{ position: "relative"}}>

          <CardMedia component="img" alt="KST" height="150" image={images?images.length !== 0
            // console.log("inside the card media test1",images)
            
            ?images[0].sharepointUrl:NotFound : NotFound} />
          
          {/* <CardMedia component="img" alt="KST" height="150" image={images} /> */}
          <Container align="right">
          <div style={{position: "absolute", color: "Black",top: 5,left: "87%",transform: "translateX(-50%)",}}>
            <Button sx={{color: "#e8eaf6"}}><CircleRoundedIcon fontSize="large"/>
            </Button>
          </div>
          <div style={{position: "absolute", color: "Black",top: 10,left: "87%",transform: "translateX(-50%)",}}>
          <Link href="/Data2" sx={{  color: "white" }}><Button sx={{color: "Black"}}><OpenInFullRoundedIcon />
          </Button></Link>
          </div>
          
          </Container>
          <CardActions >
            <Table aria-label="simple-table">
              <TableBody >
                <TableRow key="11" sx={{ borderBottom: 0 ,backgroundColor: "#E7EAEE" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0, padding:".1px" }}
                  >

                  


                    {images?images.length !== 0 ?images[0].submissionDate:"" : "NA"}
                    {/* date */}
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0,padding:".1px" }}>

                    


                    {images?images.length !== 0 ?images[0].submissionTime:"" : "NA"}

                    {/* time */}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardActions>
          </div>
        </Card>
      </TableRow>
    

            


            <TableRow key="4" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
                {t("data_address")}
              </TableCell><Box sx={{marginLeft:2.3}}>
              <TableCell align="left" className="contactdatanorightalignlabel" sx={{borderBottom: 0, padding:"8px"}}>
               {data.address?data.address:"NA"}
              </TableCell></Box>
            </TableRow>


            <TableRow key="12" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
                {t("data_postal_code")}
              </TableCell><Box sx={{marginLeft:2.3}}>
              <TableCell align="left" className="contactdatanorightalignlabel" sx={{borderBottom: 0, padding:"8px"}}>
               {data.postalCode?data.postalCode:"NA"}
              </TableCell></Box>
            </TableRow>

            <TableRow key="11" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
                {t("data_city")}
              </TableCell><Box sx={{marginLeft:2.3}}>
              <TableCell align="left" className="contactdatanorightalignlabel" sx={{borderBottom: 0, padding:"8px"}}>
               {data.city?data.city:"NA"}
              </TableCell></Box>
            </TableRow>


            <TableRow key="5" sx={{ borderBottom: 0, paddingTop: 100 }}></TableRow>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              ></TableCell>
              <TableCell align="left" className="contactdatanorightalignlabel" sx={{borderBottom: 0, padding:"8px"}}>
              <Link href={data.googleLink} target="_blank" underline="none" sx={{  color: "#004bA0" }}>{t("data_view_on_google_maps")}</Link>
              </TableCell>
            
            <TableRow key="6" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
                {t("data_production_venue")}
              </TableCell>
              <TableCell 
              align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
                <List className="datadatalabel" sx={{borderBottom: 0}} >

              {data.poses.map((item2, index)=>{
                  return(
                    <>
                    {/* {item2.productionVenue?item2.productionVenue:"NA"} */}
                    <ListItem disablePadding>
            {/* <ListItemButton> */}
              <ListItemIcon sx={{minWidth:18}}>
                {index+1+")"}
              </ListItemIcon>
              <ListItemText primary={item2.productionVenue?item2.productionVenue:"NA"}/>
            {/* </ListItemButton> */}
          </ListItem>
                    </>
                  )
                })}</List>
              </TableCell>
            </TableRow>
            <TableRow key="7" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
               {t("data_pos_type")}
              </TableCell>
              <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
              <List className="datadatalabel" sx={{borderBottom: 0}} >
                {data.poses.map((item, index)=>{
                  return(
                    <>
                    {/* {item.posType} */}
                    <ListItem disablePadding>
            {/* <ListItemButton> */}
              <ListItemIcon sx={{minWidth:18}}>
                {index+1+")"}
              </ListItemIcon>
              <ListItemText primary={item.posType}/>
            {/* </ListItemButton> */}
          </ListItem>
                    </>
                  )
                })}</List>
              </TableCell>
            </TableRow>
            <TableRow key="8" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
                {t("data_chiller_type")}
              </TableCell><Box sx={{marginLeft:2.3}}>
              <TableCell align="left" className="contactdatanorightalignlabel" sx={{borderBottom: 0, padding:"8px"}}>
               {data.chillerType?data.chillerType:"NA"}
              </TableCell></Box>
            </TableRow>
            
            <TableRow key="10" sx={{ borderBottom: 0, paddingTop: 100 }}>
              <TableCell
                component="th"
                scope="row"
                className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}
              >
                {t("data_temperature_data")}
              </TableCell>
              <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
              <List className="datadatalabel" sx={{borderBottom: 0}}>
                {data.poses.map((item1, index)=>{
                  return(
                    <>
                    {/* {item1.temperature?item1.temperature.latestTemperature:"NA"} */}
                    <ListItem disablePadding>
            {/* <ListItemButton> */}
              <ListItemIcon sx={{minWidth:18}}>
                {index+1+")"}
              </ListItemIcon>
              <ListItemText primary={item1.temperature?item1.temperature.latestTemperature:"NA"}/>
            {/* </ListItemButton> */}
          </ListItem>
                    </>
                  )
                })}</List>
              </TableCell>
            </TableRow>
            
          </TableBody>
          
        </Table>
        :null
              }
      </TableContainer>
      </Box>
      
      
      
      
      
      {/* <Box >
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(0),
          borderRadius:0,
          height:50,
          width: '100%',
          backgroundColor:"white"
        }}
        
        variant='extended'
        size="small"
        
      >
        <Link href="/Data/Checkin" sx={{  color: "white" }}>
          <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width:360,
            fontWeight: 550,
          }}
          disableElevation
        >
        Check in
        </Button></Link>
      </Fab>
      </Box> */}
      
    </>
  );
};