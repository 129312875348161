import axios from "axios";

export function getCenters(setCostcenter) {
  const access_token = localStorage.getItem("access_token");
  axios
    // .get(
    //   " https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/costcenterwithpos",
     
      .post(
      "https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_Contact/getAttachedCostCenters/costcenterwithpos",
     
     {},
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
        // "Access-Control-Allow-Origin": "https://backend.dev.commsapp.eathappy.structr.com"
      }
    )
    .then((res) => {
      // console.log(res.data);
  const result = res.data.result
  const costcenter_array = [{label:"Auswahl",id:"-1"}]
  
  // console.log(result)
  result.map((item, index) => {


    var autocomplete = item.name+"_"+item.costCenterName


    if(autocomplete!=null){
      if(costcenter_array.indexOf({label: autocomplete, id:item.id})===-1){
      costcenter_array.push(
        {
            label: autocomplete, id:item.id
        }
    )}
    }

    
  })
  
setCostcenter(costcenter_array)
console.log("costcenter_array",costcenter_array)
    });

// const costcenter_array=[
//   { label: '3756'},
//   { label: '3757'},
//   { label: '3758'},
//   { label: '3759'},
//   { label: '3760'},
//   { label: "3761"},
//   { label: '3762'},
// ];

// setCostcenter(costcenter_array)






}









