import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import endata from './locales/en/translationsEN.json'
import dedata from './locales/de/translationsDE.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  // lng: 'de',
  resources: {
    en: {
      translations: endata
    },
    de: {
      translations: dedata
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'de'];

export default i18n;
// require('../../src/i18n/locales/de/translationsDE.json')