import { Button, Paper, Table, Grid, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";
import {Box} from "@mui/material";
import SubHeader from "../Layout/Subheader";
import Fab from "@mui/material/Fab";


import E1 from "../Assets/Images/Error/Vector3.png";
import E2 from "../Assets/Images/Error/Vector2.png";
import T1 from "../Assets/Images/Logo/tree.png"
import { Stack } from "@mui/system";


import TableHead from '@mui/material/TableHead';


export const Eegg = () => {


    

    




    const access_token=localStorage.getItem("access_token")
    

  const refresh_token=localStorage.getItem("refresh_token")

  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`

    return(
        <><SubHeader backbutton={true} btnUrl={dashurl} title="Leaderboard" />
        <Box sx={{marginLeft:3,marginBottom:3,marginRight:3}}>
            
        <Typography variant="h5" sx={{marginTop:3}} align="center" >Planted trees</Typography>

        <Stack spacing={6} direction="row" >
        <Typography >
        <img variant="h1"
              style={{ height: 80, width: 80, marginTop: 20 }}
              src={T1}
              alt="pic" />
              </Typography>
              
              <Typography sx={{paddingTop:6}}>Planted trees last month: 0</Typography>
             
              </Stack>
              <Stack spacing={6} direction="row" >
        <Typography >
        <img variant="h1"
              style={{ height: 80, width: 80, marginTop: 20 }}
              src={T1}
              alt="pic" />
              </Typography>
              
              <Typography sx={{paddingTop:6}}>Total trees planted: 0</Typography>
             
              </Stack>
             
              <Typography variant="h5" align="center"   sx={{marginTop:5}}>
              Leaderboard - Most Logins
              </Typography>  
             
              {/* <Stack spacing={22} direction="row" sx={{marginTop:5}} >
              <Typography align="left">David Toborek</Typography>
              <Typography align="Right">0</Typography>
              </Stack>
              <Stack spacing={26} direction="row" sx={{marginTop:3}} >
              <Typography align="left" >Saji Thoppil</Typography>
              <Typography align="Right" >0</Typography>
              </Stack>
              <Stack spacing={27} direction="row" sx={{marginTop:3}} >
              <Typography align="left"> Florian Bierschenk</Typography>
              <Typography align="Right">0</Typography>
              </Stack> */}
        
              <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: 4 }} >
              <Box sx={{ padding: 1 }}>
              <Table aria-label="simple-table"  >
              <TableBody>
              <Stack spacing={1} direction="row" sx={{marginTop:1}} >
              <TableCell component="th" scope="row" className="leaderboardtableleft" sx={{ borderBottom: 0, borderColor: "grey", padding: "8px" }}>
             1. Hai Viet Nguyen
              </TableCell>
              <TableCell align="right" className="leaderboardtableright" sx={{ borderBottom: 0,borderColor: "grey", padding: "8px" }}>
              3
              </TableCell>
              </Stack>
              <Stack spacing={1} direction="row" sx={{marginTop:5}} >
              <TableCell component="th" scope="row" className="leaderboardtableleft" sx={{ borderBottom: 0, borderColor: "grey", padding: "8px" }}>
             2. Le Hong Phuc Van Doan
              </TableCell>
              <TableCell align="right" className="leaderboardtableright" sx={{ borderBottom: 0,borderColor: "grey", padding: "8px" }}>
              3
              </TableCell>
              </Stack>
              <Stack spacing={1} direction="row" sx={{marginTop:5}} >
              <TableCell component="th" scope="row" className="leaderboardtableleft" sx={{ borderBottom: 0, borderColor: "grey", padding: "8px" }}>
             3. The Tan Than
              </TableCell>
              <TableCell align="right" className="leaderboardtableright" sx={{ borderBottom: 0,borderColor: "grey", padding: "8px" }}>
              3
              </TableCell>
              </Stack>
              {/* <Stack spacing={22} direction="row" sx={{marginTop:5}} >
              <TableCell component="th" scope="row" className="leaderboardtableleft" sx={{ borderBottom: 0, borderColor: "grey", padding: "8px" }}>
             3. Florian Bierschenk
              </TableCell>
              <TableCell align="right" className="leaderboardtableright" sx={{ borderBottom: 0,borderColor: "grey", padding: "8px" }}>
              0
              </TableCell>
              </Stack> */}
              </TableBody>
              </Table>

              </Box>
              </TableContainer>
        
        </Box>
        
        </>
    );
}