import '../../Components/chillerslider/Chiller-slider.scss'

import React from 'react'
import PropTypes  from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'

import { Box} from "@mui/system";
import { useState } from 'react'
import * as data_service from "../../Services/Data";


import {
  
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";




export const Chillerslider = props => {


  const [images, setImages] = React.useState(null);
  const [data, setData] = React.useState(null);
  const posId = localStorage.getItem("session_posId")
  React.useEffect(() => {
    data_service.getData(setData, posId);
  }, []);

  React.useEffect(() => {
    data_service.getImages(setImages, posId);
  }, []);
  console.log(images)
  console.log(props.images, "datetest77")



  const [activeThumb, setActiveThumb] = useState()


  return <>
  <Box sx={{marginLeft:6,marginBottom:3,marginRight:6}}  >
  <Box>
  <Swiper
  loop={true}
  spaceBetween={10}
  navigation={true}
  modules={[Navigation, Thumbs]}
  grabCursor={true}
  thumbs={{ swiper: activeThumb }}
  className='chiller-slider'

>


    
    {
      images?images.map((item, index) => (
        <SwiperSlide key={index}>
          <img src={item.sharepointUrl} alt="chiller images" sx={{width:400, height:400}}/>
          <span>
          <Table aria-label="simple-table">
  <TableBody >
  <TableRow sx={{ borderBottom: 0 ,backgroundColor: "#E7EAEE"}}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0, padding:".1px" }}
                  >

                  


{item.submissionDate.length !== 0 ?item.submissionDate:"NA"}
                    
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0,padding:".1px" }}>

                    


                    {item.submissionTime.length !== 0 ?item.submissionTime:"NA"}

                    
                  </TableCell>
                </TableRow>
                </TableBody>
                </Table>


                </span>
        </SwiperSlide>
        

      ))
      :null
    }

  </Swiper>
  {/* <Table aria-label="simple-table">
  <TableBody >
  <TableRow sx={{ borderBottom: 0 ,backgroundColor: "#E7EAEE"}}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0, padding:".1px" }}
                  >

                  


                    {images?images.length !== 0 ?images[0].submissionDate:"NA" : "NA"}
                    
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0,padding:".1px" }}>

                    


                    {images?images.length !== 0 ?images[0].submissionTime:"NA" : "NA"}

                    
                  </TableCell>
                </TableRow>
                </TableBody>
                </Table> */}


      </Box>

  <Swiper
  
  onSwiper={setActiveThumb}

  loop={false}
  spaceBetween={10}
  slidesPerView={4}
  modules={[Navigation, Thumbs]}
  className='chiller-slider-thumbs'

>


    
    {
      props.images.map((item, index) => (
        <SwiperSlide key={index}>
          <div className='chiller-slider-thumbs-wrapper'>
            
            {index === (props.images.length - 1)?
          <><img src={item} alt="chiller images" />

          <div className='centered' >
            <Box sx={{backgroundColor:'white'}}> <Link href="https://eathappycom.sharepoint.com/sites/DeliveryAppChillerPhotos/Freigegebene%20Dokumente/chiller-photos" target="_blank" sx={{  color: "#004bA0" }}>Authenticate sharepoint</Link></Box>
            </div>
            
            </>

          :<img src={item} alt="chiller images" />}
          </div>
        </SwiperSlide>

      ))
    }

  </Swiper>
  </Box>
  </>
}

Chillerslider.propTypes = {
  images: PropTypes.array.isRequired
}