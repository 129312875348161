import { AttachFile} from "@mui/icons-material";
import { Button, Divider,Card, Grid,IconButton,Link,TextField,Typography, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SubHeader from "../../Layout/Subheader";
import S1 from "../../Assets/Images/Popups/Vector1.svg";
import W1 from "../../Assets/Images/Popups/Vector2.svg";

import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import AddLinkIcon from '@mui/icons-material/AddLink';
import DoneIcon from '@mui/icons-material/Done';
import * as cc_id from "../../Services/Costcenter";
import Warning from "../../Components/Popup";
import { CardContent } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import * as tick from '../../Services/Tickets'

import { useRef } from "react"
import {useEffect, useState} from 'react'
import Camera from "../../Components/Cameraticket";

import Webcam from "react-webcam";
import Fab from "@mui/material/Fab";
import CloseIcon from '@mui/icons-material/Close';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useTranslation } from 'react-i18next';
import { set } from "immutable";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


  const criticality = () => [
    { label: 'Low'},
    { label: 'Medium'},
    { label: 'High'},
    { label: 'Critical'},
    
  ];


const style = {
    position: 'absolute',
    top: '50%',
    // left: '50%',
    // right: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  
  const videoConstraints = {
    width: 500,
    
    facingmode: FACING_MODE_ENVIRONMENT,
  };


  

 



export const TForm2 = () => {

  

  const { t } = useTranslation();
  const [camopen, setCamopen] = React.useState(false);
  const [url, setUrl] = useState(null);
  const [camdisable,setCamdisable] = useState(true);
  const [fileid,setFileid] = React.useState(null);

  const handleClickOpen = () => {
    setCamopen(true);
  };

  const handleClickclose = () => {
    tick.uploadFile(url,setFileid);
    console.log(fileid,url)
    setCamopen(false);
    setUrl(null);
  setCamdisable(true)
  };


    const navigate=useNavigate()

    

    const [open, setOpen] = React.useState(false);
    

    const handleSubmit = () => {
      let finalset = {} 
   let finalset2 = {}
  
   let descr = document.getElementById('descr').value;
   let telenum = document.getElementById('telenum').value;
   let reported_temperature = document.getElementsByName('reported_temperature').value; 
  // let reported_temperature = null; 

   
  // let reported_temperature = document.getElementById('reported_temperature')?.value ?? "NA"
  
   finalset2 = Object.assign({"descr":descr, "reported_temperature":reported_temperature ? reported_temperature:"NA", "cost_center_id":posId, "telenum":telenum})
   finalset = Object.assign(finalset2, values)
   console.log(124, finalset);
   console.log("check for any value",fileid, filevidid,telenum,descr)
   console.log("5th button", fifthbtn, reported_temperature)
   var fileset = []
    if (fileid === null && filevidid ===false) {
      console.log("no image and video")
      alert("Please attach picture or video")
      return null; 
     } 
     else if (fifthbtn === true && reported_temperature === undefined) {
      alert("Please input temperature")
      return null;
     }
     else if (telenum==='') {
      alert("Please input telephone number")
      return null;
     }
     else if (descr==='') {
      alert("Please input description")
      return null;
     }
     else if (fileid !== null && filevidid !==false) {
     fileset.push(fileid) 
     fileset.push(filevidid)
     console.log(fileset)
     finalset["files_ids"] = fileset
     }
     else if (fileid === null && filevidid !==false){
      fileset.push(filevidid)
      console.log(fileset)
      finalset["files_ids"] = fileset
     }
     else if (fileid !== null && filevidid ===false){
      fileset.push(fileid)
      console.log(fileset)
      finalset["files_ids"] = fileset
     }
   tick.ticketSubmission(finalset)
    }

    const posId = localStorage.getItem("session_posId")

    const handleOpen = () => {
      const formResult = handleSubmit();
        if (formResult !== null) {
        setOpen(true);
        setTimeout(() => {
            setOpen(false)
            navigate('/Tickets') 
         }, 3000)
    }
  }

    //for video
  const [videopen, setVideopen] = React.useState(false);
  const [filevidid,setFilevidid] =React.useState(false);
  const handleClickVidOpen = () => {
    setVideopen(true);

  };

    //for cam close
    const camClose = () =>  {
      setCamopen(false);
    };

    //for vid close
    const vidClose = () =>  {
    setVideopen(false);
    };

    ///record video
const videoRef = useRef(null);
const [recording, setRecording] = useState(false);
const [mediaRecorder, setMediaRecorder] = useState(null);
const [videoUrl, setVideoUrl] = useState(null);
const [timer, setTimer] = useState(0);

const formatTime = (time) => {
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (time % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};

const handleStartRecording = () => {
  setRecording(true);
  const stream = videoRef.current.video.srcObject;
  const mediaRecorder = new MediaRecorder(stream, {
    mimeType: "video/webm",
  });
  setMediaRecorder(mediaRecorder);
  const chunks = [];
  mediaRecorder.ondataavailable = (e) => {
    chunks.push(e.data);
  };
  mediaRecorder.onstop = () => {
    const videoBlob = new Blob(chunks, { type: "video/webm" });
    const Vurl = URL.createObjectURL(videoBlob);
    setVideoUrl(videoBlob);
    console.log (Vurl, "videoMtest")
  };
  mediaRecorder.start();
};

const handleStopRecording = () => {
  setRecording(false);
  mediaRecorder.stop();
};
/////////////////////////////////////////////////////////////////////////////////
const handleDownload = () => {
  // const a = document.createElement("a");
  // a.href = videoUrl;

  console.log("what is video", videoUrl)
  console.log(videoUrl instanceof Blob)
  // a.download = "recorded-video.webm";
  // a.click();
  tick.uploadVideo(videoUrl,setFilevidid)
  setVideoUrl(null);
  setVideopen(false);
};

useEffect(() => {
  let intervalId;
  if (recording) {
    intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  }
  return () => {
    clearInterval(intervalId);
    setTimer(0);
  };
}, [recording]);




    const [warning, setWarning] = React.useState(false);
    const handleWarningopen = () => {
        setWarning(true);
            
    }
    const handleWarningClose = () => setWarning(false);

    const [values, setValues] = React.useState(null);
  const handleChange = (newValue) => {
    setValues(newValue);
  };


    const handleInput = (event) => {
        const cc_value = event.target.value
        const { name, value } = event.target
            setValues({
                ...values,
                [name]: value
            })
        console.log(cc_value)
      }
        console.log(123,values)


        const [cameraclick, setCameraclick] = React.useState(false);
    const camOpen = () => {
      setCameraclick(true);
      
      
  }

  const handleClose = () => setCameraclick(false);

    

const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

  const webcamRef = useRef(null);


  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
    setCamdisable(false);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  const onUserVideo = (e) => {
    console.log(e);
  };

  const handleClick = React.useCallback(() => {
    setUrl(null);
    setCamdisable(true);
    setFacingMode((prevState) =>
      prevState === FACING_MODE_ENVIRONMENT
        ? FACING_MODE_USER
        : FACING_MODE_ENVIRONMENT
    );
  }, []);


  

  const [techbtn, setTechBtn] = React.useState(true);
  const [funcbtn, setFuncBtn] = React.useState(false);
  const [appbtn, setAppBtn] = React.useState(false);
  const [forthbtn, setForthBtn] =React.useState(false);
  const [fifthbtn, setFifthBtn] =React.useState(false)

  const handleCamClose = () => setCameraclick(false);

  const [costcenter, setCostcenter] = React.useState(null);
  React.useEffect(() => {
    cc_id.getCenters(setCostcenter);
  }, []);


  const opClick = (event, issue) => {
    const opVal = event.target.value;
    if(opVal === "Problems lifting station") {
      setTechBtn(true);
      setFuncBtn(false);
      setAppBtn(false);
      setForthBtn(false);
      setFifthBtn(false);
      const name = "issue"
      setValues({
        ...values,
        [name]: issue
    })
    }
    else if(opVal === "Problems dishwasher") {
      setTechBtn(false);
      setFuncBtn(true);
      setAppBtn(false);
      setForthBtn(false);
      setFifthBtn(false);
      const name = "issue"
      setValues({
        ...values,
        [name]: issue
    })
      
    }
    else if (opVal === "Maintenance") {
      setTechBtn(false);
      setFuncBtn(false);
      setAppBtn(true);
      setForthBtn(false);
      setFifthBtn(false);
      const name = "issue"
      setValues({
        ...values,
        [name]: issue
    });
    }
    else if (opVal === "Issues sanitary") {
      setTechBtn(false);
      setFuncBtn(false);
      setAppBtn(false);
      setForthBtn(true);
      setFifthBtn(false);
      const name = "issue"
      setValues({
        ...values,
        [name]: issue
    });
    console.log("operation",values)
    }
    else if (opVal === "Problems cooling") {
      setTechBtn(false);
      setFuncBtn(false);
      setAppBtn(false);
      setForthBtn(false);
      setFifthBtn(true);
      const name = "issue"
      setValues({
        ...values,
        [name]: issue
    });
    console.log("options",values)
    }
  }

  const posClick = (event, pos_type) => {
    const name = "pos_type"
      setValues({
        ...values,
        [name]: pos_type
    });
    console.log("pos",values)
  }

  const subClick = (event, sub_issue) => {
    const name = "sub_issue"
   
      setValues({
        ...values,
        [name]: sub_issue
    });
    console.log("pos",values)
  
  }

  const prioClick = (event, priority) => {
    const name = "priority"
      setValues({
        ...values,
        [name]: priority
    });
    console.log("pos",values)
  }

  const sellClick = (event, continue_to_sell) => {
    const name = "continue_to_sell"
      setValues({
        ...values,
        [name]: continue_to_sell
    });
    console.log("pos",values)
  }



  const default_option = [{"label":"Loading...","id":"-1"}]


  const [value1, setValue1] = useState('');

  const handleChange1 = (event) => {
    const newValue1 = event.target.value;

    // Check if the input is a valid number
    if (/^[0-9]*$/.test(newValue1)) {
      setValue1(newValue1);
    }
  };

  
  // const [descriptioncheck, setDescriptioncheck] = useState('');

  // const handleDescriptioncheck = (event) => {
  //   setDescriptioncheck(event.target.value);
  // }

    return(
        <>
        <SubHeader backbutton={true} btnUrl="/Tickets" title={t("ticket_form_new_ticket")} warning_flag={true} bf={handleWarningopen}/>
        <Box backgroundColor="white" >
        <Box sx={{marginLeft:2,marginBottom:3,marginRight:3}}>
        
        <Grid container spacing={.1} sx={{ display: "flex", flexDirection: "column" }}>
        {/* <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766", marginLeft: 1 }} >
        <br></br>
        <Typography className="contactstaticlabel">{t("ticket_form_cost_centre")} </Typography>
        </Grid> */}
            
                <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766", marginRight:2 }} >
                
                {/* <Autocomplete
      disablePortal
      name="cost_center_id"
      id="cost_center_id"
      options={
        costcenter ? costcenter.map((option) => option.label) : default_option.map((option)=> option.label)
        // top100Films
      }
      sx={{ width: "100%", backgroundColor: "#f5f6f8", color:"black"}}
      renderInput={(params) => <TextField {...params} />}
      //onChange = {handleInput}
      onChange={(event, newValue) => {
        const name = "cost_center_id";
        let cost_center_id = null;
        costcenter.map((cc) => {
          if (cc.label === newValue) {
            cost_center_id = cc.id;
          }
        });
        setValues({
          ...values,
          [name]: cost_center_id,
        });
        console.log(values);
      }}
    /> */}
    
    <Grid container spacing={1} sx={{ color: "#4D5766" ,display: "flex", flexDirection: "column", marginTop: 3, marginLeft: 1 }}>
    <FormControl >
                    <FormLabel id="pos_type" ><Typography className="contactstaticlabel"> {t("ticket_form_pos-type")} </Typography></FormLabel>
                    <RadioGroup
                        aria-labelledby="pos_type"
                        name="pos_type"
                        id="pos_type"
                        onChange={posClick}
                    >
                        <FormControlLabel className="settingsemployeenamelabel" value="Shop" control={<Radio />} label={t("ticket_form_shop")} />
                        <FormControlLabel className="settingsemployeenamelabel" value="Chiller" control={<Radio />} label={t("ticket_form_chiller")}/>
                        <FormControlLabel className="settingsemployeenamelabel" value="Mochi - Chiller" control={<Radio />} label={t("ticket_form_mochi-chiller")}/>
                        
                        
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{marginTop: 2}}>
                    <FormLabel id="issue" sx={{display:'flex'}}><Typography className="contactstaticlabel">{t("ticket_form_please_select_defect")}</Typography></FormLabel>
                    <RadioGroup
                        aria-labelledby="issue"
                        name="issue"
                        id="issue"
                        defaultValue="Problems lifting station"
                        onChange={opClick}
                    >
                        <FormControlLabel className="settingsemployeenamelabel" value="Problems lifting station" control={<Radio />} label="Problems lifting station" />
                        <FormControlLabel className="settingsemployeenamelabel"  value="Problems dishwasher" control={<Radio />} label="Problems dishwasher" />
                        <FormControlLabel className="settingsemployeenamelabel"  value="Maintenance" control={<Radio />} label="Maintenance" />
                        <FormControlLabel className="settingsemployeenamelabel"  value="Issues sanitary" control={<Radio />} label="Issues sanitary" />
                        <FormControlLabel className="settingsemployeenamelabel"  value="Problems cooling" control={<Radio />} label="Problems cooling" />
                        
                        
                    </RadioGroup>
                </FormControl>
                { techbtn === true ?
    <FormControl sx={{marginTop: 2}}>
        <FormLabel id="sub_issue" sx={{display:'flex'}}><Typography className="contactstaticlabel">Problems lifting station-selection</Typography></FormLabel>
        <RadioGroup
            aria-labelledby="sub_issue"
            name="sub_issue"
            defaultValue="Water leakage"
        >
            <FormControlLabel className="settingsemployeenamelabel"  value="Water leakage" control={<Radio />} label="Water leakage" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="Not functioning" control={<Radio />} label="Not functioning" onChange={subClick}/>
            {/* <FormControlLabel className="settingsemployeenamelabel"  value="Electricity issue" control={<Radio />} label={t("ticket_form_technical_issue_electricity_issue")} onChange={subClick}/>
            <FormControlLabel control={<Radio />} label={ <TextField  id="techOther" label={t("ticket_form_technical_issue_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}}/>  } /> */}
            
        </RadioGroup>
    </FormControl>
     :null    }
     { funcbtn === true ?
    <FormControl sx={{marginTop: 3}}>
        <FormLabel id="sub_issue" sx={{display:'flex'}}><Typography className="contactstaticlabel">Problems dishwasher-selection</Typography></FormLabel>
        <RadioGroup
            aria-labelledby="sub_issue"
            name="sub_issue"
            id="sub_issue"
            defaultValue="Water leakage"
        >
            <FormControlLabel className="settingsemployeenamelabel"  value="Water leakage" control={<Radio />} label="Water leakage" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="Not functioning" control={<Radio />} label="Not functioning" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="Maintenance (Parts missing)" control={<Radio />} label="Maintenance (Parts missing)" onChange={subClick}/>
            {/* <FormControlLabel className="settingsemployeenamelabel"   control={<Radio />} label={ <TextField  id="funcOther" label={t("ticket_form_funtional_issue_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}}/>} /> */}
            
        </RadioGroup>
    </FormControl>
        :null    }
        {appbtn ===true ?
    <FormControl sx={{marginTop: 3}}>
        <FormLabel id="sub_issue" sx={{display:"flex"}}><Typography className="contactstaticlabel">Maintenance-selection</Typography></FormLabel>
        <RadioGroup
            aria-labelledby="sub_issue"
            name="sub_issue"
            id="sub_issue"
            defaultValue="Covering defect"
        >
            <FormControlLabel className="settingsemployeenamelabel"  value="Covering defect" control={<Radio />} label="Covering defect" onChange={subClick}/>
            {/* <FormControlLabel className="settingsemployeenamelabel"  control={<Radio />} label={ <TextField  id="appOther" name="Other" label={t("ticket_form_appearance_other")} variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}}/> } /> */}
            
        </RadioGroup>
    </FormControl>
    :null }
    {forthbtn ===true ?
    <FormControl sx={{marginTop: 3}}>
        <FormLabel id="sub_issue" sx={{display:'flex'}}><Typography className="contactstaticlabel">Issues sanitary-selection</Typography></FormLabel>
        <RadioGroup
            aria-labelledby="sub_issue"
            name="sub_issue"
            id="sub_issue"
            defaultValue="Fixtures"
        >
            <FormControlLabel className="settingsemployeenamelabel"  value="Fixtures" control={<Radio />} label="Fixtures" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="Boiler" control={<Radio />} label="Boiler" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="Blockage" control={<Radio />} label="Blockage" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="(nknown) water leakage" control={<Radio />} label="(nknown) water leakage" onChange={subClick}/>
            {/* <FormControlLabel className="settingsemployeenamelabel"  control={<Radio />} label={ <TextField  id="appOther" name="Other" label={t("ticket_form_appearance_other")} variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}}/> } /> */}
            
        </RadioGroup>
    </FormControl>
    :null }
      {fifthbtn ===true ?
      
    <FormControl sx={{marginTop: 3}}>
        <FormLabel id="sub_issue" sx={{display:'flex'}}><Typography className="contactstaticlabel">Problems cooling-selection</Typography></FormLabel>
        <RadioGroup
            aria-labelledby="sub_issue"
            name="sub_issue"
            id="sub_issue"
            defaultValue="SBK"
        >
            <FormControlLabel className="settingsemployeenamelabel"  value="SBK" control={<Radio />} label="SBK" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="UBK" control={<Radio />} label="UBK" onChange={subClick}/>
            <FormControlLabel className="settingsemployeenamelabel"  value="Fast cooler" control={<Radio />} label="Fast cooler" onChange={subClick}/>
            {/* <FormControlLabel className="settingsemployeenamelabel"  control={<Radio />} label={ <TextField  id="appOther" name="Other" label={t("ticket_form_appearance_other")} variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}}/> } /> */}
            
        </RadioGroup>
        {/* {values.issue === "Problems cooling" && ( */}
        



        {/* )} */}
    </FormControl>
    
    :null }
</Grid>
                </Grid>
                {fifthbtn === true ? (

                <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766" , marginLeft: 0,marginRight:1 }} xs={12} sm={6}>
             <Box sx={{marginTop: 3,display:"flex"}}>
              <Typography className="contactstaticlabel">{t("ticket_form_add_temp")} </Typography>
              <Typography className="mandatorylabel" sx={{color:"red", marginTop:.4,marginLeft:1}}> *this field is mandatory </Typography>
             </Box>
                <TextField 
                // required
                name="reported_temperature" sx={{ backgroundColor: "#F5F5F5", marginTop: 1 }}>
                
                </TextField>
            </Grid>
                
                ) : null}
            
            <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766" , marginLeft: 1 }} xs={12} sm={6}>
            <Box sx={{marginTop: 3,display:'flex'}}><Typography className="contactstaticlabel">
            {t("ticket_form_detailed_description")}</Typography><Typography className="mandatorylabel" sx={{color:"red", marginTop:.4,marginLeft:1}}>
            *this field is mandatory </Typography></Box>
                <TextField 
                // onChange={handleDescriptioncheck}
                required 
                id="descr" sx={{ backgroundColor: "#F5F5F5", marginTop: 1 }}>
                

                </TextField>
                <Typography className="contactstaticlabel">
                {t("ticket_form_additional_details")}
                </Typography>
            </Grid>
            <br></br>
            <Box>
            <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766" , marginLeft: 1,marginRight:1  }}  xs={12} sm={6}>
            <Box sx={{display:'flex'}}><Typography className="contactstaticlabel">
            {t("ticket_form_add_attachments")}</Typography><Typography className="mandatorylabel" sx={{color:"red", marginTop:.4,marginLeft:.2}}>
            *Image or Video is mandatory  </Typography></Box>
                
                    {/* //upload file */}
                   
                    {/* <label htmlFor="upload-photo"  >
                    <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    
                    /> */}

{/* <Link href="/CameraT" > */}
  <Button color="primary" variant="outlined" 
  // component="span" 
  sx={{
                    textTransform: "none",
                    width: '100%',
                    fontWeight: 550,
                    marginTop: 1 ,
                   
                    }}
                    // onClick={camOpen} 
                    onClick={handleClickOpen} 
                    >
                    < AddAPhotoOutlinedIcon  />
                    Take a picture
                    </Button>
                    {/* </Link> */}
                    {/* </label> */}

                    {
                  fileid ? "pic uploaded" : null
                }

                    <Dialog
        open={camopen}
        onClose={handleCamClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

<div className="button-container">
        <Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>

        <Button className="right-button" onClick={camClose}><CloseIcon /></Button>      

</div>
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickclose}>Disagree</Button>
          <Button onClick={handleClickclose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}

        




<Box className="app-container">
        
        {camdisable?
          
        
          <Webcam
            ref={webcamRef}
            // audio={true}
            screenshotFormat="image/png"
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserMedia}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


<Box sx={{marginLeft:"20%"}}>
       
       <Grid container spacing={3.5} >
       <Grid item xs={3}>
         {/* <Link href="/Tickets/form2"> */}
       <Button onClick={handleClickclose}><CloudUploadIcon fontSize="large" sx={{color:'#239342'}}/></Button>
       {/* </Link> */}
       </Grid>
       <Grid item xs={3}>
       <Button  onClick={capturePhoto}><LinkedCameraIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
       </Grid>
       <Grid item xs={3}>
       <Button  onClick={() => {
         setUrl(null);
         setCamdisable(true)
         }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#E53D30'}}/></Button>
       </Grid>
       
       {/* <Grid item xs={3}>
       <Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#1E1E1E'}}/></Button>
       </Grid> */}
       </Grid>
       
             </Box>

      </Dialog>


{/* video */}
      <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#4D5766",
                
              }}
              xs={12}
              sm={6}
            >
              {/* <Box sx={{marginBottom:1}}><Typography className="contactstaticlabel">
              Please add video if needed</Typography></Box> */}
             

<Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    fontWeight: 550,
                  }}
                  onClick={handleClickVidOpen} 
                >
                  <VideoCallIcon sx={{ color: "#003DA6" }} />
                  Record video
                </Button>

                {
                  filevidid ? "Video uploaded" : null
                }
              
            </Grid>


            <Dialog
        open={videopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <div className="button-container">
        <Button sx={{backgroundColor:"#4CAF50",color:"white"}} onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
        
      
        <Button className="right-button" onClick={vidClose}><CloseIcon /></Button>      
        </div>
        
<Box className="app-container" >

        {camdisable?
          
        
          <Webcam
            ref={videoRef}
            audio={false}
            
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserVideo}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {/* {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )} */}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


      <Box sx={{marginLeft:"40%"}}>
      


     {/* <Grid item xs={3}>
      <Button  onClick={() => {
        setUrl(null);
         setCamdisable(true)
         }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
      </Grid> */}


      
      {videoUrl && (
        <Button onClick={handleDownload}><CloudUploadIcon fontSize="large" sx={{color:'#239342'}}/></Button>
      )}
      
      
      {recording ? (
         <div className="recording-container">
         <div className="recording-symbol" />
         <div className="timer">{formatTime(timer)}</div>
        <Button onClick={handleStopRecording}><StopCircleIcon fontSize="large" sx={{color:'#1E1E1E'}}/> </Button>
      </div>
      ) : (
        <Button onClick={handleStartRecording}><RadioButtonCheckedIcon fontSize="large" sx={{color:'#E53D30',marginLeft:"20%"}}/></Button>
      )}

{/* <Grid item xs={3}>
<Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid> */}

      </Box>
      </Dialog>





            </Grid>
            </Box>
            



            <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766" , marginLeft: 1 }} xs={12} sm={6}>
            <Box sx={{marginTop: 3 ,display:'flex'}}><Typography className="contactstaticlabel">
            Telephone number</Typography><Typography className="mandatorylabel" sx={{color:"red", marginTop:.4,marginLeft:1}}>
            *this field is mandatory </Typography></Box>
                <TextField
                // required
                 value={value1}
                 onChange={handleChange1}
                 InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                 id="telenum" 
                 sx={{ backgroundColor: "#F5F5F5", marginTop: 1 }}>
                

                </TextField>
                
            </Grid>





            <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 0,
          marginLeft: 1,
        }}
      >

            <Grid container spacing={1} sx={{ color: "#4D5766" ,display: "flex", flexDirection: "column", marginTop: 2, marginLeft: .4 }}>
                <FormControl >
                    <FormLabel id="priority"> <Typography className="contactstaticlabel"> {t("ticket_form_priority")}</Typography></FormLabel>
                    <RadioGroup
                        aria-labelledby="priority"
                        name="priority"
                        id="priority"
                        onChange={prioClick}
                    >
                        <FormControlLabel className="settingsemployeenamelabel"  value="10003" control={<Radio />} label={t("ticket_form_priority_pos_closed")} />
                        <FormControlLabel className="settingsemployeenamelabel"  value="10002" control={<Radio />} label={t("ticket_form_priority_critical")} />
                        <FormControlLabel className="settingsemployeenamelabel"  value="2" control={<Radio />} label={t("ticket_form_priority_acute")} />
                        <FormControlLabel className="settingsemployeenamelabel"  value="3" control={<Radio />} label={t("ticket_form_priority_not_acute")} />
                        <FormControlLabel className="settingsemployeenamelabel"  value="10000" control={<Radio />} label={t("ticket_form_repairment")} />
                        
                    </RadioGroup>
                </FormControl>

                
            

            <FormControl sx={{marginTop: 1}}>
                <FormLabel id="continue_to_sell"><Typography className="contactstaticlabel">{t("ticket_form_continue_sales")}</Typography></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="continue_to_sell"
                  name="continue_to_sell"
                  onChange={sellClick}
                >
                  <FormControlLabel className="settingsemployeenamelabel"  value="10149" control={<Radio />} label={t("ticket_form_continue_sales_yes")} />
                  <FormControlLabel className="settingsemployeenamelabel"  value="10150" control={<Radio />} label={t("ticket_form_continue_sales_no")} />
                </RadioGroup>
              </FormControl>
              </Grid>

              </Grid>
              <Divider sx={{ marginTop: 2, marginBottom: 2}} />
            <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766",justifyContent:"center",alignItems:"center" }} xs={12} sm={6}>
            <Button 
                    variant="contained" 
                    sx={{textTransform:"none", 
                        backgroundColor:"#004bA0",
                        width:360,
                        fontWeight:550}} 
                    disableElevation
                    onClick={handleOpen}
                 >
                    <DoneIcon/>
                    {t("ticket_form_send")}
                </Button>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{left:'50%'}}
      >
        <Box sx={style}>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
          {t("ticket_form_success")}
          </Typography>
          <img
              style={{ height: 80, width: 80,marginLeft:"33%", marginTop: 20 }}
              src={S1}
              alt="pic"
              />
            <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
            {t( "ticket_form_ticket_created")} 
            </Typography>
        </Box>
      </Modal>
    <Modal
        open={warning}
        onClose={handleWarningClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{left:'50%'}}
      >
       <Card sx={style}>
        <CardContent>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
            
          {t("ticket_form_warning")}
          
          </Typography>
          
          <img
              style={{ height: 80, width: 80,marginLeft:"32%", marginTop: 20 }}
              src={W1}
              alt="pic"
              
              />
              <br></br>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
          {t("ticket_form_warning_message")}
            </Typography>
            </CardContent>

              <CardActions style={{ width: 500, marginTop: 20, marginLeft:20 }}>

            <Link href="/Tickets" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    // width:156,
                    fontWeight:600,
                    // margin:2
                }} 
                    disableElevation
                >
                     {t("ticket_form_warning_back")}
                </Button></Link>
                <Link href="/Tickets/form2" sx={{  color: "white" }}><Button 
                    variant="outlined" 
                    sx={{textTransform:"none", 
                        // width:156,
                        fontWeight:550}} 
                    disableElevation
                 >
                    {t("ticket_form_warning_finish")}
                </Button></Link>
            </CardActions>

        </Card>
       
      </Modal>
      {/* <Warning open={warning} handleClose={handleWarningClose}/>
       */}
      

            </Grid>
        </Grid>
        </Box>
        </Box>

        
        </>
    );
}