import { Box, Button, Link, TextField, Paper, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";
import SubHeader from "../../Layout/Subheader";
import Fab from "@mui/material/Fab";
import { getTemperature } from "../../Services/Temperature";
import * as temperature_Service from "../../Services/Temperature"
import * as costcenter from "../../Services/Costcenter"
import i18n from "../../i18n/config";
import { useTranslation } from 'react-i18next';
import { Stack } from "@mui/system";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fontSize } from "@mui/joy/styles/sxConfig";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import S1 from "../../Assets/Images/Popups/Vector1.svg"

const styled = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

export const Cleaning = () => {
    const { t } = useTranslation();

    const [temperature, setTemperature] = React.useState(null);
    const posId = localStorage.getItem("session_posId");
    const costcenter = localStorage.getItem("session_costcenter");

    const [value, setValue] = React.useState(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpend = () => {
        setOpend(true);
        setTimeout(() => {
          setOpend(false);
          navigate("/Cleaning");
        }, 3000);
      };
      const handleClosed = () => setOpend(false);

      const navigate = useNavigate();

    const [opend, setOpend] = React.useState(false);

    const handleButtonClick = () => {
        setIsOpen(true);
      };
    
      const handleDatePickerChange = (newValue) => {
        setValue(newValue);
        setIsOpen(false);
      };
    
      const handleDatePickerClose = () => {
        setIsOpen(false);
      };



    const access_token=localStorage.getItem("access_token")
    

  const refresh_token=localStorage.getItem("refresh_token")

  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`
  
    return (

        <><SubHeader backbutton={true} btnUrl={dashurl} title="Cleaning Schedule" />
            <Box sx={{backgroundColor:"#FFFFFF"}}>
            
                <Typography variant="h5" color="#4D5766" align="center"  sx={{paddingTop:"5%"}}>
                    Add new cleaning
                </Typography>
            
            <Box sx={{ marginRight:"15%", marginTop:"5%"}}>
            <Stack spacing={5} direction="row" sx={{ justifyContent: 'space-between' }}>
                    {/* <Button onClick={handleButtonClick} variant="contained" className="cleaningdatebutton" sx={{minWidth:150}} style={{textTransform: 'none'}}>
                        <Typography fontSize="13px">Select date</Typography>
                    </Button> */}
        <Box sx={{width:150, marginLeft:"10%"}}>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            {value? (
        <DatePicker
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => 
        <TextField {...params} style={{color:"black",background:"#D4D3D9"}} InputLabelProps={{shrink:false}}/>}
        /> ) :(


            <DatePicker
        label={<span style={{ color: 'black', fontSize:"15px" }}><Typography sx={{marginLeft:1.5,marginTop:.3}} fontSize="13.5px" color="black">Select date</Typography></span>}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => 
        <TextField {...params} style={{color:"black",background:"#D4D3D9"}} InputLabelProps={{shrink:false}}/>}
        /> 
        )}


        </LocalizationProvider>
        </Box>
       
                    <Button onClick={handleOpend} variant="contained" className="cleaningconfirmbutton"  style={{textTransform: 'none'}}>
                    <Typography fontSize="13px" color="white">Confirm cleaning</Typography>
                    </Button>
                    
                </Stack>
            </Box>


            




            <Box sx={{marginTop:5,marginBottom:"5%"}}> 
                <Typography variant="h5" color="#4D5766" align="center">
                    POS last cleaned
                </Typography>   
            </Box>
<Box sx={{marginLeft:"10%"}}>
           
    <TableContainer >
      <Table >
        <TableBody>
          <TableRow >
            <TableCell sx={{borderBottom:0}}>28.02.2023</TableCell>
            <TableCell sx={{borderBottom:0}}>f.bierschenk@eathappy.de</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{borderBottom:0}}>05.02.2023</TableCell>
            <TableCell sx={{borderBottom:0}}>a.mueller@eathappy.de</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{borderBottom:0}}>01.01.2023</TableCell>
            <TableCell sx={{borderBottom:0}}>f.bierschenk@eathappy.de</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{borderBottom:0}}>30.11.2022</TableCell>
            <TableCell sx={{borderBottom:0}}>a.mueller@eathappy.de</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{borderBottom:0}}>31.10.2022</TableCell>
            <TableCell sx={{borderBottom:0}}>f.bierschenk@eathappy.de</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Box>


            <Modal
              open={opend}
              onClose={handleClosed}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styled}>
                <Typography
                  align="center"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Success
                </Typography>
                <img
                  style={{
                    height: 80,
                    width: 80,
                    marginLeft: "38%",
                    marginTop: 20,
                  }}
                  src={S1}
                  alt="pic"
                />
                <Typography
                  align="center"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                Date confirmed
                </Typography>
              </Box>
            </Modal>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            
            </Box>

           
        </>
    );
}