import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Link, Divider, Typography } from "@mui/material";
import React, { useTransition } from "react";
import { Box } from "@mui/material";
import SubHeader from "../../Layout/Subheader";
import Fab from "@mui/material/Fab";
import { getContacts } from "../../Services/Contacts";
import * as Contacts_Service from "../../Services/Contacts"
import { useTranslation } from "react-i18next";


export const Contact = () => {
    const {t}= useTranslation()
    const [shoptype ,setShoptype] = React.useState(null); 
    const [contacts, setContacts] = React.useState(null);
    const posId = localStorage.getItem("session_posId");
    React.useEffect(() => {

        Contacts_Service.getContacts(setContacts, posId, setShoptype);

    }, []);


    const access_token=localStorage.getItem("access_token")
    

  const refresh_token=localStorage.getItem("refresh_token")

  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`

    return (


        <><SubHeader backbutton={true} btnUrl={dashurl} title={t("contacts_contacts")} />

            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                {contacts ?
                    <Box sx={{ padding: 1 }}>

                        <Table aria-label="simple-table" sx={{ marginBottom: 20 }} >
                            <TableBody>
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, borderColor: "grey", padding: "8px" }}>

                            {t('contacts_market_type')}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0,borderColor: "grey", padding: "8px" }}>
                            {shoptype ? shoptype : "NA"}
                            </TableCell>
                                {contacts.map((item, index) => {
                                   return item.positionTitle === "Kaufmann" || item.positionTitle === "Contact Expansion" || item.positionTitle === "Shop Manager" ?

                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>

                                                        {item.positionTitle}
                                                    </TableCell>
                                                    <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                        {item.contact ? item.contact.fullName : "NA"}
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>

                                                : null       
                                })}
                                {/* <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Divider sx={{ marginTop: 2, maxWidth: "100%" }} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Divider sx={{ marginTop: 2, maxWidth: "100%" }} />
                                    </TableCell>
                                </TableRow> */}

                                    <TableRow>
                                     <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                         <Divider/>
                                         </TableCell>
                                         <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                            <Divider/>
                                         </TableCell>
                                         </TableRow>

                                
                                {contacts.map((item1, index) => {
                                   return item1.positionTitle === t("contacts_ap_expansion") || item1.positionTitle === t("contacts_area_mgr_sr") || item1.positionTitle === t("contacts_area_mgr") || item1.positionTitle === t("contacts_area_mgr_jr") || item1.positionTitle === t("contacts_field_manager") || item1.positionTitle === t("contacts_marketing")?

                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>

                                                        {item1.positionTitle}
                                                    </TableCell>
                                                    <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                        {item1.contact ? item1.contact.fullName : "NA"}
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>

                                                : null       
                                })}
                                            </TableBody>
                        </Table>
                    </Box>
                    : null
}
            </TableContainer>




            {/* <Box >
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(0),
          borderRadius:0,
          height:50,
          width: '100%',
          backgroundColor:"white"
        }}
        
        variant='extended'
        size="small"
        
      >
        <Link href="/Contacts/form" sx={{  color: "white" }}>
          <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width:360,
            fontWeight: 550,
          }}
          disableElevation
        >
             
        Request changes
        </Button></Link>
      </Fab>
      </Box> */}
        </>
    );
}