import axios from "axios";

export function getTickets(setTickets, posId) {
  const access_token = localStorage.getItem("access_token");
  axios
    .get(
        `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/jiraTickets`,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  let tickets = []
  //tickets.push(result)


  console.log(result)
  // result.map((item,index)=> {
  //   if(item.id === posId){
        
  //   }
  // })
  tickets = result.jiraTickets


  setTickets(tickets)

    });



}



export function getTicketsOrder(setTicketsOrder, posId) {
  const access_token = localStorage.getItem("access_token");
  axios
    .post(
        `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/jiraTicketsDescending/jiraTickets`, {},
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  // let tickets = []
  //tickets.push(result)


  console.log(result)
  // result.map((item,index)=> {
  //   if(item.id === posId){
        
  //   }
  // })
  // tickets = result.jiraTickets


  setTicketsOrder(result)

    });



}


export function ticketSubmission(data) {
  const access_token = localStorage.getItem("access_token");
  let payload = {}
  if (data.issue === "Problems cooling")
  {payload = Object.assign(payload, {
    "cost_center_id": data.cost_center_id,
    "pos_type": data.pos_type,
    "descr": data.descr,
    "priority": data.priority,
    "continue_to_sell": data.continue_to_sell,
    "issue": data.issue,
    "sub_issue": data.sub_issue,
    "reported_temperature": data.reported_temperature,
    "files_ids": data.files_ids,
    "telenum" : data.telenum
  })}
  else
  {
    payload = Object.assign(payload, {
      "cost_center_id": data.cost_center_id,
      "pos_type": data.pos_type,
      "descr": data.descr,
      "priority": data.priority,
      "continue_to_sell": data.continue_to_sell,
      "issue": data.issue,
      "sub_issue": data.sub_issue,
      "files_ids": data.files_ids,
      "telenum" : data.telenum
    })
  }
  console.log("payload",payload)

  axios
    .post(
      "https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_JiraTicket/createTicket",payload,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    ).then((res) => {
      console.log(res)
    }).catch(err => {
      console.log(err)

      })
}



function dataURLtoFile(dataurl, filename) {
 
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}



export function uploadFile(file,setFileId){
  const access_token = localStorage.getItem("access_token");
  // var data = new FormData();
  var dfile = dataURLtoFile(file,'upload.png');
console.log(dfile);
  var data = {
    "files_ids": dfile
  }
  // data.append("file",file)
  console.log(data,"datakkk")
  axios
    .post(
      " https://backend.dev.commsapp.eathappy.structr.com/structr/upload/",data,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'multipart/form-data;' },
      }
    ).then((res) => {
      console.log(res,"uploadggg",res.data)
      
      setFileId(res.data)
    }).catch(err => {
      console.log(err,"upladhhh")

      })

}


//////////////////////////////////////////////////////////////////


export function uploadVideo(video,setFilevidid){
  const access_token = localStorage.getItem("access_token");
  // var data = new FormData();
  const formData = new FormData();
  formData.append("file", video, "video.mp4");

  
  axios
    .post(
      " https://backend.dev.commsapp.eathappy.structr.com/structr/upload/",formData,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'multipart/form-data;' },
      }
    ).then((res) => {
      console.log(res,"new video ulpoad convert",res.data)
      
      setFilevidid(res.data)
    }).catch(err => {
      console.log(err,"upladhhh")

      })

}