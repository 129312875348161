import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  Grid,
  Paper,
  Stack,
  Link,
  Container,
} from "@mui/material";
import React from "react";
import SubHeader from "../Layout/Subheader";
import BD1 from "../Assets/Images/Dashboard/Base Data/icon/Vector.svg";
import BD2 from "../Assets/Images/Dashboard/Base Data/icon/Vector1.svg";
import R1 from "../Assets/Images/Dashboard/Reports/icon/Vector.svg";
import R2 from "../Assets/Images/Dashboard/Reports/icon/Vector1.svg";
import T1 from "../Assets/Images/Dashboard/Tickets/icon/Vector.svg";
import T2 from "../Assets/Images/Dashboard/Tickets/icon/Vector1.svg";
import Te1 from "../Assets/Images/Dashboard/Temperature/icon/Vector.svg";
import Te2 from "../Assets/Images/Dashboard/Temperature/icon/Vector1.svg";
import S1 from "../Assets/Images/Dashboard/Sales/icon/Vector.svg";
import S2 from "../Assets/Images/Dashboard/Sales/icon/Vector1.svg";
import C1 from "../Assets/Images/Dashboard/Contact/icon/Vector.svg";
import C2 from "../Assets/Images/Dashboard/Contact/icon/Vector1.svg";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ExpandMore, Login, TwentyZeroMp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import NewspaperTwoToneIcon from "@mui/icons-material/NewspaperTwoTone";
import ContentPasteTwoToneIcon from "@mui/icons-material/ContentPasteTwoTone";
import ConfirmationNumberTwoToneIcon from "@mui/icons-material/ConfirmationNumberTwoTone";
import DeviceThermostatTwoToneIcon from "@mui/icons-material/DeviceThermostatTwoTone";
import DonutSmallTwoToneIcon from "@mui/icons-material/DonutSmallTwoTone";
import ContactPhoneTwoToneIcon from "@mui/icons-material/ContactPhoneTwoTone";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as count_service from "../../src/Services/settings";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { use } from "i18next";
// import queryString from 'query-string'
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 40,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 100 : 500],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#004BA0' : '#004BA0',
  },
}));
export const Dashboard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let isAuthenticated = false;
  const access_token = new URLSearchParams(useLocation().search).get(
    "access_token"
  );


  const refresh_token = new URLSearchParams(useLocation().search).get(
    "refresh_token"
  );


  if (access_token != undefined && refresh_token != undefined) {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
     isAuthenticated = true;
  }
  else {
    window.location.replace('https://backend.dev.commsapp.eathappy.structr.com/oauth/auth0/login?createTokens=true')
  }
  //text hover
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);
  const [isHover5, setIsHover5] = useState(false);
  const [isHover6, setIsHover6] = useState(false);
  const [isHover7, setIsHover7] = useState(false);
  //icons hover
  const [basedata, setBasedata] = useState(false);
  const [report, setReport] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [temperature, setTemperature] = useState(false);
  const [sales, setSales] = useState(false);
  const [contact, setContact] = useState(false);
  const [Cleaning, setCleaning] = useState(false);


  const[count,setCount] = useState(0);
  const[login,setLogin] = useState(null);
  // let logincount = login.count;
  // let newcount = logincount*6.66;
  // setCount(newcount)
  React.useEffect(() => {
    count_service.getMe(setLogin);
  }, []);
  

  React.useEffect(() => {
if (login) {
  console.log(login, 'checkingCount');
  let logincount = login.loginCount;
  if (logincount >= 15 ) {
    setCount(100)
  }
  else {
    let newcount = logincount*6.66;
  setCount(newcount)
  }
}
}, [login]);

  const handleMouseEnter1 = (event) => {
    // event.find("img").attr("src",BD2)
    setBasedata(true)
    setIsHover1(true);
  };
  const handleMouseLeave1 = (event) => {
    setBasedata(false)
    setIsHover1(false);
  };
  const boxStyle1 = {
    color: isHover1 ? "#004bA0" : "#1d2028",
  };
  const handleMouseEnter2 = () => {
    setReport(true)
    setIsHover2(true);
  };
  const handleMouseLeave2 = () => {
    setReport(false)
    setIsHover2(false);
  };
  const boxStyle2 = {
    color: isHover2 ? "#004bA0" : "#1d2028",
  };
  const handleMouseEnter3 = () => {
    setTicket(true)
    setIsHover3(true);
  };
  const handleMouseLeave3 = () => {
    setTicket(false)
    setIsHover3(false);
  };
  const boxStyle3 = {
    color: isHover3 ? "#004bA0" : "#1d2028",
  };
  const handleMouseEnter4 = () => {
    setTemperature(true)
    setIsHover4(true);
  };
  const handleMouseLeave4 = () => {
    setTemperature(false)
    setIsHover4(false);
  };
  const boxStyle4 = {
    color: isHover4 ? "#004bA0" : "#1d2028",
  };
  const handleMouseEnter5 = () => {
    setSales(true)
    setIsHover5(true);
  };
  const handleMouseLeave5 = () => {
    setSales(false)
    setIsHover5(false);
  };
  const boxStyle5 = {
    color: isHover5 ? "#004bA0" : "#1d2028",
  };
  const handleMouseEnter6 = () => {
    setContact(true)
    setIsHover6(true);
  };
  const handleMouseLeave6 = () => {
    setContact(false)
    setIsHover6(false);
  };
  const boxStyle6 = {
    color: isHover6 ? "#004bA0" : "#1d2028",
  };
  const handleMouseLeave7 = () => {
    setCleaning(false)
    setIsHover7(false);
  };
  const boxStyle7 = {
    color: isHover7 ? "#004bA0" : "#1d2028",
  };


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };




  return (
      <>        <SubHeader dropbutton />        
      <Box sx={{ marginTop:3, marginLeft: 3, marginBottom: 3, marginRight: 3 , height:"100%"}}>       
         {/* <Box sx={{ paddingLeft: 20, paddingRight: 20 }}> */}
          <Grid container spacing={3} mb={1}>            
          <Grid item xs={6}>            
          <Link
                  href="/Data"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >                  <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle1}
                  onMouseEnter={handleMouseEnter1}
                  onMouseLeave={handleMouseLeave1}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={basedata?BD2:BD1}
                    alt="pic"
                    className="image_wraper"
                  />                 
                   <Typography className="dblabel">                  
                   {t("dashboard_base_data")}
                  </Typography>              
                  </Paper>              
                  </Link>            
                  </Grid>            
                  <Grid item xs={6}>           
                   <Link
                  href="/Reports"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >                 
                 <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle2}
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={report?R2:R1}
                    alt="pic"
                  />                 
                   <Typography className="dblabel">             
                        {t("dashboard_reports")}
                  </Typography>              
                  </Paper>              
                  </Link>           
                   </Grid>         
                   </Grid>          
                  <Grid container spacing={3} mb={1}>            
                  <Grid item xs={6}>            
                  <Link
                  href="/Tickets"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >                  
                <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle3}
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={ticket?T2:T1}
                    alt="pic"
                  />                 
                   <Typography className="dblabel">                  {t("dashboard_tickets")}
                  </Typography>             
                   </Paper>             
                    </Link>            
                    </Grid>            
                  <Grid item xs={6}>            
                  <Link
                  href="/Temperature"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >             
                 <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle4}
                  onMouseEnter={handleMouseEnter4}
                  onMouseLeave={handleMouseLeave4}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={temperature?Te2:Te1}
                    alt="pic"
                  />                  
                  <Typography className="dblabel">                  {t("dashboard_temperature")}
                  </Typography>             
                   </Paper>            
                     </Link>            
                     </Grid>         
                      </Grid>          
                  <Grid container spacing={3} mb={1}>          
                    <Grid item xs={6}>           
                     <Link
                  href="https://app.powerbi.com/groups/me/reports/832a3015-236f-4f61-93ef-0a7847086c82/Repor[…]3174e6bd8d1f3bf07e8?ctid=64a1d55f-dfaa-4056-8c8a-f757374d5db4"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >                 
                 <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle5}
                  onMouseEnter={handleMouseEnter5}
                  onMouseLeave={handleMouseLeave5}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={sales?S2:S1}
                    alt="pic"
                  />                 
                   <Typography className="dblabel">                  {t("dashboard_sales_discard")}
                  </Typography>             
                   </Paper>             
                    </Link>            
                    </Grid>           
                     <Grid item xs={6}>           
                      <Link
                  href="/Contacts"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >                  
                <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle6}
                  onMouseEnter={handleMouseEnter6}
                  onMouseLeave={handleMouseLeave6}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={contact?C2:C1}
                    alt="pic"
                  />                 
                   <Typography className="dblabel">                  {t("dashboard_contact")}
                  </Typography>     
                   </Paper>    
                     </Link>         
                        </Grid>       
                          </Grid> 
                          
                          <Grid container spacing={3} mb={1}>
                          <Grid item xs={6}>           
                      <Link
                  href="/Cleaning"
                  underline="none"
                  sx={{ fontWeight: "bolder" }}
                >                  
                <Paper
                align="center"
                sx={{ marginBottom: 2, height: 160,borderRadius:3 }}
                elevation={2}
                style={boxStyle6}
                  onMouseEnter={handleMouseEnter6}
                  onMouseLeave={handleMouseLeave6}
              >                  <img
                    style={{ marginBottom: 10,height: 65, width: 65, marginTop: 30 }}
                    src={Cleaning?R2:R1}
                    alt="pic"
                  />                 
                   <Typography className="dblabel">                  Cleaning <br></br> Schedule
                  </Typography>     
                   </Paper>    
                     </Link>         
                        </Grid>  
                          </Grid>

                           <Box sx={{ flexGrow: 1 }}>    
                           <Link href="/Eegg">    
                            <BorderLinearProgress variant="determinate" value={count} />    
                              </Link>    
                                 <Box sx={{marginLeft:"40%"}}>
                                  <Typography sx={{color:"black"}}>Login Stats</Typography></Box> 
                                   </Box>    
                                   </Box>   
                                   </>   
                                    );
  };