import axios from "axios";



export function getMe(setMe) {
  const access_token = localStorage.getItem("access_token");
  axios
    .post(
       `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/User/retrieveUserInfo`, 
       {},
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  let me = {}
  // console.log(result)
  me = result
setMe(me)

    });
}
