import { Box, Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";
import SubHeader from "../../Layout/Subheader";
import Fab from "@mui/material/Fab";
import { getTemperature } from "../../Services/Temperature";
import * as temperature_Service from "../../Services/Temperature"
import * as costcenter from "../../Services/Costcenter"
import i18n from "../../i18n/config";
import { useTranslation } from 'react-i18next';


export const Temperature = () => {
    const { t } = useTranslation();

    const [temperature, setTemperature] = React.useState(null);
    const posId = localStorage.getItem("session_posId");
    const costcenter = localStorage.getItem("session_costcenter")

    React.useEffect(() => {

        temperature_Service.getTemperature(setTemperature, posId);

    }, []);

 //  const highTemp = temperature.highest24HoursTemperature || "0"
let highTemp = "0"

if(temperature){
    highTemp = temperature.highest24HoursTemperature || "0"
}
    const access_token=localStorage.getItem("access_token")
    

  const refresh_token=localStorage.getItem("refresh_token")

  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`
  
    return (

        <><SubHeader backbutton={true} btnUrl={dashurl} title={t("temperature_temperature")} />

            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Box sx={{ padding: 1 }}>
                    <Table aria-label="simple-table" sx={{ marginBottom: 100 }}>
                        {temperature ?
                            <TableBody>

                                <TableRow
                                    key="1"
                                    sx={{ borderBottom: 0 }}
                                >
                                    <TableCell className="contactstaticlabel" component="th" scope="row" sx={{ borderBottom: 0, padding: "8px" }}>
                                        {t("temperature_temp_average")}
                                    </TableCell>
                                    <TableCell className="contactdatalabel" align="right" sx={{ borderBottom: 0, padding: "8px" }}>
                                        {temperature.average24HoursTemperature}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key="2"
                                    sx={{ borderBottom: 0 }}
                                >
                                    <TableCell className="contactstaticlabel" component="th" scope="row" sx={{ padding: "8px" }}>
                                        {t("temperature_temp_highest")}
                                    </TableCell>
                                    <TableCell className="contactdatalabel" align="right" sx={{ padding: "8px" }}>
                                        {temperature.highest24HoursTemperature}
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow
                        key="3"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell className="contactstaticlabel" component="th" scope="row" sx={{ padding:"8px"}}>
                            Delivery App
                        </TableCell>
                        <TableCell className="contactdatalabel" align="right" sx={{ padding:"8px"}}>
                        3°
                        </TableCell>
                    </TableRow> */}
                                <br></br>
                                <TableRow
                                    key="4"
                                    sx={{ borderBottom: 0, paddingTop: 100 }}
                                >
                                    <TableCell className="contactstaticlabel" component="th" scope="row" sx={{ borderBottom: 0, padding: "8px" }}>
                                        {t("temperature_last_alarm")}
                                    </TableCell>
                                    <TableCell className="contactdatalabel" align="right" sx={{ borderBottom: 0, padding: "8px" }}>
                                        {temperature.lastAlarmDate}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key="5"
                                    sx={{ borderBottom: 0 }}
                                >
                                    <TableCell className="contactstaticlabel" component="th" scope="row" sx={{ borderBottom: 0, padding: "8px" }}>
                                        {t("temperature_abnormalities")}
                                    </TableCell>
                                    <TableCell className="contactdatalabel" align="right" sx={{ borderBottom: 0, padding: "8px" }}>
                                        {temperature.hasAbnormalities}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            : t("temperature_no_exists")
                            
                            
                            }
                    </Table>
                </Box>
            </TableContainer>


            <Box sx={{ marginRight: 1 }}>
                <Fab
                    sx={{
                        position: "fixed",
                        bottom: (theme) => theme.spacing(0),

                        borderRadius: 0,
                        height: 50,
                        width: '100%',
                        backgroundColor: "white"

                    }}
                    variant='extended'
                    size="small"

                >
                     {/* <Button

                        sx={{
                            textTransform: "none",
                            backgroundColor: "#FFFFFF",

                            width: 156,
                            fontWeight: 600,
                            margin: 2
                        }}
                        disableElevation
                    >
                        Analyse in QMSpot */}
                <Link

                     href={"mailto:qs-truhen@fcf-holding.com?Subject=Please%20check%20sensor&body=Hey, there seems to be an abnormality with Chiller "+costcenter+".The sensor reported a temperature of "+highTemp+". Please check if chiller needs to be set . Thanks"}

                     underline="none"

                   > 
                        <Button

                        variant="contained"

                        sx={{
                            textTransform: "none",

                            backgroundColor: "#004bA0",

                            width: 200,

                            fontWeight: 550
                        }}

                        disableElevation

                    >

                    {t("temperature_report_abnormalities")}

                        </Button>
                         </Link> 
                </Fab>

            </Box>
        </>
    );
}