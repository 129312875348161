import React from "react";
import {
  Grid,
  Card,
  Typography,
  Paper,
  Button,
  Link,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ExpandMore } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Link as Link1 } from "react-router-dom";

import Warning from "../../Components/Popup";
import Autocomplete from "@mui/material/Autocomplete";

import * as cs from "../../Components/Costcenter/data";
import { getCenters } from "../../Services/Costcenter";
import * as cc_service from "../../Services/Costcenter";
import { useTranslation } from "react-i18next";

// ######################################################## // The main Home component returned by this Module // ########################################################

const SubHeader = ({
  
  title,
  dropbutton,
  btnUrl,
  backbutton,
  modal_flag = false,
  warning_flag = false,
  bf,
}) => {
  
  // Material UI Classes title //
  const [open, setOpen] = React.useState(false);
  const [costcenter, setCostcenter] = React.useState(null);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleMenuItemClick = (event, index) => {
    
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleClick = (event) => {
    setOpen(true);
  };

  React.useEffect(() => {
    cc_service.getCenters(setCostcenter);
  }, []);

  const costcentervalue = localStorage.getItem("session_costcenter")
  const { t } = useTranslation();
  const default_option = [{"label":"Loading...","id":"-1"}] 
  console.log(costcenter);
  return (
    <>
      <Paper>
        <Grid
          //   sx={{
          //     display: "flex",
          //     justifyContent: "left",
          //     alignItems: "left",
          //     padding: 2,
          //     justifyContent: "space-between",
          //     color: "black" ? "grey.800" : "#ffffff",
          //   }}
          sx={{ minHeight: 80, backgroundColor: "#004bA0" }}
        >
          <Grid container>
            <Grid>
              {backbutton == true ? (
                warning_flag == true ? (
                  <Button sx={{ marginTop: "35%" }} onClick={bf}>
                    {<ArrowBackIcon variant="h6" sx={{ color: "white" }} />}
                  </Button>
                ) : (
                  <Button
                    component={Link1}
                    to={btnUrl}
                    sx={{ marginTop: "35%" }}
                  >
                    {<ArrowBackIcon variant="h6" sx={{ color: "white" }} />}
                  </Button>
                )
              ) : null}
              {/* {backbutton && (
            <Button component={Link1} to={btnUrl} sx={{ marginTop:"35%"}}>
              {<ArrowBackIcon variant="h6" sx={{color:"white"}}/>}
            </Button>
          )}{" "} */}
            </Grid>
            <Grid sx={{ marginTop: 3 }}>
              {title && (
                <Typography variant="h6" color="white" fontWeight={700}>
                  {title}
                </Typography>
              )}{" "}
            </Grid>
          </Grid>
          {dropbutton == true ? (
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                <Autocomplete 
                loading={true}
                value={costcentervalue === undefined || costcentervalue === null? t('dashboard_select') : costcentervalue}
                  disablePortal
                  id="Costcenter name"
    
                  options={costcenter? costcenter.map((option)=> option.label):
                   
                    default_option.map((option)=> option.label)
                  }
                  
                  renderInput={(params) => (
                    
                    <TextField sx={{marginTop:-2, marginLeft:1}} {...params} className="costcentersublabel" label= {t('dashboard_cost_centre')} />
                  )}
                  onChange={(event, newValue) => {
                    //costcenter?
                    costcenter.map((cc)=>{
                      if(cc.label === newValue) {
                        localStorage.setItem("session_posId", cc.id);
                        localStorage.setItem("session_costcenter", cc.label);
                      }
                    })
                    //:null
                  }}
                />
              </Typography>
              {/* <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
            > */}
              <Link href="/Settings" sx={{ color: "white" }}>
                <Button size="small" sx={{ backgroundColor: "#004bA0" }}>
                  <SettingsOutlinedIcon
                    fontSize="large"
                    sx={{ color: "white" }}
                  />
                </Button>
              </Link>
              {/* </ButtonGroup> */}
            </Toolbar>
          ) : null}
          {/* {dropbutton && (
            <Toolbar >
            <Typography color="white" variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Select KST
              <IconButton sx={{color:"white"}}>
                <ExpandMore />
              </IconButton>
            </Typography>
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
            >
              <Link href="/Settings" sx={{  color: "white" }}><Button
                size="small"
                sx={{ backgroundColor: "#004bA0" }}
              >
                <SettingsSuggestIcon />
              </Button></Link>
            </ButtonGroup>
          </Toolbar>
          )}{" "} */}
        </Grid>{" "}
      </Paper>{" "}
    </>
  );
};

export default SubHeader;
