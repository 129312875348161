import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Card,
  Modal,
  CardActions,
  CardContent,

} from "@mui/material";
import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,Link
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import W1 from "../../Assets/Images/Popups/Vector2.svg";



import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import SubHeader from "../../Layout/Subheader";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  // left: '50%',
  // right: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initialValues = {
  "informTeamFeedback": false,

}


export const Report24 = () => {
  const {t} = useTranslation()

    const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));



    const [yesbtn,setYesbtn] = React.useState(false)

    const location = useLocation()
  console.log("location", location)
  const {report23_set} = location.state
  const navigate = useNavigate()
console.log("inside22",report23_set)


    // const handleChange = (newValue) => {
    //   setValue(newValue);
    // };

    const [values, setValues] = React.useState(initialValues);
    const handleChange = (newValue) => {
      setValues(newValue);
    };

    const [ehStaff, setEhStaff] = React.useState([])
    const handleEhStaff = (event) =>{
      // alert(event.target.checked+" == "+ event.target.name)
      const name = 'staffFeedback'
      // setEhStaff({
      // ...ehStaff,
      // [name] : event.target.name
      // })
      // console.log(ehStaff)
      if(!ehStaff.includes(event.target.name)){
      
        setEhStaff([...ehStaff, event.target.name])}
    }

    const [feedback, setFeedback] = React.useState([])
    const handleFeedback = (event) =>{
      // alert(event.target.checked+" == "+ event.target.name)
      const name = 'option'
      // setFeedback({
      // ...feedback,
      // [name] : event.target.name
      // })
      // console.log(feedback)
      if(!feedback.includes(event.target.name)){
      
        setFeedback([...feedback, event.target.name])}
    }

    const [meetingRequest, setMeetingRequest] = React.useState([])
    const handleMeetingRequest = (event) =>{
      // alert(event.target.checked+" == "+ event.target.name)
      const name = 'option'
      // setMeetingRequest({
      // ...meetingRequest,
      // [name] : event.target.name
      // })
      // console.log(meetingRequest)
      if(!meetingRequest.includes(event.target.name)){
      
        setMeetingRequest([...meetingRequest, event.target.name])}
    }

    const textField = {

    }

    const [warning, setWarning] = React.useState(false);
    const handleWarningopen = () => {
        setWarning(true);
            
    }
    const handleWarningClose = () => setWarning(false);
    const [temperature, setTemperature] = React.useState(null)
    // const handleTemperature = (event) => {
    //   setTemperature ({
    //     ...temperature,
    //     [event.target.name]:event.target.checked,
    //   })
    // }

    const [food, setFood] = React.useState(null)
    // const handleFood = (event) => {
    //   setFood ({
    //     ...food,
    //     [event.target.name]:event.target.checked,
    //   })
    // }

    const [defects, setDefects] = React.useState(null)
    // const handleDefects = (event) => {
    //   setDefects ({
    //     ...defects,
    //     [event.target.name]:event.target.checked,
    //   })
    // }


    const handleYesClick = (event) =>{
      const yesvalue = event.target.value
      if(yesvalue === 'report24_selection_yes'){
        setYesbtn(true);
        const name = "addFeedback"
        const addFeedback = true
        setValues({
          ...values,
          [name]: addFeedback
      })
      }
      else{
      setYesbtn(false);
      const name = "addFeedback"
      const addFeedback = false
      setValues({
        ...values,
        [name]: addFeedback
    })
      }
    }

    const feedbackClick = (event) => {

      let isChecked = false
    if(event.target.value === 'report24_feedback_meeting_request_yes'){
      // alert("Hi")
      isChecked = true
    }
    values.informTeamFeedback = isChecked
      // const yesvalue = event.target.value;
      // if (yesvalue === "Yes") {
      //   //setYesbtn(true);
      //   const name = "informTeamFeedback"
      //   const informTeamFeedback = true
      //   setValues({
      //     ...values,
      //     [name]: informTeamFeedback
      // })
      // } else {
      //   //setYesbtn(false);
      //   const name = "informTeamFeedback"
      //   const informTeamFeedback = false
      //   setValues({
      //     ...values,
      //     [name]: informTeamFeedback
      // })
      // }
    };

    const handleNextClick = () => {
      let report24_set = {}
      report24_set = Object.assign(report24_set,report23_set)
      if(values.addFeedback === true) {
         
        report24_set = Object.assign(report24_set,{"addFeedback":true})
        let ehStaff_data = []
        ehStaff.map((item)=>{
          if(item === "Other"){
           const text_val = document.getElementById("ehOther")
           ehStaff_data.push({"staffFeedback":text_val})
          }else{
          ehStaff_data.push({"staffFeedback":item})
          }
        })

        let feedback_data = []
        feedback.map((item)=>{
          if(item === "Other"){
           const text_val = document.getElementById("feedbackOther")
           feedback_data.push({"option":text_val})
          }else{
          feedback_data.push({"option":item})
          }
        })

        let meeting_data = []
        meetingRequest.map((item)=>{
          if(item === "Other"){
           const text_val = document.getElementById("meetingOther")
           meeting_data.push({"option":text_val})
          }else{
          meeting_data.push({"option":item})
          }
        })

        // const namePositionExternalContactPerson = document.getElementById("externalContact").value || null
        const namePositionExternalContactPerson = "NA"
        console.log(namePositionExternalContactPerson, namePositionExternalContactPerson.length)


        const feedbacks = {"feedback":{
          "informTeamFeedback": values.informTeamFeedback,
          "ehStaffs": ehStaff_data,
          "feedbackContactPersons": feedback_data,
          "meetingRequestExternalContactPersons": meeting_data,
          "namePositionExternalContactPerson" : namePositionExternalContactPerson.length === 0 ? "NA" : namePositionExternalContactPerson
        }
        }
        report24_set = Object.assign(report24_set, feedbacks)
        navigate('/Reports/Report25',{state:{report24_set}})
      }
      else {
        navigate('/Reports/Report25',{state:{report24_set}})
      }
      console.log("report24", report24_set)
    }

    const backClick = () => {
      navigate('/Reports/Report23',{state:{report22_set : report23_set}})
    }

  return (
    <>
    <SubHeader backbutton={true} btnUrl="/Reports/Report23" title={t("report24_new_report")} warning_flag={true} bf={handleWarningopen}/>
    <Box backgroundColor="white" >
    <Box sx={{marginLeft:3,marginRight:3}}>
      {" "}
      
      
      <Box marginLeft={1} >
        <Typography
          className="reportoverviewheadingtlabel"
        >
          <br></br>
          {t("report24_staffing_feedback")} 
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 1,
        }}
      >
        <FormControl >
          <FormLabel className="reportsinfoslabel " id="POS">{t("report24_selection")} </FormLabel>
          <Typography className="reportsinfofadelabel">{t("report24_selection_infotext")} </Typography>
          <RadioGroup
            row
            aria-labelledby="POS"
            defaultValue="report24_selection_no"
            name="POS-filled"
            onChange={handleYesClick}
          >
            
            
            <FormControlLabel className="settingsemployeenamelabel" value="report24_selection_yes" control={<Radio />} label={t("report24_selection_yes")} />
            <FormControlLabel className="settingsemployeenamelabel" value="report24_selection_no" control={<Radio />} label={t("report24_selection_no")} />
          </RadioGroup>
        </FormControl>
      

    

        {yesbtn == true ? 
        <><FormLabel sx={{marginTop:2}} className="reportsinfoslabel">{t("report24_eh_staff")}</FormLabel><FormGroup>
              <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_eh_staff_not_enough")} name="No/too litte staff" onChange={handleEhStaff}/>
              <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_eh_staff_too_much")} name="Too much staff" onChange={handleEhStaff}/>
              <FormControlLabel  control={<Checkbox />} label={<TextField id="ehOther" label={t("report24_eh_staff_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}} size="small" />}  onChange={handleEhStaff}/>
            </FormGroup><FormLabel sx={{marginTop:2}} className="reportsinfoslabel">{t("report24_feedback")}</FormLabel><FormGroup>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_is_happy")} name="Is happy/no negative comments" onChange={handleFeedback}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_sales")} name="Unhappy about sales" onChange={handleFeedback}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_assortment")} name="Unhappy about assortment" onChange={handleFeedback}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_eh_staff")} name="Unhappy about EH staff" onChange={handleFeedback}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_change_location")} name="Change request location" onChange={handleFeedback}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_technical_issues")} name="Technical issues/problems to discuss" onChange={handleFeedback}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_feedback_marketing_request")} name="Marketing request" onChange={handleFeedback}/>
                <FormControlLabel control={<Checkbox />} label={<TextField id="feedbackOther" label={t("report24_feedback_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}} size="small" />} onChange={handleFeedback}/>
              </FormGroup><FormLabel sx={{marginTop:2}} className="reportsinfoslabel">{t("report24_meeting_request")}</FormLabel><FormGroup>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_meeting_request_check-in")}  name="Check-In requested" onChange={handleMeetingRequest}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_meeting_request_sales")} name="Speak about sales numbers" onChange={handleMeetingRequest}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_meeting_request_contract")} name="Speak about contract" onChange={handleMeetingRequest}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_meeting_request_assortment")} name="Speak about assortment" onChange={handleMeetingRequest}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_meeting_request_look_feel")} name="Speak about look/feel" onChange={handleMeetingRequest}/>
                <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report24_meeting_request_staff")} name="Speak about staff" onChange={handleMeetingRequest}/>
                <FormControlLabel control={<Checkbox />} label={<TextField id="meetingOther" label={t("report24_meeting_request_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}} size="small" />} onChange={handleMeetingRequest}/>
              </FormGroup>
              {/* <Grid item sx={{ display: "flex", flexDirection: "column", marginLeft:-1 }} xs={12} sm={6}>
                <Typography sx={{marginTop:2}} className="reportsinfoslabel"> Wie lautet der Name & Position des Ansprechpartners? </Typography>
                <TextField id="externalContact" sx={{ backgroundColor: "#F5F5F5", marginTop: 1, }}>

                </TextField>
              </Grid> */}
              <Box  >

                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                >
                  <FormControl >
                    <FormLabel className="reportsinfoslabel" id="POS">{t("report24_feedback_meeting_request_selection")}</FormLabel>
                    <Typography className="reportsinfofadelabel">{t("report24_feedback_meeting_request_infotext")}</Typography>
                    <RadioGroup
                      row
                      aria-labelledby="POS"
                      defaultValue="report24_feedback_meeting_request_no"
                      name="POS-filled"
                      onChange={feedbackClick}
                    >
                      <FormControlLabel  className="settingsemployeenamelabel" value="report24_feedback_meeting_request_yes" control={<Radio />} label={t("report24_feedback_meeting_request_yes")} />
                      <FormControlLabel  className="settingsemployeenamelabel" value="report24_feedback_meeting_request_no" control={<Radio />} label={t("report24_feedback_meeting_request_no")} />

                    </RadioGroup>
                  </FormControl>

                </Grid>
                
              </Box></>
      

      :null }


      </Grid>
      <Divider sx={{ marginTop: 2, maxWidth: 750 }} />
      <Box
                m={1}
                display="flex"
                marginTop={2}
                justifyContent="center"
                alignItems="center"
            >
                <Link href="/Reports/Report23" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    width:120,
                    fontWeight:600,
                    margin:2}} 
                    disableElevation
                    onClick={backClick}
                >
                    {t("report24_back")}
                </Button></Link>
                {/* /Reports/Report25 */}
                {/* <Link href="#" sx={{  color: "white" }}>*/}<Button  
                    variant="contained" 
                    sx={{textTransform:"none", 
                        backgroundColor:"#004bA0",
                        width:156,
                        fontWeight:550}} 
                    disableElevation
                    onClick={handleNextClick}
                 >
                      {t("report24_next")}
                </Button>
                {/* </Link> */}

                <Modal
        open={warning}
        onClose={handleWarningClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{left:'50%'}}
      >
       <Card sx={style}>
        <CardContent>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
            
          {t("report24_warning_message_infotext1")}
          
          </Typography>
          
          <img
              style={{ height: 80, width: 80,marginLeft:"30%", marginTop: 20 }}
              src={W1}
              alt="pic"
              
              />
              <br></br>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
          {t("report24_warning_message_infotext2")}
            </Typography>
            </CardContent>

              <CardActions style={{ width: 500, marginTop: 20, marginLeft:0 }}>

            <Link href="/Reports" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    // width:50,
                    fontWeight:600,
                    // margin:2
                }} 
                    disableElevation
                >
                     {t("report24_warning_message_back")}
                </Button></Link>
                <Link href="/Reports/Report24" sx={{  color: "white" }}><Button 
                    variant="outlined" 
                    sx={{textTransform:"none", 
                        // width:100,
                        fontWeight:550}} 
                    disableElevation
                 >
                    {t("report24_warning_message_finish")}
                </Button></Link>
            </CardActions>

        </Card>
       
      </Modal>
            </Box>
      </Box>
      </Box>
    </>
  );
};
