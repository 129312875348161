import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Modal,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import W1 from "../../Assets/Images/Popups/Vector2.svg";

import dayjs from "dayjs";
import Stack from "@mui/material/Stack";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import SubHeader from "../../Layout/Subheader";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  // left: '50%',
  // right: '50%',
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initialValues = {
  "addAppearanceAssortmentInfo" : false,
  "isPosCleanEnough": false,
  "materialNeedToBeReplaced": false,
  "informTeamToReplace": false,
  "isPosFilledSufficiently": false,
  "posCleanedDuringVisit": false,
  "issuesWithStorageArea": false,
}


export const Report23 = () => {
  const {t} = useTranslation()
  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));

  const [yesbtn, setYesbtn] = React.useState(false);

  const [cleanbtn, setCleanbtn] = React.useState(true);

  const [sufficientbtn, setSufficientbtn] = React.useState(true);

  const [storagebtn, setStoragebtn] = React.useState(false);

  const [materialbtn, setMaterialbtn] = React.useState(false);

  const [replacebtn, setReplacebtn] = React.useState(false);



  const [values, setValues] = React.useState(initialValues);


  const location = useLocation()
  console.log("location", location)
  const {report22_set} = location.state
   const navigate = useNavigate()
console.log("inside22",report22_set)


  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  const [itemClean, setItemClean] = React.useState([])
  const handleItemClean = (event) =>{
    // alert(event.target.checked+" == "+ event.target.name)
    const name = 'item'
    // setItemClean({
    // ...itemClean,
    // [name] : event.target.name
    // })
    // console.log(itemClean)
    if(!itemClean.includes(event.target.name)){
      
      setItemClean([...itemClean, event.target.name])}
  }

  const [assortment, setAssortment] = React.useState([])
  const handleAssortment = (event) =>{
    // alert(event.target.checked+" == "+ event.target.name)
    const name = 'option'
    // setAssortment({
    // ...assortment,
    // [name] : event.target.name
    // })
    // console.log(assortment)
    if(!assortment.includes(event.target.name)){
      
      setAssortment([...assortment, event.target.name])}
  }

  const [requestDays, setRequestDays] = React.useState([])
  const handleRequestDays = (event) =>{
    // alert(event.target.checked+" == "+ event.target.name)
    const name = 'requestDay'
    // setRequestDays({
    // ...requestDays,
    // [name] : event.target.name
    // })
    // console.log(requestDays)
    if(!requestDays.includes(event.target.name)){
      
      setRequestDays([...requestDays, event.target.name])}
  }

  const [replaceMaterial, setReplacematerial] = React.useState([])
  const handleReplaceMaterials = (event) =>{
   // alert(event.target.checked+" == "+ event.target.name)
    const name = 'replacementType'
    // setReplacematerial({
    // ...replaceMaterial,
    // [name] : event.target.name
    // })
    // console.log(replaceMaterial)
    if(event.target.name === "Box content (soy sauce, wasabi, etc. - opens follow-up question)"){

    if(event.target.checked === true){
      setReplacebtn(true)
    }
    else {
      setReplacebtn(false)
      setBoxContent([])
      // const index = replaceMaterial.indexOf(event.target.name)
      // const x = replaceMaterial.splice(index, 1)
      // setReplacematerial(replaceMaterial)
    }
  }
  
   
    if(!replaceMaterial.includes(event.target.name)){
      
      setReplacematerial([...replaceMaterial, event.target.name])}
      console.log("boxcheck",replacebtn)
    
  }

  const [boxContent, setBoxContent] = React.useState([])
  const handleBoxContent = (event) =>{
    // alert(event.target.checked+" == "+ event.target.name)
    const name = 'content'
    // setBoxContent({
    // ...boxContent,
    // [name] : event.target.name
    // })
    // console.log(boxContent)
    if(!boxContent.includes(event.target.name)){
      
      setBoxContent([...boxContent, event.target.name])}
  }

  const textField = {};

  const [warning, setWarning] = React.useState(false);
  const handleWarningopen = () => {
    setWarning(true);
  };
  const handleWarningClose = () => setWarning(false);
  // const [temperature, setTemperature] = React.useState(null)
  // const handleTemperature = (event) => {
  //   setTemperature ({
  //     ...temperature,
  //     [event.target.name]:event.target.checked,
  //   })
  // }

  // const [food, setFood] = React.useState(null)
  // const handleFood = (event) => {
  //   setFood ({
  //     ...food,
  //     [event.target.name]:event.target.checked,
  //   })
  // }

  // const [defects, setDefects] = React.useState(null)
  // const handleDefects = (event) => {
  //   setDefects ({
  //     ...defects,
  //     [event.target.name]:event.target.checked,
  //   })
  // }

  const handleYesClick = (event) => {
    const yesvalue = event.target.value;
    if (yesvalue === 'report23_selection_yes') {
      setYesbtn(true);
      const name = "addAppearanceAssortmentInfo"
      const addAppearanceAssortmentInfo = true
      setValues({
        ...values,
        [name]: addAppearanceAssortmentInfo
    })

    } else {
      setYesbtn(false);
      
      const name = "addAppearanceAssortmentInfo"
      const addAppearanceAssortmentInfo = true
      setValues({
        ...values,
        [name]: addAppearanceAssortmentInfo
    })
    }
  };

  const posCleanClick = (event) => {

    let isChecked = false
    if(event.target.value === 'report23_pos_clean_yes'){
      setCleanbtn(true)
      // alert("Hi")
      isChecked = true
    }
    else{
      setCleanbtn(false)
    }
    values.isPosCleanEnough = isChecked
  //   setValues({
  //     ...values,
  //     ["isPosCleanEnough"]: isChecked
  // })
  // console.log("values inside posclick",values,isChecked)
};
  //   }
  //   if (yesvalue === "Yes") {
  //     //setYesbtn(true);

  //     const name = "isPosCleanEnough"
  //     const isPosCleanEnough = true
  //     setValues({
  //       ...values,
  //       [name]: isPosCleanEnough
  //   })
  //   console.log("inside yes",values)
  //   } else {
  //     //setYesbtn(false);
  //     const name = "isPosCleanEnough"
  //     const isPosCleanEnough = false
  //     setValues({
  //       ...values,
  //       [name]: isPosCleanEnough
  //   })
  //   }
  //   console.log("values",values)
  // };

  const materialReplaceClick = (event) => {

    let isChecked1 = false
    if(event.target.value === 'report23_replace_material_yes'){
      setMaterialbtn(true)
      // alert("Hi")
      isChecked1 = true
    }
    else{
      setMaterialbtn(false)
    }
    values.materialNeedToBeReplaced = isChecked1
    // const yesvalue = event.target.value;
    // if (yesvalue === "Yes") {
    //   //setYesbtn(true);
    //   const name = "materialNeedToBeReplaced"
    //   const materialNeedToBeReplaced = true
    //   setValues({
    //     ...values,
    //     [name]: materialNeedToBeReplaced
    // })
    // } else {
    //   //setYesbtn(false);
    //   const name = "materialNeedToBeReplaced"
    //   const materialNeedToBeReplaced = false
    //   setValues({
    //     ...values,
    //     [name]: materialNeedToBeReplaced
    // })
    // }
  };

  const posVisitClick = (event) => {

    let isChecked2 = false
    if(event.target.value === 'report23_clean_items_selection_yes'){
      // alert("Hi")
      isChecked2 = true
    }
    values.posCleanedDuringVisit = isChecked2
    // const yesvalue = event.target.value;
    // if (yesvalue === "Yes") {
    //   //setYesbtn(true);
    //   const name = "posCleanedDuringVisit"
    //   const posCleanedDuringVisit = true
    //   setValues({
    //     ...values,
    //     [name]: posCleanedDuringVisit
    // })
    // } else {
    //   //setYesbtn(false);
    //   const name = "posCleanedDuringVisit"
    //   const posCleanedDuringVisit = false
    //   setValues({
    //     ...values,
    //     [name]: posCleanedDuringVisit
    // })
    // }
  };

  const storageAreaClick = (event) => {

    let isChecked3 = false
    if(event.target.value === 'report23_storage_issues_yes'){
      setStoragebtn(true)
      // alert("Hi")
      isChecked3 = true
    }
    else{
      setStoragebtn(false)
    }
    values.issuesWithStorageArea = isChecked3
    // const yesvalue = event.target.value;
    // if (yesvalue === "Yes") {
    //   //setYesbtn(true);
    //   const name = "issuesWithStorageArea"
    //   const issuesWithStorageArea = true
    //   setValues({
    //     ...values,
    //     [name]: issuesWithStorageArea
    // })
    // } else {
    //   //setYesbtn(false);
    //   const name = "issuesWithStorageArea"
    //   const issuesWithStorageArea = false
    //   setValues({
    //     ...values,
    //     [name]: issuesWithStorageArea
    // })
    // }
  };

  const informTeamClick = (event) => {

    let isChecked4 = false
    if(event.target.value === 'report23_inform_team_yes'){
      // alert("Hi")
      isChecked4 = true
    }
    values.informTeamToReplace = isChecked4
    // const yesvalue = event.target.value;
    // if (yesvalue === "Yes") {
    //   //setYesbtn(true);
    //   const name = "informTeamToReplace"
    //   const informTeamToReplace = true
    //   setValues({
    //     ...values,
    //     [name]: informTeamToReplace
    // })
    // } else {
    //   //setYesbtn(false);
    //   const name = "informTeamToReplace"
    //   const informTeamToReplace = false
    //   setValues({
    //     ...values,
    //     [name]: informTeamToReplace
    // })
    // }
  };

  const posSufficientClick = (event) => {
    let isChecked5 = false
    if(event.target.value === 'report23_pos_filled_yes'){
      setSufficientbtn(true)
      // alert("Hi")
      isChecked5 = true
    }
    else {
      setSufficientbtn(false)
    }
    values.isPosFilledSufficiently = isChecked5
  }

const handleNextClick = () => {
  let report23_set = {}
    report23_set = Object.assign(report23_set, report22_set)
  if(values.addAppearanceAssortmentInfo === true){
    report23_set = Object.assign(report23_set,{"addAppearanceAssortmentInfo":true})
    let itemClean_data = []
        itemClean.map((item)=>{
         
          itemClean_data.push({"item":item})
          
        })
        let boxContent_data = []
        boxContent.map((item)=>{
         
          boxContent_data.push({"content":item})
          
        })
    let assortment_data = []
        assortment.map((item)=>{
          if(item === "Other") {
            const text_val = document.getElementById("assortmentOther")
            assortment_data.push({"option":text_val})
          }
         else{
        assortment_data.push({"option":item})
      }
        })
    let requestDays_data = []
        requestDays.map((item)=>{
         
          requestDays_data.push({"requestedDay":item})
          
        })
    let replaceMaterial_data = []
        replaceMaterial.map((item)=>{
         if(item === "Other"){
          const text_val = document.getElementById("materialOther")
          if(boxContent.length ===0){
            replaceMaterial_data.push({
              "replacementType":text_val,
              "informTeamToReplace": values.informTeamToReplace
            }
              )
          }
          else{
            if(item === "Box content (soy sauce, wasabi, etc. - opens follow-up question)"){
            replaceMaterial_data.push({
              "replacementType":text_val,
              "informTeamToReplace": values.informTeamToReplace,
              "boxContents":boxContent_data
            }
              )
            }
            else {
              replaceMaterial_data.push({
                "replacementType":text_val,
                "informTeamToReplace": values.informTeamToReplace,
              }
                )
            }
          }
         }
         else{
          if(boxContent.length ===0){
            replaceMaterial_data.push({
              "replacementType":item,
              "informTeamToReplace": values.informTeamToReplace
            }
              )
          }
          else{
            if(item === "Box content (soy sauce, wasabi, etc. - opens follow-up question)") {
            replaceMaterial_data.push({
              "replacementType":item,
              "informTeamToReplace": values.informTeamToReplace,
              "boxContents":boxContent_data
            }
              )
          }
          else{
            replaceMaterial_data.push({
              "replacementType":item,
              "informTeamToReplace": values.informTeamToReplace,
            }
              )
          }
          }
        } 
        
        })
    
        let storageDescription = "NA"
        if(values.issuesWithStorageArea){
          storageDescription = document.getElementById('sanote').value
        }
        console.log("replaceMaterial_data",replaceMaterial_data)
        const appearance = {"appearance": {
          "isPosCleanEnough": values.isPosCleanEnough,
          "itemsToBeCleaned": itemClean_data,
          "posCleanedDuringVisit": values.posCleanedDuringVisit,
          "issuesWithStorageArea": values.issuesWithStorageArea,
          "issuesWithStorageAreaText": storageDescription,
          "materialNeedToBeReplaced": values.materialNeedToBeReplaced,
          "materialsToBeReplaced": replaceMaterial_data
        }
      }
      const assortments = { "assortment": {
        "isPosFilledSufficiently": values.isPosFilledSufficiently,
        "assortmentChangeRequests": assortment_data,
        "requestedDays": requestDays_data
      } 
      }
     const test_set = Object.assign(appearance, assortments)
     report23_set = Object.assign(report23_set, test_set)
     navigate('/Reports/Report24',{state:{report23_set}}) 
  }
  else {
    navigate('/Reports/Report24',{state:{report23_set}})
    console.log("report")
  }
  console.log("report")
}

const backClick = () => {
  navigate('/Reports/Report22',{state:{dataset : report22_set}})
  console.log("report")
}

  return (
    <>
      <SubHeader
        backbutton={true}
        btnUrl="/Reports/Report22"
        title={t("report23_new_report")}
        warning_flag={true}
        bf={handleWarningopen}
      />
      <Box backgroundColor="white">
        <Box sx={{ marginLeft: 3, marginRight: 3 }}>
          {" "}
          <Box marginLeft={1}>
            <Typography
              className="reportoverviewheadingtlabel"
            >
              <br></br>
              {t("report23_appearance_assortment")}
            </Typography>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: 2,
              marginLeft: 1,
            }}
          >
            <FormControl>
              <FormLabel className="reportsinfoslabel " id="POS">
              {t("report23_selection")}
              </FormLabel>
              <Typography className="reportsinfofadelabel">
              {t("report23_selection_infotext")}
              </Typography>
              <RadioGroup
                row
                aria-labelledby="POS"
                defaultValue="report23_selection_no"
                name="POS-filled"
                onChange={handleYesClick}
              >
                <FormControlLabel className="settingsemployeenamelabel" value="report23_selection_yes" control={<Radio />} label={t("report23_selection_yes")} />
                <FormControlLabel className="settingsemployeenamelabel" value="report23_selection_no" control={<Radio />} label={t("report23_selection_no")} />
              </RadioGroup>
            </FormControl>

            {yesbtn == true ? 
              <>
                <FormControl sx={{ marginLeft: 0.2 , marginTop:2}}>
                  <FormLabel className="reportsinfoslabel" id="POS">{t("report23_pos_clean")}</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="POS"
                    defaultValue="report23_pos_clean_yes"
                    name="POS-filled"
                    onChange={posCleanClick}
                  >
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_pos_clean_yes"
                      control={<Radio />}
                      label={t("report23_pos_clean_yes")}
                    />
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_pos_clean_no"
                      control={<Radio />}
                      label={t("report23_pos_clean_no")}
                    />
                  </RadioGroup>
                </FormControl>
                { cleanbtn == false ?
                <><FormLabel className="reportsinfoslabel">{t("report23_clean_items")}</FormLabel><FormGroup>
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_clean_items_shelfs")}
                      name="Shelfs/Protection"
                      onChange={handleItemClean} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_clean_items_countersigns")}
                      name="Counter signs/Displays"
                      onChange={handleItemClean} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_clean_items_spit_protection")}
                      name="Spit protection"
                      onChange={handleItemClean} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_clean_items_material")}
                      name="Material (e.g. spoons, boxes)"
                      onChange={handleItemClean} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_clean_items_sediment")}
                      name="Sediments/Icing"
                      onChange={handleItemClean} />
                  </FormGroup><FormControl sx={{ marginTop: 1 }}>
                      <FormLabel className="reportsinfoslabel" id="POS">{t("report23_clean_items_selection")}</FormLabel>
                      <Typography className="reportsinfofadelabel">
                      {t("report23_clean_items_infotext")}
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="POS"
                        defaultValue="report23_clean_items_selection_yes"
                        name="POS-filled"
                        onChange={posVisitClick}
                      >
                        <FormControlLabel className="settingsemployeenamelabel"
                          value="report23_clean_items_selection_yes"
                          control={<Radio />}
                          label={t("report23_clean_items_selection_yes")} />
                        <FormControlLabel className="settingsemployeenamelabel"
                          value="report23_clean_items_selection_no"
                          control={<Radio />}
                          label={t("report23_clean_items_selection_no")}/>
                      </RadioGroup>
                    </FormControl></>
                  : null}

                <FormControl sx={{ marginLeft: 0.2 }}>
                  <FormLabel sx={{marginTop:1}} className="reportsinfoslabel" id="POS">
                  {t("report23_pos_filled")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="POS"
                    defaultValue="report23_pos_filled_yes"
                    name="POS-filled"
                    onChange={posSufficientClick}
                  >
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_pos_filled_yes"
                      control={<Radio />}
                      label={t("report23_pos_filled_yes")}
                    />
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_pos_filled_no"
                      control={<Radio />}
                      label={t("report23_pos_filled_no")}
                    />
                  </RadioGroup>
                </FormControl>
                {sufficientbtn == false ?
                <><FormLabel sx={{ marginTop: 2 }} className="reportsinfoslabel">{t("report23_change_request")}</FormLabel><FormGroup>
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_change_request_variation")}
                      name="More variation/more color"
                      onChange={handleAssortment} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_change_request_filled")}
                      name="Not filled sufficiently"
                      onChange={handleAssortment} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_change_request_veggie_vegan")}
                      name="More veggie/vegan"
                      onChange={handleAssortment} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={t("report23_change_request_special_boxes")}
                      name="More special boxes"
                      onChange={handleAssortment} />
                    <FormControlLabel className="settingsemployeenamelabel"
                      control={<Checkbox />}
                      label={<TextField
                        id="assortmentOther"
                        label={t("report23_change_request_other")}
                        name="Other"
                        variant="outlined"
                        color="info"
                        sx={{ backgroundColor: "#F5F5F5" }}
                        size="small" />}
                      onChange={handleAssortment} />
                  </FormGroup><FormLabel sx={{ marginTop: 2 }} className="reportsinfoslabel">{t("report23_requested_days")}</FormLabel><FormGroup>
                      <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report23_requested_days_mon")} name="Monday" onChange={handleRequestDays} />
                      <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report23_requested_days_tue")} name="Tuesday" onChange={handleRequestDays} />
                      <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report23_requested_days_wed")} name="Wednesday" onChange={handleRequestDays} />
                      <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report23_requested_days_thu")} name="Thursday" onChange={handleRequestDays} />
                      <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report23_requested_days_fri")} name="Friday" onChange={handleRequestDays} />
                      <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report23_requested_days_sat")} name="Saturday" onChange={handleRequestDays} />
                    </FormGroup></>
                : null }
                <FormControl sx={{ marginLeft: 0.2 }}>
                  <FormLabel sx={{marginTop:1}} className="reportsinfoslabel" id="POS">
                  {t("report23_storage_issues")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="POS"
                    defaultValue="report23_storage_issues_no"
                    name="POS-filled"
                    onChange={storageAreaClick}
                  >
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_storage_issues_yes"
                      control={<Radio />}
                      label={t("report23_storage_issues_yes")}
                    />
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_storage_issues_no"
                      control={<Radio />}
                      label={t("report23_storage_issues_no")}
                    />
                  </RadioGroup>
                </FormControl>
                {storagebtn == true ?
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#4D5766",
                    marginLeft:-.6
                  }}
                  xs={12}
                  sm={6}
                > <Typography className="reportsinfoslabel">
                 {t("report23_storage_issue_description")}</Typography>
                  <TextField id="sanote"
                    sx={{ backgroundColor: "#F5F5F5", marginTop: 1 }}
                  ></TextField>
                </Grid>
                :null }
                <FormControl sx={{ marginLeft: .2 }}>
                  <br></br>
                  <FormLabel className="reportsinfoslabel" id="POS">
                  {t("report23_replace_material")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="POS"
                    defaultValue="report23_replace_material_no"
                    name="POS-filled"
                    onChange={materialReplaceClick}
                  >
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_replace_material_yes"
                      control={<Radio />}
                      label={t("report23_replace_material_yes")}
                    />
                    <FormControlLabel className="settingsemployeenamelabel"
                      value="report23_replace_material_no"
                      control={<Radio />}
                      label={t("report23_replace_material_no")}
                    />
                  </RadioGroup>
                </FormControl>
                { materialbtn == true?
                <><Box marginTop={1} marginLeft={.2}>
                    <FormLabel className="reportsinfoslabel"> {t("report23_select_material")}</FormLabel>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label={t("report23_select_material_wobblers")} name="Wobblers" onChange={handleReplaceMaterials} />
                      <FormControlLabel className="settingsemployeenamelabel"
                        control={<Checkbox />}
                        label={t("report23_select_material_counter_signs")}
                        name="Counter signs/displays (A4)"
                        onChange={handleReplaceMaterials} />
                      <FormControlLabel className="settingsemployeenamelabel"
                        control={<Checkbox />}
                        label={t("report23_select_material_sachet_container")}
                        name="Sachet-Container"
                        onChange={handleReplaceMaterials} />
                      <FormControlLabel className="settingsemployeenamelabel"
                        control={<Checkbox />}
                        label={t("report23_select_material_chopstick_/soysauce_container")}
                        name="Chopstick/soysauce container"
                        onChange={handleReplaceMaterials} />
                      <FormControlLabel className="settingsemployeenamelabel"
                        control={<Checkbox />}
                        label={t("report23_select_material_infoflyer")}
                        name="Infoflyer"
                        onChange={handleReplaceMaterials} />
                      <FormControlLabel className="settingsemployeenamelabel"
                        control={<Checkbox />}
                        label={t("report23_select_material_special_signs")}
                        name="Special signs"
                        onChange={handleReplaceMaterials} />
                      <FormControlLabel className="settingsemployeenamelabel"
                        control={<Checkbox />}
                        label={t("report23_select_material_box_content")}
                        name="Box content (soy sauce, wasabi, etc. - opens follow-up question)"
                        onChange={handleReplaceMaterials} />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={<TextField
                          id="materialOther"
                          label={t("report23_select_material_other")}
                          name="Other"
                          variant="outlined"
                          color="info"
                          sx={{ backgroundColor: "#F5F5F5" }}
                          size="small" />}
                        onChange={handleReplaceMaterials} />
                    </FormGroup>
                  </Box>

                  {replacebtn == true?

                  <Box marginTop={2} marginLeft={.2}>
                      <FormLabel className="reportsinfoslabel">Boxeninhalt</FormLabel>
                      <FormGroup>
                        <FormControlLabel className="settingsemployeenamelabel"
                          control={<Checkbox />}
                          label={t("report23_box_content")}
                          name="Soy sauce"
                          onChange={handleBoxContent} />
                        <FormControlLabel control={<Checkbox />} label={t("report23_box_content_wasabi")} name="Wasabi" onChange={handleBoxContent} />
                        <FormControlLabel className="settingsemployeenamelabel"
                          control={<Checkbox />}
                          label={t("report23_box_content_soy_sauce")}
                          name="Chopsticks"
                          onChange={handleBoxContent} />
                      </FormGroup>
                    </Box>
                    :null}
                    <Box marginTop={2} marginLeft={.4}>
                      <FormControl>
                        <FormLabel className="reportsinfoslabel" id="POS">
                        {t("report23_inform_team")}
                        </FormLabel>
                        <Typography className="reportsinfofadelabel">
                        {t("report23_inform_team_infotext")}
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="POS"
                          defaultValue="report23_inform_team_no"
                          name="POS-filled"
                          onChange={informTeamClick}
                        >
                          <FormControlLabel
                            value="report23_inform_team_yes"
                            control={<Radio />}
                            label={t("report23_inform_team_yes")} />
                          <FormControlLabel
                            value="report23_inform_team_no"
                            control={<Radio />}
                            label={t("report23_inform_team_no")} />
                        </RadioGroup>
                      </FormControl>
                    </Box></>
                : null}
              </>
             : null}
          </Grid>
          <Divider sx={{ marginTop: 2, maxWidth: 750 }} />
          <Box
            m={1}
            display="flex"
            marginTop={2}
            justifyContent="center"
            alignItems="center"
          >
            <Link href="/Reports/Report22" sx={{ color: "#004bA0" }}>
              <Button
                sx={{
                  textTransform: "none",
                  backgroundColor: "#FFFFFF",
                  color: "#004bA0",
                  width: 120,
                  fontWeight: 600,
                  margin: 2,
                }}
                disableElevation
                onClick={backClick}
              >
               {t("report23_back")}
              </Button>
            </Link>
            {/* "/Reports/Report24" */}
            {/* <Link href="#" sx={{ color: "white" }}> */}
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#004bA0",
                  width: 156,
                  fontWeight: 550,
                }}
                disableElevation
                onClick={handleNextClick}
              >
                {t("report23_next")}
              </Button>
            {/* </Link> */}
            <Modal
              open={warning}
              onClose={handleWarningClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ left: "50%" }}
            >
              <Card sx={style}>
                <CardContent>
                  <Typography
                    align="center"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {t("report23_warning_message_infotext1")}
                  </Typography>

                  <img
                    style={{
                      height: 80,
                      width: 80,
                      marginLeft: "30%",
                      marginTop: 20,
                    }}
                    src={W1}
                    alt="pic"
                  />
                  <br></br>
                  <Typography
                    align="center"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                   {t("report23_warning_message_infotext2")}
                  </Typography>
                </CardContent>

                <CardActions
                  style={{ width: 500, marginTop: 20, marginLeft: 0 }}
                >
                  <Link href="/Reports" sx={{ color: "#004bA0" }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#FFFFFF",
                        color: "#004bA0",
                        // width:156,
                        fontWeight: 600,
                        // margin:2
                      }}
                      disableElevation
                    >
                     {t("report23_warning_message_back")}
                    </Button>
                  </Link>
                  <Link href="/Reports/Report23" sx={{ color: "white" }}>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        // width:156,
                        fontWeight: 550,
                      }}
                      disableElevation
                    >
                     {t("report23_warning_message_finish")}
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Modal>
          </Box>
        </Box>
      </Box>
    </>
  );
};
