import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  CardActions,
  Card,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import W1 from "../../Assets/Images/Popups/Vector2.svg";
import Modal from "@mui/material/Modal";
import { CardContent } from "@mui/joy";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";

import Autocomplete from "@mui/material/Autocomplete";
import * as cs from "../../Components/Costcenter/data";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import SubHeader from "../../Layout/Subheader";
import { getCenters } from "../../Services/Costcenter";
import * as cc_id from "../../Services/Costcenter";
import { palette } from '@mui/system';

import Fab from "@mui/material/Fab";

import { useTranslation } from 'react-i18next';

const style = {
  position: "absolute",
  top: "50%",
  // left: '50%',
  // right: '50%',
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Report11_de = () => {



  // const top100Films = [
  //   { label: 'The Shawshank Redemption', year: 1994 },
  //   { label: 'The Godfather', year: 1972 },
  //   { label: 'The Godfather: Part II', year: 1974 },
  //   { label: 'The Dark Knight', year: 2008 },
  //   { label: '12 Angry Men', year: 1957 },
  //   { label: "Schindler's List", year: 1993 },
  //   { label: 'Pulp Fiction', year: 1994 },
  //   {
  //     label: 'The Lord of the Rings: The Return of the King',
  //     year: 2003,
  //   },
  //   { label: 'The Good, the Bad and the Ugly', year: 1966 },
  //   { label: 'Fight Club', year: 1999 },
  //   {
  //     label: 'The Lord of the Rings: The Fellowship of the Ring',
  //     year: 2001,
  //   },
  //   {
  //     label: 'Star Wars: Episode V - The Empire Strikes Back',
  //     year: 1980,
  //   },
  //   { label: 'Forrest Gump', year: 1994 },
  //   { label: 'Inception', year: 2010 },
  //   {
  //     label: 'The Lord of the Rings: The Two Towers',
  //     year: 2002,
  //   },
  //   { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  //   { label: 'Goodfellas', year: 1990 },
  //   { label: 'The Matrix', year: 1999 },
  //   { label: 'Seven Samurai', year: 1954 },
  //   {
  //     label: 'Star Wars: Episode IV - A New Hope',
  //     year: 1977,
  //   },
  //   { label: 'City of God', year: 2002 },
  //   { label: 'Se7en', year: 1995 },
  //   { label: 'The Silence of the Lambs', year: 1991 },
  //   { label: "It's a Wonderful Life", year: 1946 },
  //   { label: 'Life Is Beautiful', year: 1997 },
  //   { label: 'The Usual Suspects', year: 1995 },
  //   { label: 'Léon: The Professional', year: 1994 },
  //   { label: 'Spirited Away', year: 2001 },
  //   { label: 'Saving Private Ryan', year: 1998 },
  //   { label: 'Once Upon a Time in the West', year: 1968 },
  //   { label: 'American History X', year: 1998 },
  //   { label: 'Interstellar', year: 2014 },
  //   { label: 'Casablanca', year: 1942 },
  //   { label: 'City Lights', year: 1931 },
  //   { label: 'Psycho', year: 1960 },
  //   { label: 'The Green Mile', year: 1999 },
  //   { label: 'The Intouchables', year: 2011 },
  //   { label: 'Modern Times', year: 1936 },
  //   { label: 'Raiders of the Lost Ark', year: 1981 },
  //   { label: 'Rear Window', year: 1954 },
  //   { label: 'The Pianist', year: 2002 },
  //   { label: 'The Departed', year: 2006 },
  //   { label: 'Terminator 2: Judgment Day', year: 1991 },
  //   { label: 'Back to the Future', year: 1985 },
  //   { label: 'Whiplash', year: 2014 },
  //   { label: 'Gladiator', year: 2000 },
  //   { label: 'Memento', year: 2000 },
  //   { label: 'The Prestige', year: 2006 },
  //   { label: 'The Lion King', year: 1994 },
  //   { label: 'Apocalypse Now', year: 1979 },
  //   { label: 'Alien', year: 1979 },
  //   { label: 'Sunset Boulevard', year: 1950 },
  //   {
  //     label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
  //     year: 1964,
  //   },
  //   { label: 'The Great Dictator', year: 1940 },
  //   { label: 'Cinema Paradiso', year: 1988 },
  //   { label: 'The Lives of Others', year: 2006 },
  //   { label: 'Grave of the Fireflies', year: 1988 },
  //   { label: 'Paths of Glory', year: 1957 },
  //   { label: 'Django Unchained', year: 2012 },
  //   { label: 'The Shining', year: 1980 },
  //   { label: 'WALL·E', year: 2008 },
  //   { label: 'American Beauty', year: 1999 },
  //   { label: 'The Dark Knight Rises', year: 2012 },
  //   { label: 'Princess Mononoke', year: 1997 },
  //   { label: 'Aliens', year: 1986 },
  //   { label: 'Oldboy', year: 2003 },
  //   { label: 'Once Upon a Time in America', year: 1984 },
  //   { label: 'Witness for the Prosecution', year: 1957 },
  //   { label: 'Das Boot', year: 1981 },
  //   { label: 'Citizen Kane', year: 1941 },
  //   { label: 'North by Northwest', year: 1959 },
  //   { label: 'Vertigo', year: 1958 },
  //   {
  //     label: 'Star Wars: Episode VI - Return of the Jedi',
  //     year: 1983,
  //   },
  //   { label: 'Reservoir Dogs', year: 1992 },
  //   { label: 'Braveheart', year: 1995 },
  //   { label: 'M', year: 1931 },
  //   { label: 'Requiem for a Dream', year: 2000 },
  //   { label: 'Amélie', year: 2001 },
  //   { label: 'A Clockwork Orange', year: 1971 },
  //   { label: 'Like Stars on Earth', year: 2007 },
  //   { label: 'Taxi Driver', year: 1976 },
  //   { label: 'Lawrence of Arabia', year: 1962 },
  //   { label: 'Double Indemnity', year: 1944 },
  //   {
  //     label: 'Eternal Sunshine of the Spotless Mind',
  //     year: 2004,
  //   },
  //   { label: 'Amadeus', year: 1984 },
  //   { label: 'To Kill a Mockingbird', year: 1962 },
  //   { label: 'Toy Story 3', year: 2010 },
  //   { label: 'Logan', year: 2017 },
  //   { label: 'Full Metal Jacket', year: 1987 },
  //   { label: 'Dangal', year: 2016 },
  //   { label: 'The Sting', year: 1973 },
  //   { label: '2001: A Space Odyssey', year: 1968 },
  //   { label: "Singin' in the Rain", year: 1952 },
  //   { label: 'Toy Story', year: 1995 },
  //   { label: 'Bicycle Thieves', year: 1948 },
  //   { label: 'The Kid', year: 1921 },
  //   { label: 'Inglourious Basterds', year: 2009 },
  //   { label: 'Snatch', year: 2000 },
  //   { label: '3 Idiots', year: 2009 },
  //   { label: 'Monty Python and the Holy Grail', year: 1975 },
  // ];

const {t} = useTranslation()

  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
  const navigate = useNavigate();
 
  const [yesbtn, setYesbtn] = React.useState(true);

  const [values, setValues] = React.useState(null);

  const [logistikbtn, setLogistikbtn] = React.useState(false);

  const [expansionbtn, setExpansionbtn] = React.useState(false);

  const [marketingbtn, setMarketingbtn] = React.useState(false);

  const [qsbtn, setQsbtn] = React.useState(false);

  const [opsbtn, setOpsbtn] = React.useState(false);

  const [hrbtn, setHrbtn] = React.useState(false);

  const [costcenter, setCostcenter] = React.useState(null);
  React.useEffect(() => {
    cc_id.getCenters(setCostcenter);
  }, []);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [warning, setWarning] = React.useState(false);
  const handleWarningopen = () => {
    setWarning(true);
  };
  const handleWarningClose = () => setWarning(false);

  const handleYesClick = (event) => {
    const yesvalue = event.target.value;
    if (yesvalue === "Yes") {
      setYesbtn(true);
      const name = "isQuickReport";
      const isQuickReport = true;
      setValues({
        ...values,
        [name]: isQuickReport,
      });
    } else {
      setYesbtn(false);
      const name = "isQuickReport";
      const isQuickReport = false;
      setValues({
        ...values,
        [name]: isQuickReport,
      });
    }
  };

  const handleInput = (event) => {
    const cc_value = event.target.value;
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    console.log(cc_value);
  };
  // console.log(123,values)
  // function handleReport11Click(isFlag) {
  //   console.log("flag",isFlag)
  //   alert("Hi ")
  //   if (isFlag === true) {
  //     navigate("/Reports/Report22", { state:{"dataset":values} });
  //   } else {
  //     navigate("/Reports/Report12", { state: {"dataset":values} });
  //   }
    
  // };

  const default_option = [{"label":"Loading...","id":"-1"}]
  return (
    <>
      <SubHeader
        backbutton={true}
        btnUrl="/Reports"
        title="In-App Kommunikation"
        warning_flag={true}
        bf={handleWarningopen}
      />
      <Box backgroundColor="white">
        <Box sx={{ marginLeft: 3, marginBottom: 3, marginRight: 3 }}>
          {" "}
          {/* <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: 2,
          marginLeft: 1,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          color: "#4D5766",
          fontWeight: 400,
        }}
        xs={12}
          sm={6}
      >
      <Grid item
          sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
          xs={6}
          sm={6}>
      <DesktopDatePicker
          label="Date"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
         </Grid>
         <Grid item
          sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
          xs={6}
          sm={6}>
        <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
         </Grid>
         </Grid>
        </LocalizationProvider>
      </Grid> */}
          <Grid
            container
            spacing={0.1}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {/* <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#000000",
              }}
              xs={6}
              sm={3}
            >
              <br></br>
              <Typography
                className="contactstaticlabel"
                sx={{ marginBottom: 1,
                  }}
              >
                {t("data_cost_centre")}
              </Typography>
            </Grid> */}

            {/* <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "black" ,
              }}
              xs={6}
              sm={3}
            >
              <Autocomplete
                disablePortal
                name="costCenter"
                id="costCenter1"
                options={
                  costcenter ? costcenter.map((option) => option.label) : default_option.map((option)=> option.label)
                  // top100Films
                }
                sx={{ width: "100%", backgroundColor: "#f5f6f8", color:"black"}}
                renderInput={(params) => <TextField {...params} />}
                //onChange = {handleInput}
                onChange={(event, newValue) => {
                  const name = "costCenter";
                  let costCenter = null;
                  costcenter.map((cc) => {
                    if (cc.label === newValue) {
                      costCenter = cc.id;
                    }
                  });
                  setValues({
                    ...values,
                    [name]: costCenter,
                  });
                  console.log(values);
                }}
              />
            </Grid> */}

            <Grid
              container
              spacing={1}
              sx={{
                color: "#4D5766",
                display: "flex",
                flexDirection: "column",
                marginTop: 2,
                marginLeft: 0,
              }}
            >
              <FormControl sx={{ marginLeft: 0.2 }}>
                <FormLabel id="posType">
                  {" "}
                  <Typography className="contactstaticlabel">
                    POS-Typ:
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="posType"
                  name="posType"
                  id="posType"
                  onChange={(event, newValue) => {
                    //alert(newValue); 
                    const name = "posType";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
                  }}
                >
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="report11_shop"
                    control={<Radio />}
                    label={t("report11_shop")}
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="report11_chiller"
                    control={<Radio />}
                    label={t("report11_chiller")}
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="report11_mochi-chiller"
                    control={<Radio />} 
                    label={t("report11_mochi-chiller")}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ marginLeft: 0.2, marginTop:1 }}>
                <FormLabel id="informTeam">
                  {" "}
                  <Typography className="contactstaticlabel">
                    Select team:
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="informTeam"
                  name="informTeam"
                  id="informTeam"
                  onChange={(event, newValue) => {
                    //alert(newValue); 
                    if(newValue === "Logistik"){
                      setLogistikbtn(true);
                      setExpansionbtn(false);
                      setMarketingbtn(false);
                      setQsbtn(false);
                      setOpsbtn(false);
                      setHrbtn(false);
                    const name = "informTeam";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
                  }
                else if(newValue === "Expansion"){
                  setLogistikbtn(false);
                      setExpansionbtn(true);
                      setMarketingbtn(false);
                      setQsbtn(false);
                      setOpsbtn(false);
                      setHrbtn(false);
                  const name = "informTeam";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
                }
              else if(newValue === "Marketing"){
                setLogistikbtn(false);
                      setExpansionbtn(false);
                      setMarketingbtn(true);
                      setQsbtn(false);
                      setOpsbtn(false);
                      setHrbtn(false);
                const name = "informTeam";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
              }
            else if(newValue ==="QS"){
              setLogistikbtn(false);
                      setExpansionbtn(false);
                      setMarketingbtn(false);
                      setQsbtn(true);
                      setOpsbtn(false);
                      setHrbtn(false);
              const name = "informTeam";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
            }
            else if(newValue ==="OPS regional office"){
              setLogistikbtn(false);
                      setExpansionbtn(false);
                      setMarketingbtn(false);
                      setQsbtn(false);
                      setOpsbtn(true);
                      setHrbtn(false);
              const name = "informTeam";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
            }
            else if(newValue ==="Human Resources"){
              setLogistikbtn(false);
                      setExpansionbtn(false);
                      setMarketingbtn(false);
                      setQsbtn(false);
                      setOpsbtn(false);
                      setHrbtn(true);
              const name = "informTeam";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
            }
          }
                }
                >
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="Logistik"
                    control={<Radio />}
                    label="Logistik"
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="Expansion"
                    control={<Radio />}
                    label="Expansion"
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="Marketing"
                    control={<Radio />} 
                    label="Marketing"
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="QS"
                    control={<Radio />} 
                    label="QS"
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="OPS regional office"
                    control={<Radio />} 
                    label="OPS regional office"
                  />
                  <FormControlLabel
                    className="settingsemployeenamelabel"
                    value="Human Resources"
                    control={<Radio />} 
                    label="Human Resources"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {/* <Divider sx={{ marginTop: 2, maxWidth: 750 }} /> */}
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", maxWidth: 750 }}
          >
            <Table aria-label="simple-table"></Table>
          </TableContainer>
          {/* <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: 2,
              marginLeft: 0,
            }}
          >
            <FormControl sx={{ marginLeft: 0.1 }}>
              <FormLabel id="POS">
                {" "}
                <Typography className="contactstaticlabel">
                In-App Kommunikation Starten
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="POS"
                defaultValue="Yes"
                name="POS-filled"
                onChange={handleYesClick}
              >
                <FormControlLabel
                  className="settingsemployeenamelabel"
                  value="Yes"
                  control={<Radio />}
                  label={t("report11_quick_note_yes")}
                />
                <FormControlLabel
                  className="settingsemployeenamelabel"
                  value="No"
                  control={<Radio />}
                  label={t("report11_quick_note_no")}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Box>
      </Box>

      <Box sx={{ marginRight: 1 }}>
        <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(0),
            borderRadius: 0,
            height: 50,
            width: "100%",
            backgroundColor: "white",
          }}
          variant="extended"
          size="small"
        >
          {
            //checking yesbtn is ture or not
            logistikbtn === true ? (
              //<Link href="/Reports/Report22" sx={{ color: "white" }}>
              <RouterLink to="/Reports/report12_logistics" state={{dataset:values}} >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#004bA0",
                  width: 360,
                  fontWeight: 550,
                }}
                disableElevation
                //onClick={handleReport11Click(false)}
              >
                Start In-App communication with team
              </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(true)}
              // >
              //   Next
              // </Button>
            ) : 
            expansionbtn === true ?(
              //</Link>
              <RouterLink to="/Reports/report12_expansion" state={{dataset:values}} >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#004bA0",
                    width: 360,
                    fontWeight: 550,
                  }}
                  disableElevation
                  // onClick={handleReport11Click(false)}

                >
                  Start In-App communication with team
                </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(false)}
              // >
              //   Next
              // </Button>
            ):
            marketingbtn === true ?(
              //</Link>
              <RouterLink to="/Reports/report12_marketing" state={{dataset:values}} >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#004bA0",
                    width: 360,
                    fontWeight: 550,
                  }}
                  disableElevation
                  // onClick={handleReport11Click(false)}

                >
                  Start In-App communication with team
                </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(false)}
              // >
              //   Next
              // </Button>
            ):
            qsbtn === true ?(
              //</Link>
              <RouterLink to="/Reports/report12_qs" state={{dataset:values}} >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#004bA0",
                    width: 360,
                    fontWeight: 550,
                  }}
                  disableElevation
                  // onClick={handleReport11Click(false)}

                >
                  Start In-App communication with team
                </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(false)}
              // >
              //   Next
              // </Button>
            ):
            opsbtn === true ?
            (
              //</Link>
              <RouterLink to="/Reports/report12_ops" state={{dataset:values}} >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#004bA0",
                    width: 360,
                    fontWeight: 550,
                  }}
                  disableElevation
                  // onClick={handleReport11Click(false)}

                >
                  Start In-App communication with team
                </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(false)}
              // >
              //   Next
              // </Button>
            ) :
            hrbtn === true ? 
            (
              //</Link>
              <RouterLink to="/Reports/report12_hr" state={{dataset:values}} >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#004bA0",
                    width: 360,
                    fontWeight: 550,
                  }}
                  disableElevation
                  // onClick={handleReport11Click(false)}

                >
                  Start In-App communication with team
                </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(false)}
              // >
              //   Next
              // </Button>
            ):
            (
              //</Link>
              <RouterLink to="/Reports/Report22" state={{dataset:values}} >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#004bA0",
                    width: 360,
                    fontWeight: 550,
                  }}
                  disableElevation
                  // onClick={handleReport11Click(false)}

                >
                  Start In-App communication with team
                </Button>
              </RouterLink>
              // <Button
              //   variant="contained"
              //   sx={{
              //     textTransform: "none",
              //     backgroundColor: "#004bA0",
              //     width: 360,
              //     fontWeight: 550,
              //   }}
              //   disableElevation
              //   onClick={handleReport11Click(false)}
              // >
              //   Next
              // </Button>
            )

          }
        </Fab>

        <Modal
          open={warning}
          onClose={handleWarningClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ left: "50%" }}
        >
          <Card sx={style}>
            <CardContent>
              <Typography
                align="center"
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                {t("ticket_form_warning")}
              </Typography>

              <img
                style={{
                  height: 80,
                  width: 80,
                  marginLeft: "32%",
                  marginTop: 20,
                }}
                src={W1}
                alt="pic"
              />
              <br></br>
              <Typography
                align="center"
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                {t("ticket_form_warning_message")}
              </Typography>
            </CardContent>

            <CardActions style={{ width: 500, marginTop: 20, marginLeft: 0 }}>
              <Link href="/Reports" sx={{ color: "#004bA0" }}>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#FFFFFF",
                    color: "#004bA0",
                    display: "flex",
                    // width:156,
                    fontWeight: 600,
                    
                    
                  }}
                  disableElevation
                >
                  {t("report12_warning_message_back")}
                </Button>
              </Link>
              <Link href="/Reports/Report11" sx={{ color: "white" }}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    display: "flex",
                    // width:156,
                    fontWeight: 550,
                  }}
                  disableElevation
                >
                   {t("report12_warning_message_finish")}
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Modal>
      </Box>
    </>
  );
};
