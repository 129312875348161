import React from "react";
import {
  Button,
  Grid,
  Divider,
  Modal,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,

} from "@mui/material";
import { Box } from "@mui/system";
import {
  Link
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddLinkIcon from '@mui/icons-material/AddLink';
import S1 from "../../Assets/Images/Popups/Vector1.svg";
import W1 from "../../Assets/Images/Popups/Vector2.svg";
import SubHeader from "../../Layout/Subheader";
import Fab from "@mui/material/Fab";
import { useLocation, useNavigate } from "react-router-dom";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import Camera from "../../Components/Cameraticket";
import * as rep from '../../Services/Reports'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Webcam from "react-webcam";
import { useRef } from "react"
import {useEffect, useState} from 'react'
import { useTranslation } from "react-i18next";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  width: 500,
  
  facingmode: FACING_MODE_ENVIRONMENT,
};



export const Report25 = () => {
  const {t} = useTranslation()

  const [url, setUrl] = useState(null);
  const [camdisable,setCamdisable] = useState(true);

  const [fileid,setFileid] = React.useState(null);

  const [camopen, setCamopen] = React.useState(false);

  const handleClickOpen = () => {
    setCamopen(true);
  };

  const handleClickclose = () => {
    rep.uploadFile(url,setFileid);
    console.log(fileid,url)
    setCamopen(false);
    setUrl(null);
  setCamdisable(true)
  };



  const location = useLocation()
  // console.log("location", location)

  //uncomment for dev
  const {report24_set} = location.state
  const navigate = useNavigate()
console.log("inside22",report24_set)
    const [open, setOpen] = React.useState(false);


    function finalSubmit(){
     let additionalInformation = document.getElementById('shortrepnote').value || null
     let finalset = Object.assign(report24_set, {"additionalInformation":additionalInformation})
    console.log(fileid,"testfileideee")
    var fileset = []

    if (fileid !== null && filevidid !==null) {
    fileset.push(filevidid)
     fileset.push(fileid) 
     console.log(fileset)
    finalset["files"] = fileset
    } else if
    (fileid !== null && filevidid ===null) {
     fileset.push(fileid) 
     console.log(fileset)
    finalset["files"] = fileset
    }else if
    (fileid === null && filevidid !==null) {
     fileset.push(filevidid) 
     console.log(fileset)
    finalset["files"] = fileset
    }
      rep.reportSubmission(finalset)
    }



    const handleOpen = () => {
      finalSubmit()
      setOpen(true);
      setTimeout(() => {
        setOpen(false)
        navigate('/Reports') 
      }, 3000, )
      
  }
    const handleClose = () => setOpen(false);

    const [warning, setWarning] = React.useState(false);
    const handleWarningopen = () => {
        setWarning(true);
            
    }
    const handleWarningClose = () => setWarning(false);

    const [cameraclick, setCameraclick] = React.useState(false);
    const camOpen = () => {
      setCameraclick(true);
      
      
  }
//for cam close
  const camClose = () =>  {
    setCamopen(false);
  };

  //for vid close
const vidClose = () =>  {
  setVideopen(false);
};

//for video
const [videopen, setVideopen] = React.useState(false);
const [filevidid,setFilevidid] =React.useState(false);
const handleClickVidOpen = () => {
  setVideopen(true);

};


///record video
const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [timer, setTimer] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleStartRecording = () => {
    setRecording(true);
    const stream = videoRef.current.video.srcObject;
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    setMediaRecorder(mediaRecorder);
    const chunks = [];
    mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: "video/webm" });
      const Vurl = URL.createObjectURL(videoBlob);
      setVideoUrl(videoBlob);
      console.log (Vurl, "videoMtest")
    };
    mediaRecorder.start();
  };

  const handleStopRecording = () => {
    setRecording(false);
    mediaRecorder.stop();
  };
/////////////////////////////////////////////////////////////////////////////////
  const handleDownload = () => {
    // const a = document.createElement("a");
    // a.href = videoUrl;

    console.log("what is video", videoUrl)
    console.log(videoUrl instanceof Blob)
    // a.download = "recorded-video.webm";
    // a.click();
    rep.uploadVideo(videoUrl,setFilevidid)
    setVideoUrl(null);
    setVideopen(false);
  };

  useEffect(() => {
    let intervalId;
    if (recording) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
      setTimer(0);
    };
  }, [recording]);



/////////////////////////////////////////  
 const handleCamClose = () => setCameraclick(false);

//uncomment for dev
 const backClick = () => {
  navigate('/Reports/Report24',{state:{report23_set : report24_set}})
}


const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

  const webcamRef = useRef(null);

  


  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
    setCamdisable(false);
  }, [webcamRef]);

    

  const onUserMedia = (e) => {
    console.log(e);
  };


  const onUserVideo = (e) => {
    console.log(e);
  };


  const handleClick = React.useCallback(() => {
    setUrl(null);
    setCamdisable(true);
    setFacingMode((prevState) =>
      prevState === FACING_MODE_ENVIRONMENT
        ? FACING_MODE_USER
        : FACING_MODE_ENVIRONMENT
    );
  }, []);


  

  return (
    <>
    <SubHeader backbutton={true} btnUrl="/Reports/Report24" title={t("report25_new_report")} warning_flag={true} bf={handleWarningopen}/>
    <Box backgroundColor="white" >
    <Box sx={{marginLeft:3,marginRight:3}}>
      {" "}
      
      <Grid item sx={{ display: "flex", flexDirection: "column"}} className="reportsinfoslabel" xs={12} sm={6}>
      <br></br>
      {t("report25_additional_info")}
                <TextField id="shortrepnote"
                sx={{ backgroundColor: "#F5F5F5", marginTop: 1 }}>

                </TextField>
            </Grid>
     <br></br>

            <Grid item className="reportsinfoslabel"  xs={12} sm={6}>
            {t("report25_additional_pictures")}
                
                    
                   
                    


  <Button color="primary" variant="outlined" component="span" sx={{
                    textTransform: "none",
                    width: '100%',
                    fontWeight: 550,
                    marginTop:1
                    }}  
                    onClick={handleClickOpen} >
                    <AddAPhotoOutlinedIcon sx={{ color: "#003DA6" }} />
                    {t("report25_additional_pictures")}
                    </Button>
                    
                    {
                  fileid ? "pic uploaded" : null
                }


                    
            </Grid>
            
            <Dialog
        open={camopen}
        onClose={handleCamClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

<div className="button-container">
        <Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>

        <Button className="right-button" onClick={camClose}><CloseIcon /></Button>      

</div>
<Box className="app-container">
        
        {camdisable?
          
        
          <Webcam
            ref={webcamRef}
            // audio={true}
            screenshotFormat="image/png"
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserMedia}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


<Box sx={{marginLeft:"20%"}}>
       
       <Grid container spacing={3.5} >
       <Grid item xs={3}>
         {/* <Link href="/Tickets/form2"> */}
       <Button onClick={handleClickclose}><CloudUploadIcon fontSize="large" sx={{color:'#239342'}}/></Button>
       {/* </Link> */}
       </Grid>
       <Grid item xs={3}>
       <Button  onClick={capturePhoto}><LinkedCameraIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
       </Grid>
       <Grid item xs={3}>
       <Button  onClick={() => {
         setUrl(null);
         setCamdisable(true)
         }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#E53D30'}}/></Button>
       </Grid>
       
       {/* <Grid item xs={3}>
       <Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#1E1E1E'}}/></Button>
       </Grid> */}
       </Grid>
       
             </Box>

      </Dialog>
            
{/* video */}

      <br></br>


      <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#4D5766",
                
              }}
              xs={12}
              sm={6}
            ><Box sx={{marginBottom:1}}><Typography className="contactstaticlabel">
              Please add video if needed</Typography></Box>
             

<Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    fontWeight: 550,
                  }}
                  onClick={handleClickVidOpen} 
                >
                  <VideoCallIcon sx={{ color: "#003DA6" }} />
                  Record video
                </Button>

                {
                  filevidid ? "Video uploaded" : null
                }
              
            </Grid>


            <Dialog
        open={videopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <div className="button-container">
        <Button sx={{backgroundColor:"#4CAF50",color:"white"}} onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
        
      
        <Button className="right-button" onClick={vidClose}><CloseIcon /></Button>      
        </div>
        
<Box className="app-container" >

        {camdisable?
          
        
          <Webcam
            ref={videoRef}
            audio={false}
            
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserVideo}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {/* {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )} */}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


      <Box sx={{marginLeft:"40%"}}>
      


     {/* <Grid item xs={3}>
      <Button  onClick={() => {
        setUrl(null);
         setCamdisable(true)
         }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
      </Grid> */}


      
      {videoUrl && (
        <Button onClick={handleDownload}><CloudUploadIcon fontSize="large" sx={{color:'#239342'}}/></Button>
      )}
      
      
      {recording ? (
         <div className="recording-container">
         <div className="recording-symbol" />
         <div className="timer">{formatTime(timer)}</div>
        <Button onClick={handleStopRecording}><StopCircleIcon fontSize="large" sx={{color:'#1E1E1E'}}/> </Button>
      </div>
      ) : (
        <Button onClick={handleStartRecording}><RadioButtonCheckedIcon fontSize="large" sx={{color:'#E53D30',marginLeft:"20%"}}/></Button>
      )}

{/* <Grid item xs={3}>
<Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid> */}

      </Box>
      </Dialog>

            </Box>




            
            {/* <Box sx={{padding: 2}}>
            <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766" , marginLeft: 1 }}  xs={12} sm={6}>
            <Box><Typography className="contactstaticlabel">
            Please add attachments if necessary</Typography></Box>
                
                    
                   
                    <label htmlFor="upload-photo"  >
                    <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    
                    />




  <Button color="primary" variant="outlined"
 
  sx={{
                    textTransform: "none",
                    width: '100%',
                    fontWeight: 550,
                    marginTop: 1 ,
                    }}
                    onClick={camOpen}  >
                    < AddAPhotoOutlinedIcon  />
                    Take a picture
                    </Button>
                   
                    </label>
<Modal
open={cameraclick}
onClose={camClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"



>
<div>
  <Camera/>
</div>



           </Modal>
            </Grid>
            </Box> */}
            <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
   
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
   

   
    
    
   
            </Box>
            <Box sx={{marginRight:1}}>
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(0),
          
          borderRadius:0,
          height:50,
          width: '100%',
          backgroundColor:"white"
          
        }}
        variant='extended'
        size="small"
        
      >
                <Link href="/Reports/Report24" sx={{  color: "#004bA0" }}> <Button 
                 
                 sx={{textTransform:"none", 
                 backgroundColor:"#FFFFFF", 
                 
                 width:120,
                 fontWeight:600,
                 margin:2}} 
                 disableElevation
                //  onClick={backClick}
         >
                    {t("report25_back")}
                </Button></Link>
               <Button 
                    variant="contained" 
                    sx={{textTransform:"none", 
                        backgroundColor:"#004bA0",
                        width:156,
                        fontWeight:550}} 
                    disableElevation
                    onClick={handleOpen}
                 >
                    <DoneIcon/>
                    {t("report25_submit")}
                </Button>
                </Fab>



                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
            
          {t("report25_success_message_infotext1")}
          
          </Typography>
          <img
              style={{ height: 80, width: 80,marginLeft:"38%", marginTop: 20 }}
              src={S1}
              alt="pic"
              />
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
          {t("report25_success_message_infotext2")}
            </Typography>
        </Box>
      </Modal>



      <Modal
        open={warning}
        onClose={handleWarningClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
       <Card sx={style}>
        <CardContent>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
            
          {t("report25_warning_message_infotext1")}
          
          </Typography>
          
          <img
              style={{ height: 80, width: 80,marginLeft:"36%", marginTop: 20 }}
              src={W1}
              alt="pic"
              
              />
              <br></br>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
          {t("report25_warning_message_infotext2")}
            </Typography>
            </CardContent>

              <CardActions style={{ width: 80, marginTop: 0 }}>

            <Link href="/Reports" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    width:156,
                    fontWeight:600,
                    // margin:2
                }} 
                    disableElevation
                >
                    {t("report25_warning_message_back")}
                </Button></Link>
                
                <Link href="/Reports/Report25" sx={{  color: "white" }}><Button 
                    variant="outlined" 
                    sx={{textTransform:"none", 
                        width:156,
                        fontWeight:550}} 
                    disableElevation
                 >
                    {t("report25_warning_message_finish")}
                </Button></Link>
            </CardActions>

        </Card>
       
      </Modal>
        </Box>
    
      
      
      
    </>
  );
};
