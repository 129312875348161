import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Card,
  Modal,
  CardActions,
  CardContent,

} from "@mui/material";
import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,Link
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import W1 from "../../Assets/Images/Popups/Vector2.svg";


import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import SubHeader from "../../Layout/Subheader";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  // left: '50%',
  // right: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initialValues={
  "addTechnicalInfo": false,
    "sensorsExist": false,
        "sensorsFunctioning": false,
        "loggerExists": false,
        "loggerFunctioning": false,
        "issuesFixed":false,
        "createJiraTicket": false  

}
export const Report22 = () => {
  const {t} = useTranslation()
    // const location = useLocation()
    // const {dataset} = location.state
    const location = useLocation()
  const {dataset} = location.state
  const navigate = useNavigate()
console.log("inside22",dataset)

    const [temp,setTemp] = React.useState([])
    // const [temp,setTemp] = Rect.useState(null)
    const [food, setFood] = React.useState([])
    const [defects, setDefects] = React.useState([])


    const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
    
    
    
    const [yesbtn,setYesbtn] = React.useState(false)
    

    const [sensbtn,setSensbtn] = React.useState(true)

    const [loggbtn, setLoggbtn] = React.useState(true)

    const [values, setValues] = React.useState(initialValues);
    const handleChange = (newValue) => {
      setValues(newValue);
    };


    const handleTempChange = (event) =>{
      // alert(event.target.checked+" == "+ event.target.name)
      const name = 'reason'
     /* setTemp({
      ...temp,
      [event.target.name] : event.target.name
      })*/
      // console.log(temp)
      if(!temp.includes(event.target.name)){
      
      setTemp([...temp, event.target.name])}
    }
    

    const handleFoodChange = (event) =>{
      //alert(event.target.checked+" == "+ event.target.name)
      const name = 'reason'
      if(!food.includes(event.target.name)){

      setFood([...food, event.target.name])
      }
      console.log(food)
    }

    const handleDefectsChange = (event) =>{
      //alert(event.target.checked+" == "+ event.target.name)
      const name = 'reason'
      // if(eve)
      if(!defects.includes(event.target.name)){
      setDefects([...defects, event.target.name])
      }
      console.log(defects)
    }

    const handleInput = (event) => {
      const cc_value = event.target.value
      const { name, value } = event.target
          setValues({
              ...values,
              [name]: value
          })
      console.log(cc_value)
    }
    

    const textField = {

    }

    const [warning, setWarning] = React.useState(false);
    const handleWarningopen = () => {
        setWarning(true);
            
    }
    const handleWarningClose = () => setWarning(false);

    const handleYesClick = (event) =>{
      const yesvalue = event.target.value
      if(yesvalue === 'report22_sensors_exist_yes'){
        setYesbtn(true)
        const name = "addTechnicalInfo"
      const addTechnicalInfo = true
      setValues({
        ...values,
        [name]: addTechnicalInfo
    })
      }
      else{
      setYesbtn(false)
      const name = "addTechnicalInfo"
      const addTechnicalInfo = false
      setValues({
        ...values,
        [name]: addTechnicalInfo
    })
      }
    }

    

    const handleSensorClick1 = (event) => {
      const yesvalue = event.target.value;
      if (yesvalue === 'report22_sensors_function_yes') {
        setSensbtn(true);
        const name = "sensorsExist"
        const sensorsExist = false
        setValues({
          ...values,
          [name]: sensorsExist
      })
      } else {
        setSensbtn(false);
        const name = "sensorsExist"
        const sensorsExist = true
        setValues({
          ...values,
          [name]: sensorsExist
      })
      }
    };

    const handleSensorClick2 = (event) => {
      const yesvalue = event.target.value;
      if (yesvalue === 'report22_sensors_function_yes') {
        //setSensbtn(true);
        const name = "sensorsFunctioning"
        const sensorsFunctioning = false
        setValues({
          ...values,
          [name]: sensorsFunctioning
      })
      } else {
        //setSensbtn(false);
        const name = "sensorsFunctioning"
        const sensorsFunctioning = true
        setValues({
          ...values,
          [name]: sensorsFunctioning
      })
      }
    };

    const handleLoggerClick1 = (event) => {
      const yesvalue = event.target.value;
      if (yesvalue === 'report22_loggers_exist_yes') {
        setLoggbtn(true);
        const name = "loggerExists"
        const loggerExists = true
        setValues({
          ...values,
          [name]: loggerExists
      })
      } else {
        setLoggbtn(false);
        const name = "loggerExists"
        const loggerExists = false
        setValues({
          ...values,
          [name]: loggerExists
      })
      }
    };

    const handleLoggerClick2 = (event) => {
      const yesvalue = event.target.value;
      if (yesvalue === 'report22_loggers_function_yes') {
       // setYesbtn(true);
        const name = "loggerFunctioning"
        const loggerFunctioning = true
        setValues({
          ...values,
          [name]: loggerFunctioning
      })
      } else {
       // setYesbtn(false);
        const name = "loggerFunctioning"
        const loggerFunctioning = false
        setValues({
          ...values,
          [name]: loggerFunctioning
      })
      }
    };

    const handleIssueClick = (event) => {
      const yesvalue = event.target.value;
      if (yesvalue === 'report22_able_to_remove_yes') {
       // setYesbtn(true);
        const name = "issuesFixed"
        const issuesFixed = true
        setValues({
          ...values,
          [name]: issuesFixed
      })
      } else {
       // setYesbtn(false);
        const name = "issuesFixed"
        const issuesFixed = false
        setValues({
          ...values,
          [name]: issuesFixed
      })
      }
    };

    const handleJiraClick = (event) => {
      const yesvalue = event.target.value;
      if (yesvalue === 'report22_create_jira_yes') {
       // setYesbtn(true);
        const name = "createJiraTicket"
        const createJiraTicket = true
        setValues({
          ...values,
          [name]: createJiraTicket
      })
      } else {
        //setYesbtn(false);
        const name = "createJiraTicket"
        const createJiraTicket = false
        setValues({
          ...values,
          [name]: createJiraTicket
      })
      }
    };

    const handleNextClick = () => {
      console.log(122)
      console.log(values)
      console.log(temp)
      console.log(food)
      console.log(defects)
      console.log(123)

      let report22_set = {} 
      report22_set = Object.assign(report22_set,dataset)
      if(values.addTechnicalInfo === true){
         
        report22_set = Object.assign(report22_set,{"addTechnicalInfo":true})
        let temp_data = []
        temp.map((item)=>{
          if(item === "Other"){
           const text_val = document.getElementById("tempOther")
           temp_data.push({"reason":text_val})
          }else{
          temp_data.push({"reason":item})
          }
        })
        let food_data = []
        food.map((item)=>{
          if(item === "Other") {
            const text_val = document.getElementById("foodOther")
           temp_data.push({"reason":text_val})
          }
          else {
          food_data.push({"reason":item})
        }})
        let defect_data = []
        defects.map((item)=>{
          if(item === "Other") {
            const text_val = document.getElementById("defectsOther")
           temp_data.push({"reason":text_val})
          }
          else{
          defect_data.push({"reason":item})
        }})
        const technical = {"technical":{
          "sensorsExist": values.sensorsExist,
        "sensorsFunctioning": values.sensorsFunctioning,
        "loggerExists": values.loggerExists,
        "loggerFunctioning": values.loggerFunctioning,
        "technicalTemperatures":temp_data,
        "foodQualities":food_data,
        "defects":defect_data,
        "issuesFixed": values.issuesFixed,
        "createJiraTicket": values.createJiraTicket
        }}
        report22_set = Object.assign(report22_set,technical)
      navigate('/Reports/Report23',{state:{report22_set}})
      }
      else{
        navigate('/Reports/Report23',{state:{report22_set}})
      }
      console.log("report",report22_set)
    }

    const [temperature, setTemperature] = React.useState(null)
    // const handleTemperature = (event) => {
    //   setTemperature ({
    //     ...temperature,
    //     [event.target.name]:event.target.checked,
    //   })
    // }

    // const handleFood = (event) => {
    //   setFood ({
    //     ...food,
    //     [event.target.name]:event.target.checked,
    //   })
    // }

    // const handleDefects = (event) => {
    //   setDefects ({
    //     ...defects,
    //     [event.target.name]:event.target.checked,
    //   })
    // }

    console.log(1234,sensbtn)

  return (
    <>
    <SubHeader backbutton={true} btnUrl="/Reports/Report11" title={t("report22_new_report")} warning_flag={true} bf={handleWarningopen}/>
    <Box backgroundColor="white" >
    <Box sx={{marginLeft:3,marginRight:3}}>
      {" "}
      
      
      <Box marginLeft={1}>
        <Typography 
          className="reportoverviewheadingtlabel"
        >
          <br></br>
          {t("report22_technical_defects")}
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 1,
          marginLeft: 1,
        }}
      >
        <FormControl sx={{ marginLeft: 0.2 }}>
          <FormLabel className="reportsinfoslabel" id="addTechnicalInfo">{t("report22_selection")} </FormLabel>
          <Typography className="reportsinfofadelabel" >{t("report22_selection_infotext")}</Typography>
          <RadioGroup
            row
            aria-labelledby="POS"
            defaultValue="report22_sensors_exist_no"
            name="addTechnicalInfo"
            onChange={handleYesClick}

          >
            <FormControlLabel className="settingsemployeenamelabel" value="report22_sensors_exist_yes" control={<Radio />} label={t("report22_sensors_exist_yes")} />
        <FormControlLabel className="settingsemployeenamelabel" value="report22_sensors_exist_no" control={<Radio />} label={t("report22_sensors_exist_no")} />
            
          </RadioGroup>
        </FormControl>
      

      {yesbtn == true ? 
        // dataset.posType === "Chiller" ? 
        <><FormControl sx={{ marginLeft: 0.2 , marginTop:2}}>

              <FormLabel className="reportsinfoslabel" id="sensorsExist">{t("report22_sensors_exist")}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="POS"
                defaultValue="report22_sensors_function_yes"
                name="sensorsExist"
                onChange={handleSensorClick1}
              >
                <FormControlLabel className="settingsemployeenamelabel" value="report22_sensors_function_yes" control={<Radio />} label={t("report22_sensors_function_yes")} />
                <FormControlLabel className="settingsemployeenamelabel" value="report22_sensors_function_no" control={<Radio />} label={t("report22_sensors_function_no")} />
              </RadioGroup>
            </FormControl>
            
            
          
            { 
            sensbtn === true ?
            <FormControl sx={{ marginLeft: 0.2 }}>
              <FormLabel className="reportsinfoslabel" id="sensorsFunctioning">{t("report22_sensors_function")}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="POS"
                  defaultValue="report22_sensors_function_yes"
                  name="sensorsFunctioning"
                  onChange={handleSensorClick2}
                >
                  <FormControlLabel className="settingsemployeenamelabel" value="report22_sensors_function_yes" control={<Radio />} label={t("report22_sensors_function_yes")} />
                  <FormControlLabel className="settingsemployeenamelabel" value="report22_sensors_function_no" control={<Radio />} label={t("report22_sensors_function_no")} />
                </RadioGroup>
              </FormControl>
              : null }
              <FormControl sx={{ marginLeft: 0.2 }}>
                <FormLabel className="reportsinfoslabel" id="loggerExists">{t("report22_loggers_exist")}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="POS"
                  defaultValue="report22_loggers_exist_yes"
                  name="loggerExists"
                  onChange={handleLoggerClick1}
                >
                  
                  <FormControlLabel className="settingsemployeenamelabel" value="report22_loggers_exist_yes" control={<Radio />} label={t("report22_loggers_exist_yes")} />
                  <FormControlLabel className="settingsemployeenamelabel" value="report22_loggers_exist_no" control={<Radio />} label={t("report22_loggers_exist_no")} />
                </RadioGroup>
              </FormControl>
              
              {loggbtn === true ?
              <FormControl sx={{ marginLeft: 0.2 }}>
                <FormLabel className="reportsinfoslabel" id="loggerFunctioning">{t("report22_loggers_function")}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="POS"
                  defaultValue="report22_loggers_function_yes"
                  name="loggerFunctioning"
                  onChange={handleLoggerClick2}
                >
                  <FormControlLabel className="settingsemployeenamelabel" value="report22_loggers_function_yes" control={<Radio />} label={t("report22_loggers_function_yes")}/>
                  <FormControlLabel className="settingsemployeenamelabel" value="report22_loggers_function_no" control={<Radio />} label={t("report22_loggers_function_no")}
                   />
                </RadioGroup>
              </FormControl>
              : null}
              {/* : null} */}
                {/* : null */}

              <Box marginTop={2} >
        <FormLabel className="reportsinfoslabel">{t("report22_temperature")}</FormLabel>
        <FormGroup>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_temperature_too_high")} name="Temperature too high" onChange={handleTempChange}/>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_temperature_deicing")} name="De-icing necessary" onChange={handleTempChange}/>
          <FormControlLabel control={<Checkbox />} label={ <TextField  id="tempOther" label={t("report22_temperature_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}} size="small" onChange={handleDefectsChange}/>} />
        </FormGroup>
      </Box>
      <Box marginTop={2} >
        <FormLabel className="reportsinfoslabel">{t("report22_food_quality")}</FormLabel>
        <FormGroup>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_food_quality_food_complaints")} name="Food complaints" onChange={handleFoodChange}/>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_food_quality_expiry_date")} name="Products over expiry date" onChange={handleFoodChange}/>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_food_quality_packaging")} name="Packaging issues" onChange={handleFoodChange}/>
          <FormControlLabel  control={<Checkbox />} label={ <TextField  id="foodOther" label={t("report22_food_quality_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}} size="small" />} onChange={handleDefectsChange}/>
          
        </FormGroup>
      </Box>
      <Box marginTop={2} >
        <FormLabel className="reportsinfoslabel">{t("report22_defects")}</FormLabel>
        <FormGroup>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_defects_shelfs")} name="Shelfs/protection defect" onChange={handleDefectsChange}/>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_defects_ubk_skb")} name="UBK/SBK defect" onChange={handleDefectsChange}/>
          <FormControlLabel className="settingsemployeenamelabel" control={<Checkbox />} label={t("report22_defects_signs")} name="Signs/material defect" onChange={handleDefectsChange}/>
          <FormControlLabel  control={<Checkbox />} label= { <TextField  id="defectsOther" label={t("report22_defects_other")} name="Other" variant="outlined" color="info" sx={{backgroundColor:"#F5F5F5"}} size="small" />} onChange={handleDefectsChange}/>
        </FormGroup>

        <br></br>
        <FormControl >
          <FormLabel className="reportsinfoslabel" id="issuesFixed">{t("report22_able_to_remove")}</FormLabel>
          <Typography className="reportsinfofadelabel"color={"#bdbdbd"}> {t("report22_ableo_to_remove_infotext")}</Typography>
          <RadioGroup
            row
            aria-labelledby="POS"
            defaultValue="report22_able_to_remove_yes"
            name="issuesFixed"
            onClick={handleIssueClick}
          >
            <FormControlLabel className="settingsemployeenamelabel" value="report22_able_to_remove_yes" control={<Radio />} label={t("report22_able_to_remove_yes")} />
            <FormControlLabel className="settingsemployeenamelabel" value="report22_able_to_remove_no" control={<Radio />} label={t("report22_able_to_remove_no")} />
            
          </RadioGroup>
        </FormControl>
        <br></br>
        <FormControl >
          <FormLabel sx={{marginTop:2}} className="reportsinfoslabel" id="createJiraTicket">{t("report22_create_jira")}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="POS"
            defaultValue="report22_create_jira_no"
            name="createJiraTicket"
            onClick={handleJiraClick}
          >
            <FormControlLabel className="settingsemployeenamelabel" value="report22_create_jira_yes" control={<Radio />} label={t("report22_create_jira_yes")} />
            <FormControlLabel className="settingsemployeenamelabel" value="report22_create_jira_no" control={<Radio />} label={t("report22_create_jira_no")} />
            
          </RadioGroup>
        </FormControl>
      
     

      </Box>
              
              </>
         : null }

      
        

 
      </Grid>
      
      <Divider sx={{ marginTop: 2, maxWidth: 750 }} />
      <Box
                m={1}
                display="flex"
                marginTop={2}
                justifyContent="center"
                alignItems="center"
                
            >
                <Link href="/Reports/Report11" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    width:120,
                    fontWeight:600,
                    margin:2}} 
                    disableElevation
                >
                     {t( "report22_back")}
                </Button></Link>
                {/* ///Reports/Report23 */}
                {/* <Link href="#" sx={{  color: "white" }}><Button 
                    variant="contained" 
                    sx={{textTransform:"none", 
                        backgroundColor:"#004bA0",
                        width:156,
                        fontWeight:550}} 
                    disableElevation
                    onClick={handleNextClick}
                 >
                      Next
                </Button></Link> */}
                <Button 
                    variant="contained" 
                    sx={{textTransform:"none", 
                        backgroundColor:"#004bA0",
                        width:156,
                        fontWeight:550}} 
                    disableElevation
                    onClick={handleNextClick}
                 >
                     {t( "report22_next")}
                </Button>

                <Modal
        open={warning}
        onClose={handleWarningClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{left:'50%'}}
      >
       <Card sx={style}>
        <CardContent>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
            
          {t("report22_warning_message_infotext1")}
          
          </Typography>
          
          <img
              style={{ height: 80, width: 80,marginLeft:"30%", marginTop: 20 }}
              src={W1}
              alt="pic"
              
              />
              <br></br>
          <Typography align="center" id="modal-modal-title" variant="h6" component="h2">
          {t("report22_warning_message_infotext2")}
            </Typography>
            </CardContent>

              <CardActions style={{ width: 500, marginTop: 20, marginLeft:0 }}>

            <Link href="/Reports" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    // width:156,
                    fontWeight:600,
                    // margin:2
                }} 
                    disableElevation
                >
                    {t("report22_warning_message_back")}
                </Button></Link>
                <Link href="/Reports/Report22" sx={{  color: "white" }}><Button 
                    variant="outlined" 
                    sx={{textTransform:"none", 
                        // width:156,
                        fontWeight:550}} 
                    disableElevation
                 >
                    {t("report22_warning_message_finish")}
                </Button></Link>
            </CardActions>

        </Card>
       
      </Modal>
            </Box>
      </Box>
      </Box>
    </>
  );
};
