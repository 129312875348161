import React from "react";
import Header from "./Header";
import { styled, useTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { Padding } from "@mui/icons-material";
const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 10,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

export const Layout = (props) => {
  const theme = useTheme();
  // console.log("Hii", props);
  return (
    <>
      {/* <Header /> */}
      <Main
        theme={theme}
        handleLeftDrawerToggle={"open"}
        className="mainTheme"
        sx={{backgroundColor: '#F5F5F5',
         
        }}
      >
        {props.children}
      </Main>
    </>
  );
};
