
import { Urls } from "./Urls";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./themes/style.css";
import React, { useEffect, useTransition } from "react";
import {initReactI18next}  from 'react-i18next'
import i18n from "./i18n/config";

function App(props) {
  const theme = useTheme();
  const [lang, setLang] = React.useState("de")
  const {t } = useTransition()
  // useEffect(() => {
  //   localStorage.setItem('mylang', JSON.stringify(lang));
  // }, [lang]);
  useEffect(() => {
    const mylang = JSON.parse(localStorage.getItem('mylang'));
    if (mylang) {
     i18n.changeLanguage(mylang);
    }
  }, []);
  return (
    <initReactI18next i18n={i18n}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Urls {...props}  lang={lang} setLang={ setLang } />
        
      </ThemeProvider>
    </StyledEngineProvider>
    </initReactI18next>
  );
}

  // const TOKEN_RENEWAL_ENDPOINT =
     

export default App;
