import { Chillerslider } from "../../Components/chillerslider";
import { chillerImages } from "../../Assets";
import React, { useState } from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs'
import SubHeader from "../../Layout/Subheader";
import { height, maxHeight } from "@mui/system";
import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import * as data_service from "../../Services/Data"
import { t } from "i18next";

export const Data2 = () => {


const [images,setImages] = React.useState(null);

const posId = localStorage.getItem("session_posId")
React.useEffect(() => {
  data_service.getImages(setImages, posId);
}, []);
console.log(images)


let chillerImages = []
if (images !== null)
{
  images.map((item)=> {
    chillerImages.push(item.sharepointUrl)
  } )
}
console.log(chillerImages)

return (
  <><SubHeader backbutton={true} btnUrl="/Data" title={t("data2_images")} />
  
  <Card elevation='none'>
  <div style={{
    height: "80vh",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <div style={{
      
      width: '500px',
      backgroundColor: "#fff",
      padding: '15px',
      
    }}>
      
      <Chillerslider images={chillerImages} />
      
    </div>
  </div>
  </Card>
  
  </>
)
}