import { Button, Paper, Table, Grid, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";
import {Box} from "@mui/material";
import SubHeader from "../Layout/Subheader";
import Fab from "@mui/material/Fab";


import E1 from "../Assets/Images/Error/Vector3.png";
import E2 from "../Assets/Images/Error/Vector2.png";


export const Error = () => {
    return(
        <><SubHeader /*backbutton={true} btnUrl="/Dashboard2" title="Contacts"*/ />
        <Box sx={{marginLeft:3,marginBottom:3,marginRight:3}}>
        <Typography align="center">
        <img variant="h1"
              style={{ height: 260, width: 260, marginTop: 20 }}
              src={E1}
              alt="pic" />
              </Typography>
             
              <Typography variant="h4" align="center" color="red" >
              <img
              
              style={{ height: 60, width: 60, marginTop: 20 }}
              src={E2}
              alt="pic" />
              Login Failed
              
              </Typography>  
              <br></br>
              <Typography variant="h6" align="center" >
                
###Error occurred during Login. Please contact your system administrator###
              </Typography>
              
        
        </Box>
        
        </>
    );
}