import React from "react";
import {
  Button,
  CardHeader,
  Divider,
  Paper,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { Card } from "@mui/material";
import { Box } from "@mui/system";
import SubHeader from "../../Layout/Subheader";
import { getReports } from "../../Services/Reports";
import * as reports_service from "../../Services/Reports";
import Fab from "@mui/material/Fab";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
export const Reports = () => {
  const {t} = useTranslation()
  const [reports, setReports] = React.useState(null);
  const posId = localStorage.getItem("session_posId")
  React.useEffect(() => {
    reports_service.getallReports(setReports, posId);
  }, []);
  console.log(reports)

  const alertForposId = () => {
    alert("Select a costcenter to continue")
  }
  //  const  testvar = [1,2,3,4,5,6]
  //  {
  //     testvar.map((item)=>{
  //         return (
  //             <>
  //             <Typography variant="subtitle1" sx={{fontWeight:550}}>
  //         {item}
  //     </Typography>
  //             </>
  //         )
  //     })
  // }
  const access_token=localStorage.getItem("access_token")
    
  const refresh_token=localStorage.getItem("refresh_token")
  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`
  console.log("reportsov", reports)
  return (
    <>
    {/* TODO: translate */}
      <SubHeader backbutton={true} btnUrl={dashurl} title={t("reports_reports")} />
      <Box
        sx={{ marginTop: 3, marginLeft: 3, marginBottom: 6, marginRight: 3 }}
      >
        {
            reports?
            reports.length !== 0 ?
            reports.map((item,index)=>{
                return(<>
                <Card key={index} sx={{ borderRadius: 2, marginBottom: 4, boxShadow: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{marginTop:1, marginBottom:1}} className="reportoverviewheadingtlabel">
            {t("reports_reports")}
            </Typography>
          </Box>
          <Divider />
          <TableContainer component={Paper} className="contactstaticlabel" sx={{ boxShadow: "none" }}>
            <Table aria-label="simple-table">
              <TableBody>
                <TableRow key="1" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}
                  >
                    {/* TODO: translate */}
                    {t("reports_date")}
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0 }} className="contactdatalabel">
                    {item.submissionDateTime?item.submissionDateTime:"NA"}
                  </TableCell>
                </TableRow>
                <TableRow key="2" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0}}
                    className="contactstaticlabel"
                  >
                    {/* TODO: translate */}
                    {t("reports_user")}
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0 }} className="contactdatalabel">
                    {item.owner?item.owner.name:"NA"}
                  </TableCell>
                </TableRow>
                <TableRow key="3" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0}}
                    className="contactstaticlabel"
                  >
                    
                    {t("reports_pos-type")}
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0 }} className="contactdatalabel">
                    {item.posType === "report11_shop"?
                      t("report11_shop")
                      :
                      item.posType === "report11_chiller"?
                      t("report11_chiller")
                      :
                      item.posType === "report11_mochi-chiller"?
                      t("report11_mochi-chiller")
                      :"NA"}
                  </TableCell>
                </TableRow>  
                <TableRow key="4" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0}}
                    className="contactstaticlabel"
                  >
                    {/* TODO: translate */}
                    {t("reports_informed_team")}
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0 }} className="contactdatalabel">
                    {item.informTeam === "report12_contact_ops"?
                        t("report12_contact_ops")
                        :
                        item.informTeam === "report12_contact_ad"?
                        t("report12_contact_ad")
                        :
                        item.informTeam === "report12_contact_expansion"?
                        t("report12_contact_expansion")
                        :
                        item.informTeam === "report12_contact_marketing"?
                        t("report12_contact_marketing")
                        :
                        item.informTeam === "report12_contact_tdm"?
                        t("report12_contact_tdm")
                        :
                        item.informTeam === "report12_contact_qs"?
                        t("report12_contact_qs")
                        :
                        item.informTeam === "report12_contact_incident"?
                        t("report12_contact_incident")
                        :"NA"}
                  </TableCell>
                </TableRow>
                <TableRow key="5" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="contactstaticlabel"
                    sx={{ borderBottom: 0}}
                  >
                    Title
                  </TableCell>
                  <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0}}>
                     NA
                  </TableCell>
                </TableRow>
                <TableRow key="6" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="contactstaticlabel"
                    sx={{ borderBottom: 0}}
                  >
                    Status
                  </TableCell>
                  <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0}}>
                     NA
                  </TableCell>
                </TableRow>
                <TableRow key="7" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="contactstaticlabel"
                    sx={{ borderBottom: 0}}
                  >
                    Comments
                  </TableCell>
                  <TableCell align="right" className="contactdatalabel" sx={{ borderBottom: 0}}>
                     NA
                  </TableCell>
                </TableRow>
                <TableRow key="8" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 0}}
                    className="contactstaticlabel"
                  >
                    Status
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: 0 }} className="contactdatalabel">
                    In progress
                  </TableCell>
                </TableRow>
                <TableRow key="9" sx={{ borderBottom: 0 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="contactstaticlabel"
                  >
                    
                    {t("reports_short_note")}
                  </TableCell>
                  <TableCell align="right" className="contactdatalabel">
                    {"note" in item ?item.note?item.note:item.additionalInformation:item.additionalInformation ? item.additionalInformation : "NA"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Divider sx={{ marginTop: 2, maxWidth: 750 }} /> */}




          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <Link href="/Reports/Overview" sx={{ color: "white" }}>
              <Button
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                  textTransform: "none",
                  width: 156,
                  fontWeight: 550,
                }}
                disableElevation
              >
                
                {t("reports_view_details")}
              </Button>
            </Link>
          </Box> */}



          
        </Card>
                </>)
            }):
            <Typography>{t("reports_no_tickets_created")}</Typography>
            :

            
            // "No reports available "
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        }
        {/* <Card sx={{ borderRadius: 2, marginBottom:4, boxShadow:3 }}>
            <Box
            sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <Typography variant="subtitle1" sx={{fontWeight:550}}>
                    Report
                </Typography>
            </Box>
            <Divider />
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table aria-label="simple-table">
                <TableBody>
                    <TableRow
                        key="1"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            Date
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            04.09.2022
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="2"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            User
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            Employee name
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="3"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            POS
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            Chiller
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="4"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            Informed Teams
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            Marketing
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="5"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                        Note
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                        Lorem ipsum dolor sit amet is just dummy text for this short note
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="6"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                        Status
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                        In progress
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Divider sx={{ marginTop: 2, maxWidth: 750 }} />
        <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Link href="/Reports/Overview" sx={{  color: "white" }}>
                    <Button sx={{ marginTop: 1, marginBottom: 1, textTransform: "none", width: 156, fontWeight: 550 }} disableElevation>
                    View details
                </Button>
                </Link>
            </Box>
    </Card>
    <Card sx={{ borderRadius: 2, marginBottom:4, boxShadow:3 }}>
            <Box
            sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <Typography variant="subtitle1" sx={{fontWeight:550}}>
                    Report
                </Typography>
            </Box>
            <Divider />
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table aria-label="simple-table">
                <TableBody>
                    <TableRow
                        key="1"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            Date
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            04.09.2022
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="2"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            User
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            Employee name
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="3"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            POS
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            Chiller
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="4"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                            Informed Teams
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                            Marketing
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="5"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                        Note
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                        Lorem ipsum dolor sit amet is just dummy text for this short note
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="6"
                        sx={{ borderBottom: 0 }}
                    >
                        <TableCell component="th" scope="row" sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}>
                        Status
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 0 }}>
                        In progress
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Divider sx={{ marginTop: 2, maxWidth: 750 }} />
        <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Link href="/Reports/Overview" sx={{  color: "white" }}>
                    <Button sx={{ marginTop: 1, marginBottom: 1, textTransform: "none", width: 156, fontWeight: 550 }} disableElevation>
                    View details
                </Button>
                </Link>
            </Box>
    </Card>
    {/* <Box
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={0}
    >
        <Link href="/Reports/Report" sx={{  color: "#004bA0" }}><Button 
                 
                 sx={{textTransform:"none", 
                     backgroundColor:"#FFFFFF", 
                     
                     width:156,
                     fontWeight:600,
                     margin:2}} 
                     disableElevation
             >
                 New Report
             </Button></Link>
             <Link href="/Reports/Report1" sx={{  color: "white" }}><Button 
                 variant="contained" 
                 sx={{textTransform:"none", 
                     backgroundColor:"#004bA0",
                     width:156,
                     fontWeight:550}} 
                 disableElevation
                 >
                     New report
             </Button></Link>
    </Box> */}
    </Box>
    
    <Box sx={{marginRight:1}}>
      <Fab
        sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(0),
            borderRadius:0,
            height:50,
            width: '100%',
            backgroundColor:"white"
          }}
          
        variant='extended'
        size="small"
        
      >
        { posId === "-1" ?          
          <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width: 360,
            fontWeight: 550,
          }}
          disableElevation
          onClick={alertForposId}
        >
        {/* TODO: translate */}
        {t("reports_new_report")}
        </Button>
        :
        <Link href="/Reports/report11_de" sx={{  color: "white" }}>
          <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width: 360,
            fontWeight: 550,
          }}
          disableElevation
        >
        {/* TODO: translate */}
        {t("reports_new_report")}
        </Button></Link>
        }
      </Fab>
      </Box>
    </>
    );
}
   
