import React, { useRef, useState } from "react";
import Webcam from "react-webcam";

export const Videorec = () => {

    const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const handleStartRecording = () => {
    setRecording(true);
    const stream = videoRef.current.video.srcObject;
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    setMediaRecorder(mediaRecorder);
    const chunks = [];
    mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: "video/webm" });
      const url = URL.createObjectURL(videoBlob);
      setVideoUrl(url);
    };
    mediaRecorder.start();
  };

  const handleStopRecording = () => {
    setRecording(false);
    mediaRecorder.stop();
  };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = videoUrl;
    a.download = "recorded-video.webm";
    a.click();
  };

  return (
    <div>
      <Webcam audio={false} ref={videoRef} />
      {recording ? (
        <button onClick={handleStopRecording}>Stop Recording</button>
      ) : (
        <button onClick={handleStartRecording}>Record Video</button>
      )}
      {videoUrl && (
        <button onClick={handleDownload}>Download Video</button>
      )}
    </div>
  );
}

