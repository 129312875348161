import axios from "axios";

export function getReports(setReports, posId) {
  const access_token = localStorage.getItem("access_token");
  axios
    .get(
      `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/reportOverview`,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log(res.data);
  const result = res.data.result
  let reports = []
  //reports.push(result)


  // console.log(result)
  result.map((item,index)=> {
    if(item.id === posId){
        reports = item.reports
    }
  })

  const sortedReports = reports.sort(
    (objB, objA) => Number(objB.submissionDateTime) - Number(objA.submissionDateTime),
  );
  
  setReports(sortedReports)

    });



}


export function sendQuickReport(data) {
  console.log("collected payload data :", data)
  const access_token = localStorage.getItem("access_token");
  console.log("access Token:", access_token)

  const posType = data.posType
  console.log("posType from the payload data:", posType)

  
  const subDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString()
  console.log("Date data:", subDate)

  let payload = {}
  if(posType === "Chiller") {
    console.log("postype is chiller, postype= ", posType);
    payload = Object.assign(payload,{
      "costCenter": data.costCenter,
      "isQuickReport": true,
      "submissionDateTime": subDate,
      "note": data.note,
      "informTeam": data.reports_informed_team,
      "posType": data.posType,
      "files": data.files

  
  }
  )
  console.log("postype chiller payload data", payload);

  const informTeam = data.informTeam
  console.log("Getting inform tean", informTeam);
  if(informTeam === 'report12_contact_ops'){
  console.log("Inform team is OPS: ", informTeam );
    payload = Object.assign(payload, {"opsTeamOption": data.opsTeamOption})
  console.log("Paylaod for Inform team is OPS : ", payload );

  }

  }
  else{
  console.log("Inform team is not ops: ", informTeam );
    payload = Object.assign(payload,{
      
      "costCenter": data.costCenter,
      "isQuickReport": true,
      "submissionDateTime": subDate,
      "note": data.note,
      "informTeam": data.informTeam,
      "posType": data.posType,
      "files": data.files,
      "topic": data.topic
  
  })
  console.log("payload when inform team is not OPS: " , payload);
  const informTeam = data.informTeam
  if(informTeam === 'report12_contact_ops'){
    payload = Object.assign(payload, {"opsTeamOption": data.opsTeamOption})
  }


  }
  
  console.log("final payload",payload)
  // const access_token = localStorage.getItem("access_token");
 
// https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/f01d2afaa83f40e9ad3fce094f60d493/reportsDescending/reportOverview
  axios
    .post(
      "https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_Report/",payload,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    ).then((res) => {
      console.log("successful request", res)
    }).catch(err => {
      console.log("unsuccessful request:", err)
      alert("there was an error")

      })
}

export function getallReports(setReports, posId) {
  const access_token = localStorage.getItem("access_token");
  axios
    .post(
      `https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_CostCenter/${posId}/reportsDescending/reportOverview`, {},
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
      }
    )
    .then((res) => {
      console.log("@1234abcd",res.data);
  const result = res.data.result
  setReports(result)
})
}

// export function reportSubmission(data) {
//   console.log("data",data)
//   const access_token = localStorage.getItem("access_token");
//   const posType = data.posType;
//   const subDate = new Date(
//     new Date().getTime() - new Date().getTimezoneOffset() * 60000
//   ).toISOString();
//   let payload = {
//     costCenter: data.costCenter,
//     submissionDateTime: subDate,
//     posType: data.posType
//   }
//   if (posType === "Chiller") {
//     const addTechnicalInfo = data.addTechnicalInfo
//     if(addTechnicalInfo) {
//       let technical_data = {}
//       if (data.technical.createJiraTicket) {
//         technical_data = Object.assign(technical_data, {
//           sensorsExist: data.technical.sensorsExist,
//           sensorsFunctioning: data.technical.sensorsFunctioning,
//           loggerExists: data.technical.loggerExists,
//           loggerFunctioning: data.technical.loggerFunctioning,
//           technicalTemperatures: data.technical.technicalTemperatures,
//           foodQualities: data.technical.foodQualities,
//           defects: data.technical.defects,
//           issuesFixed: data.technical.issuesFixed,
//           createJiraTicket: data.technical.createJiraTicket
//         })
//       }
//       else {
//         technical_data = Object.assign(technical_data, {
//           sensorsExist: data.technical.sensorsExist,
//           sensorsFunctioning: data.technical.sensorsFunctioning,
//           loggerExists: data.technical.loggerExists,
//           loggerFunctioning: data.technical.loggerFunctioning,
//           technicalTemperatures: data.technical.technicalTemperatures,
//           foodQualities: data.technical.foodQualities,
//           defects: data.technical.defects,
//           issuesFixed: data.technical.issuesFixed
//         })
//       }
//       payload = Object.assign(payload, { addTechnicalInfo: data.addTechnicalInfo, technical: technical_data })
//     }
//     if(data.addAppearanceAssortmentInfo) {
//       let appear = data.appearance
//       if(appear.isPosCleanEnough === true){
//         delete appear.itemsToBeCleaned
//         delete appear.posCleanedDuringVisit
//       }
//       if(appear.issuesWithStorageArea === false){
//         delete appear.issuesWithStorageAreaText
//         delete appear.issuesWithStorageArea
//       }
//       console.log("appear",appear)
//       payload = Object.assign(payload,{
//         "addAppearanceAssortmentInfo": data.addAppearanceAssortmentInfo,
//         "appearance": appear,
//         "assortment":data.assortment
//       })
//     }
//     if (data.addFeedback) {
//       payload = Object.assign(payload, {
//         "addFeedback": true,
//         "feedback": data.feedback
//     })
//     }
//     //payload = Object.assign(payload,{"additionalInformation": "Some additional information","files": [ "57610f6deea64bddab2f53bac7e60625"]}) 
//   }
//   else {
//     const addTechnicalInfo = data.addTechnicalInfo
//     if(addTechnicalInfo) {
//       let technical_data = {}
//       technical_data = Object.assign(technical_data, {
//         technicalTemperatures: data.technical.technicalTemperatures,
//         foodQualities: data.technical.foodQualities,
//         defects: data.technical.defects,
//         issuesFixed: data.technical.issuesFixed
//       })
//       payload = Object.assign(payload, { addTechnicalInfo: data.addTechnicalInfo, technical: technical_data })
//     }
//     if(data.addAppearanceAssortmentInfo) {
//       let appear = data.appearance
//       if(appear.isPosCleanEnough === true){
//         delete appear.itemsToBeCleaned
//         delete appear.posCleanedDuringVisit
//       }
//       if(appear.issuesWithStorageArea === false){
//         delete appear.issuesWithStorageAreaText
//         delete appear.issuesWithStorageArea
//       }
//       console.log("appear",appear)
//       payload = Object.assign(payload,{
//         "addAppearanceAssortmentInfo": data.addAppearanceAssortmentInfo,
//         "appearance": appear,
//         "assortment":data.assortment
//       })
//     }
//     if (data.addFeedback) {
//       payload = Object.assign(payload, {
//         "addFeedback": true,
//         "feedback": data.feedback
//     })
//     }
//     //payload = Object.assign(payload,{"additionalInformation": "Some additional information","files": [ "57610f6deea64bddab2f53bac7e60625"]})
//   }
//   console.log("finalsubmit",payload)
//   payload = {
//     "costCenter": "4a8541560e1d47909f3a508b3a06a102",
//     "submissionDateTime": "2022-11-24T19:18:30+0200",
//     "posType": "Shop",
//     "addAppearanceAssortmentInfo": true,
//     "appearance": {
//         "isPosCleanEnough": false,
//         "itemsToBeCleaned": [
//             {
//                 "item": "Shelfs"
//             },
//             {
//                 "item": "Counter"
//             }            
//         ],
//         "posCleanedDuringVisit": false,
//         "issuesWithStorageArea": true,
//         "issuesWithStorageAreaText": "some issue with storage area",
//         "materialNeedToBeReplaced": true,
//         "materialsToBeReplaced": [
//             {
//                 "informTeamToReplace": true,
//                 "replacementType": "Wobbler"
//             },
//             {
//                 "informTeamToReplace": false,
//                 "replacementType": "Sachet-Container"
//             },
//             {
//                 "informTeamToReplace": true,
//                 "replacementType": "Box content",
//                 "boxContents": [
//                     {
//                         "content": "soy sauce"
//                     },
//                     {
//                         "content": "wasabi"
//                     }
//                 ]
//             }
//         ]
//     },
//     "assortment":{
//         "isPosFilledSufficiently": false,
//         "assortmentChangeRequests": [
//             {
//                 "option": "More variation/color"
//             },
//             {
//                 "option": "Not filled sufficiently"
//             },
//             {
//                 "option": "Other option"
//             }
//         ],
//         "requestedDays": [
//             {
//                 "requestedDay": "Monday"
//             },
//             {
//                 "requestedDay": "Thursday"
//             }            
//         ] 
//     }

// }
//   axios.post(
//     "https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_Report/",payload,
//     {
//       headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
//     }
//   ).then((res) => {
//     console.log(res)
//   }).catch(err => {
//     console.log(err)

//     })
// }

export function reportSubmission(data) {
  console.log("data",data)
  const access_token = localStorage.getItem("access_token");
  const posType = data.posType;
  const subDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  ).toISOString();
  let payload = {
    costCenter: data.costCenter,
    submissionDateTime: subDate,
    posType: data.posType
  }
  if (posType === "Chiller") {
    const addTechnicalInfo = data.addTechnicalInfo
    if(addTechnicalInfo) {
      let technical_data = {}
      if (data.technical.createJiraTicket) {
        technical_data = Object.assign(technical_data, {
          sensorsExist: data.technical.sensorsExist,
          sensorsFunctioning: data.technical.sensorsFunctioning,
          loggerExists: data.technical.loggerExists,
          loggerFunctioning: data.technical.loggerFunctioning,
          technicalTemperatures: data.technical.technicalTemperatures,
          foodQualities: data.technical.foodQualities,
          defects: data.technical.defects,
          issuesFixed: data.technical.issuesFixed,
          createJiraTicket: data.technical.createJiraTicket
        })
      }
      else {
        technical_data = Object.assign(technical_data, {
          sensorsExist: data.technical.sensorsExist,
          sensorsFunctioning: data.technical.sensorsFunctioning,
          loggerExists: data.technical.loggerExists,
          loggerFunctioning: data.technical.loggerFunctioning,
          technicalTemperatures: data.technical.technicalTemperatures,
          foodQualities: data.technical.foodQualities,
          defects: data.technical.defects,
          issuesFixed: data.technical.issuesFixed
        })
      }
      payload = Object.assign(payload, { addTechnicalInfo: data.addTechnicalInfo, technical: technical_data })
    }
    if(data.addAppearanceAssortmentInfo) {
      let appear = data.appearance
      if(appear.isPosCleanEnough === true){
        delete appear.itemsToBeCleaned
        delete appear.posCleanedDuringVisit
        delete appear.issuesWithStorageAreaText
        delete appear.issuesWithStorageArea
      }
      console.log("appear inside chiller",appear)
      payload = Object.assign(payload,{
        "addAppearanceAssortmentInfo": data.addAppearanceAssortmentInfo,
        "appearance": appear,
        "assortment":data.assortment
      })
    }
    if (data.addFeedback) {
      payload = Object.assign(payload, {
        "addFeedback": true,
        "feedback": data.feedback
    })
    }
    payload = Object.assign(payload,{"additionalInformation": data.additionalInformation}) 
  }
  else {
    const addTechnicalInfo = data.addTechnicalInfo
    if(addTechnicalInfo) {
      let technical_data = {}
      technical_data = Object.assign(technical_data, {
        technicalTemperatures: data.technical.technicalTemperatures,
        foodQualities: data.technical.foodQualities,
        defects: data.technical.defects,
        issuesFixed: data.technical.issuesFixed
      })
      payload = Object.assign(payload, { addTechnicalInfo: data.addTechnicalInfo, technical: technical_data })
    }
    if (data.addAppearanceAssortmentInfo === true) {
      // Assortment starts here
      let appear = data.appearance
      if(appear.isPosCleanEnough === true){
        delete appear.itemsToBeCleaned
        delete appear.posCleanedDuringVisit
        delete appear.issuesWithStorageAreaText
        delete appear.issuesWithStorageArea
      }
      console.log("appear inside shop",appear)
      payload = Object.assign(payload,{
        "addAppearanceAssortmentInfo": data.addAppearanceAssortmentInfo,
        "appearance": appear,
        "assortment":data.assortment
      })
    }
    if (data.addFeedback) {
      payload = Object.assign(payload, {
        "addFeedback": true,
        "feedback": data.feedback
    })
    }
    //payload = Object.assign(payload,{"additionalInformation": "Some additional information","files": [ "57610f6deea64bddab2f53bac7e60625"]})
    payload = Object.assign(payload,{"additionalInformation": data.additionalInformation})
  }
  if (data.hasOwnProperty('files'))
  {
    payload = Object.assign(payload,{"files": data.files})
  }

  console.log("finalsubmit",payload)
  axios.post(
    "https://backend.dev.commsapp.eathappy.structr.com/structr/rest/EH_CRM_Report/",payload,
    {
      headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'application/json' },
    }
  ).then((res) => {
    console.log(res)
  }).catch(err => {
    console.log(err)
    })
}


function dataURLtoFile(dataurl, filename) {
 
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}



export function uploadFile(file,setFileId){
  const access_token = localStorage.getItem("access_token");
  // var data = new FormData();
  var dfile = dataURLtoFile(file,'upload.png');
console.log(dfile);
  var data = {
    "file": dfile
  }
  // data.append("file",file)
  console.log(data,"datakkk")
  axios
    .post(
      " https://backend.dev.commsapp.eathappy.structr.com/structr/upload/",data,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'multipart/form-data;' },
      }
    ).then((res) => {
      console.log(res,"uploadggg",res.data)
      
      setFileId(res.data)
    }).catch(err => {
      console.log(err,"upladhhh")

      })

}




///////////////////////////////////////////////////////////////




export function uploadVideo(video,setFilevidid){
  const access_token = localStorage.getItem("access_token");
  // var data = new FormData();
  const formData = new FormData();
  formData.append("file", video, "video.mp4");

  
  axios
    .post(
      " https://backend.dev.commsapp.eathappy.structr.com/structr/upload/",formData,
      {
        headers: { Authorization: `Bearer ${access_token}`,'Content-Type':'multipart/form-data;' },
      }
    ).then((res) => {
      console.log(res,"new video ulpoad convert",res.data)
      
      setFilevidid(res.data)
    }).catch(err => {
      console.log(err,"upladhhh")

      })

}





