import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Card,
  Modal,
  CardActions,
  CardContent,

} from "@mui/material";
import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,Link
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import W1 from "../../Assets/Images/Popups/Vector2.png";



import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import SubHeader from "../../Layout/Subheader";




export const Overview = () => {
 
    


  return (
    <>
    <SubHeader backbutton={true} btnUrl="/Reports" title="Report details" />
      
            
        <TableContainer  sx={{ boxShadow: "none", }}>
        <Box marginLeft={1} marginTop={2}>
        <Typography className="reportoverviewheadingtlabel">Overview</Typography>
      <br></br>
      </Box>
                    <Box sx={{ padding: 1 }}>

                        <Table aria-label="simple-table" sx={{ marginBottom: 3 }} >
                            <TableBody>
                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      POS:
                                                    </TableCell>
                                                    <TableCell align="right" className="reportdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      2315
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>
                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Date:
                                                    </TableCell>
                                                    <TableCell align="right" className="reportdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      15/02/2023 15:30:45
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>
                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Informed Teams:
                                                    </TableCell>
                                                    <TableCell align="right" className="reportdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Logistics
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>
                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Title:
                                                    </TableCell>
                                                    <TableCell align="right" className="reportdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Some Issue
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>
                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Details:
                                                    </TableCell>
                                                    <TableCell align="right" className="reportdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Some Detail
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>
                                                <TableRow>
                                                    
                                                    
                                                    <TableCell component="th" scope="row" className="contactstaticlabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Status
                                                    </TableCell>
                                                    <TableCell align="right" className="reportdatalabel" sx={{ borderBottom: 0, padding: "8px" }}>
                                                      Open
                                                    </TableCell>

                                                        
                                                    
                                                </TableRow>
                                            </TableBody>
                        </Table>
                    </Box>
                    <Box marginLeft={1} marginTop={2} >
        <Typography className="reportoverviewheadingtlabel">Images</Typography>
          <Link underline="always" color="inherit">Add new</Link>
      <br></br>
      </Box>
      <Box marginLeft={1} marginTop={2}>
        <Typography className="reportoverviewheadingtlabel">Comments</Typography>
      <br></br>
      </Box>
      <Box>
      <TextField multiline rows={4}
                sx={{ backgroundColor: "#F5F5F5", marginTop: 1, marginLeft:1, minWidth:340, }}
              ></TextField>
      </Box>
      <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width: 160,
            fontWeight: 400,
            marginTop:2,
            marginLeft:1,
            fontSize:10,
            borderRadius:3,
            height:60, marginBottom:5
          }}
          disableElevation
        >
        Add new comment
        </Button>
            </TableContainer>     
            
      

    

      
    </>
  );
}
