import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,Link
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";



import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import SubHeader from "../../Layout/Subheader";


export const Report1 = () => {

    const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

    const handleChange = (newValue) => {
      setValue(newValue);
    };

  return (
    <>
    <SubHeader backbutton={true} btnUrl="/Reports" title="New report" />
    <Box sx={{marginLeft:3,marginBottom:3,marginRight:3}}>
      {" "}
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: 2,
          marginLeft: 0.2,
        }}
        xs={12}
          sm={6}
      >
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
          xs={6}
          sm={6}
        >
          Employee
          <TextField
            sx={{ backgroundColor: "#F2F5F7", marginTop: 1 }}
          ></TextField>
        </Grid>
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
          xs={6}
          sm={6}
        >
          POS
          <TextField
            sx={{ backgroundColor: "#F2F5F7", marginTop: 1 }}
            select
          ></TextField>
        </Grid>
        </Grid>
        <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: 2,
          marginLeft: 1,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          color: "#4D5766",
          fontWeight: 400,
        }}
        xs={12}
          sm={6}
      >
      <Grid item
          sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
          xs={6}
          sm={6}>
      <DesktopDatePicker
          label="Date"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
         </Grid>
         <Grid item
          sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
          xs={6}
          sm={6}>
        <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
         </Grid>
         </Grid>
        </LocalizationProvider>
      </Grid>
      <Divider sx={{ marginTop: 2, maxWidth: 750 }} />
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", maxWidth: 750 }}
      >
        <Table aria-label="simple-table">
          <TableBody>
            <TableRow key="1" sx={{ borderBottom: 0 }}>
              <TableCell
                component="th"
                scope="row"
                sx={{ borderBottom: 0, fontWeight: 700, minHeight: 120 }}
              >
                Contact person
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderBottom: 0,
                  minHeight: 120,
                  color: "#003DA6",
                  fontWeight: 700,
                }}
              >
                Request Change
              </TableCell>
            </TableRow>
            <TableRow key="2" sx={{ borderBottom: 0 }}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderBottom: 0,
                  fontWeight: 550,
                  color: "#4D5766",
                  minHeight: 120,
                }}
              >
                Shop manager
              </TableCell>
              <TableCell
                align="right"
                sx={{ borderBottom: 0, minHeight: "50%" }}
              >
                Isolde Großfrau
              </TableCell>
            </TableRow>
            <TableRow key="3" sx={{ borderBottom: 0 }}>
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: 550, color: "#4D5766", borderBottom: 0 }}
              >
                Phone
              </TableCell>
              <TableCell
                align="right"
                sx={{ borderBottom: 0, color: "#003DA6", fontWeight: 400 }}
              >
                030548961520
              </TableCell>
            </TableRow>
            <TableRow key="4" sx={{ borderBottom: 0 }}>
              <TableCell
                component="th"
                scope="row"
                sx={{ borderBottom: 0, fontWeight: 550, color: "#4D5766" }}
              >
                Email
              </TableCell>
              <TableCell
                align="right"
                sx={{ borderBottom: 0, color: "#003DA6", fontWeight: 400 }}
              >
                i.grossfrau@rewe-berlin.de
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ marginTop: 1, maxWidth: 750 }} />
      <Box marginLeft={1} marginTop={2}>
        <Typography
          sx={{ fontWeight: 700, fontFamily: "Moderat", fontSize: "large" }}
        >
          Assortment
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 1,
        }}
      >
        <FormControl sx={{ marginLeft: 0.2 }}>
          <FormLabel id="POS">POS filled sufficiently</FormLabel>
          <RadioGroup
            row
            aria-labelledby="POS"
            defaultValue="Yes"
            name="POS-filled"
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Box marginTop={2} marginLeft={1}>
        <FormLabel>Adaptations</FormLabel>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="Maki" />
          <FormControlLabel control={<Checkbox />} label="Nigiri" />
          <FormControlLabel control={<Checkbox />} label="Inside out" />
        </FormGroup>
      </Box>
      <Box marginTop={2} marginLeft={1}>
        <FormLabel>Type</FormLabel>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="Maki-Cucumber" />
          <FormControlLabel control={<Checkbox />} label="Maki-Avocado" />
          <FormControlLabel control={<Checkbox />} label="Maki-Salmon" />
          <FormControlLabel
            control={<Checkbox />}
            label="Inside Out Spicy Tuna"
          />
          <FormControlLabel control={<Checkbox />} label="Inside Out Vegan" />
        </FormGroup>
      </Box>
      <Box marginTop={2} marginLeft={1}>
        <FormLabel>Other assortment changes</FormLabel>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="More Veggie" />
          <FormControlLabel control={<Checkbox />} label="More Vegan" />
          <FormControlLabel control={<Checkbox />} label="More Dessert" />
        </FormGroup>
      </Box>
      <Box marginTop={2} marginLeft={1}>
        <FormLabel>Requested Days</FormLabel>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="Monday" />
          <FormControlLabel control={<Checkbox />} label="Tuesday" />
          <FormControlLabel control={<Checkbox />} label="Wednesday" />
          <FormControlLabel control={<Checkbox />} label="Thursday" />
          <FormControlLabel control={<Checkbox />} label="Friday" />
        </FormGroup>
      </Box>

      
      <Grid
        item
        sx={{ display: "flex", flexDirection: "column", color: "#4D5766" }}
        xs={12}
        sm={6}
      >
        <Link href="/Reports/Report2" sx={{  color: "White" }}><Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width: '100%',
            fontWeight: 550,
            marginTop: 3,
          }}
          disableElevation
        >
          
           Next
        </Button></Link>
      </Grid>
      </Box>
    </>
  );
};
