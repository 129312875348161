import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Link,
  Container,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SubHeader from "../../Layout/Subheader";
import S1 from "../../Assets/Images/Popups/Vector1.svg";

import Modal from "@mui/material/Modal";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DoneIcon from "@mui/icons-material/Done";
import AddLinkIcon from "@mui/icons-material/AddLink";
import W1 from "../../Assets/Images/Popups/Vector2.svg";
import { useNavigate } from "react-router-dom";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { useLocation } from "react-router-dom";
import Camerashortreport from "../../Components/Camerashortreport";
import * as rep from '../../Services/Reports'
import { useRef } from "react";
import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import Webcam from "react-webcam";
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { useTranslation } from "react-i18next";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StopCircleIcon from '@mui/icons-material/StopCircle';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  width: 340,
  
  facingmode: FACING_MODE_ENVIRONMENT,
};

export const Report12 = () => {

  const {t} = useTranslation()
  const [camopen, setCamopen] = React.useState(false);
  const [fileid,setFileid] = React.useState(null);
  const [videoid,setVideoid] = React.useState(null);
  const handleClickOpen = () => {
    setCamopen(true);
  };



  //for video
  const [videopen, setVideopen] = React.useState(false);
  const [filevidid,setFilevidid] =React.useState(false);
  const handleClickVidOpen = () => {
    setVideopen(true);

  };



  


  const location = useLocation()
  console.log("location", location)


  
  //uncomment for dev
  const {dataset} = location.state
console.log("inside12",dataset)




  const [values, setValues] = React.useState(null);
  const handleChange = (newValue) => {
    setValues(newValue);
  };

  const [opsbtn,setOpsbtn] = React.useState(false)

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleSubmit = () => {

    let note = document.getElementById('shortrepnote').value || "NA"
        
        //uncomment for dev
        console.log("Final note:", note)
        var finalset11 = {}
    // console.log(123, values);
    var fileset = []
    let finalset111 = {}
    let finalset = {}
    console.log("test file id:", fileid)
    if (fileid !== null && filevidid !==null) {
     fileset.push(fileid) 
     fileset.push(filevidid)
     console.log(fileset)
     finalset11 = Object.assign(dataset, {"note":note,"files":fileset})
    } else if
      (fileid !== null && filevidid ===null) {
        fileset.push(fileid) 
        console.log(fileset)
        finalset11 = Object.assign(dataset, {"note":note,"files":fileset})
    }
    else if (fileid === null && filevidid !==null){
      fileset.push(filevidid)
      console.log(fileset)
     finalset11 = Object.assign(dataset, {"note":note,"files":fileset})
    } 
    else {
      finalset11=dataset
    }
    console.log("last submit check 777777777777777:", finalset11)
    console.log("Collected values:", values);
    finalset = Object.assign(finalset11, values)
    console.log("Final set of collected data:", finalset);
    rep.sendQuickReport(finalset)
  }



  const handleOpen = () => {
    handleSubmit()
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      navigate("/Reports");
    }, 3000);
  };
  const handleClose = () => setOpen(false);

  const [warning, setWarning] = React.useState(false);
  const handleWarningopen = () => {
    setWarning(true);
  };
  const handleWarningClose = () => setWarning(false);

  // const [yesbtn,setOpsbtn] = React.useState(true)

  // const handleYesClick = (event) =>{
  //     const yesvalue = event.target.value
  //     if(opsvalue === 'Yes'){
  //       setOpsbtn(true)

  //     }
  //     else{
  //     setOpsbtn(false)

  //     }
  //   }


  const [cameraclick, setCameraclick] = React.useState(false);
  const camOpen = () => {
    setCameraclick(true);
    
    
}


const [videorec, setVideorec] = React.useState(false);
const vidOpen = () => {
  setVideorec(true);
}



const handleCamClose = () => setCameraclick(false);

const handleVideoClose =() => setVideorec(false);

const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

const webcamRef = useRef(null);

const [url, setUrl] = useState(null);
const [camdisable,setCamdisable] = useState(true);


const capturePhoto = React.useCallback(async () => {
  const imageSrc = webcamRef.current.getScreenshot();
  setUrl(imageSrc);
  setCamdisable(false);
}, [webcamRef]);

  

const onUserMedia = (e) => {
  console.log(e);
};

const onUserVideo = (e) => {
  console.log(e);
};
const handleClick = React.useCallback(() => {
  setUrl(null);
  setCamdisable(true);
  setFacingMode((prevState) =>
    prevState === FACING_MODE_ENVIRONMENT
      ? FACING_MODE_USER
      : FACING_MODE_ENVIRONMENT
  );
}, []);

const handleClickclose = () => {
  console.log(url,"urltest")
  rep.uploadFile(url,setFileid);
  console.log(fileid,"pictureupload");
  setCamopen(false);
  setUrl(null);
  setCamdisable(true)
  
};

//for cam close
const camClose = () =>  {
  setCamopen(false);
};

//for vid close
const vidClose = () =>  {
  setVideopen(false);
};

///record video
const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [timer, setTimer] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleStartRecording = () => {
    setRecording(true);
    const stream = videoRef.current.video.srcObject;
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    setMediaRecorder(mediaRecorder);
    const chunks = [];
    mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: "video/webm" });
      const Vurl = URL.createObjectURL(videoBlob);
      setVideoUrl(videoBlob);
      console.log (Vurl, "videoMtest")
    };
    mediaRecorder.start();
  };

  const handleStopRecording = () => {
    setRecording(false);
    mediaRecorder.stop();
  };
/////////////////////////////////////////////////////////////////////////////////
  const handleDownload = () => {
    // const a = document.createElement("a");
    // a.href = videoUrl;

    console.log("what is video", videoUrl)
    console.log(videoUrl instanceof Blob)
    // a.download = "recorded-video.webm";
    // a.click();
    rep.uploadVideo(videoUrl,setFilevidid)
    setVideoUrl(null);
    setVideopen(false);
  };

  useEffect(() => {
    let intervalId;
    if (recording) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
      setTimer(0);
    };
  }, [recording]);


  return (
    <>
      <SubHeader
        backbutton={true}
        /*btnUrl="/Repor11"*/ title={t("report22_new_report")}
        modal_flag={true}
        warning_flag={true}
        bf={handleWarningopen}
      />
      <Box backgroundColor="white">
        <Box sx={{ marginLeft: 3,  marginRight: 3 }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#4D5766",
                
              }}
              xs={12}
              sm={6}
              
            >
              <Box><Typography className="contactstaticlabel">
              {t("report12_short_note_text")}</Typography></Box>
              <TextField id="shortrepnote"
                sx={{ backgroundColor: "#F5F5F5", marginTop: 1 }}
              ></TextField>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#4D5766",
                
              }}
              xs={12}
              sm={6}
            ><Box sx={{marginBottom:1}}><Typography className="contactstaticlabel">
              {t("report12_add_pictures")}</Typography></Box>
             

<Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    fontWeight: 550,
                  }}
                  onClick={handleClickOpen} 
                >
                  <AddAPhotoOutlinedIcon sx={{ color: "#003DA6" }} />
                  {t("report12_add_photo")}
                </Button>

                {
                  fileid ? "Picture uploaded" : null
                }
              
            </Grid>

            <Dialog
        open={camopen}
        onClose={handleCamClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="button-container">
        <Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>

        <Button className="right-button" onClick={camClose}><CloseIcon /></Button>      

</div>

<Box className="app-container">

        {camdisable?
          
        
          <Webcam
            ref={webcamRef}
            // audio={true}
            screenshotFormat="image/png"
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserMedia}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


      <Box sx={{marginLeft:"20%"}}>
       
<Grid container spacing={3.5} >
<Grid item xs={3}>
  {/* <Link href="/Tickets/form2"> */}
<Button onClick={handleClickclose}><CloudUploadIcon fontSize="large" sx={{color:'#239342'}}/></Button>
{/* </Link> */}
</Grid>
<Grid item xs={3}>
<Button  onClick={capturePhoto}><LinkedCameraIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid>
<Grid item xs={3}>
<Button  onClick={() => {
  setUrl(null);
  setCamdisable(true)
  }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#E53D30'}}/></Button>
</Grid>

{/* <Grid item xs={3}>
<Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#1E1E1E'}}/></Button>
</Grid> */}
</Grid>

      </Box>
      </Dialog>



{/* <Box sx={{padding: 2}}>
            <Grid item sx={{ display: "flex", flexDirection: "column", color: "#4D5766" , marginLeft: 1 }}  xs={12} sm={6}>
            <Box><Typography className="contactstaticlabel">
            Please add attachments if necessary</Typography></Box>
                
                    
                   
                    <label htmlFor="upload-photo"  >
                    <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    
                    />
  <Button color="primary" variant="outlined"
  sx={{
                    textTransform: "none",
                    width: '100%',
                    fontWeight: 550,
                    marginTop: 1 ,
                    }}
                    onClick={camOpen}  >
                    < AddAPhotoOutlinedIcon  />
                    Take a picture
                    </Button>
                   
                    </label>
<Modal
open={cameraclick}
onClose={camClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<div>
  <Camera/>
</div>
           </Modal>
            </Grid>
            </Box> */}


            {/* <Divider sx={{ marginTop: 4, maxWidth: 750 }} /> */}
<br></br>








{/* video record */}

<Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#4D5766",
                
              }}
              xs={12}
              sm={6}
            ><Box sx={{marginBottom:1}}><Typography className="contactstaticlabel">
              Please add video if needed</Typography></Box>
             

<Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    fontWeight: 550,
                  }}
                  onClick={handleClickVidOpen} 
                >
                  <VideoCallIcon sx={{ color: "#003DA6" }} />
                  Record video
                </Button>

                {
                  filevidid ? "Video uploaded" : null
                }
              
            </Grid>

            <Dialog
        open={videopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <div className="button-container">
        <Button sx={{backgroundColor:"#4CAF50",color:"white"}} onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
        
      
        <Button className="right-button" onClick={vidClose}><CloseIcon /></Button>      
        </div>
        
<Box className="app-container" >

        {camdisable?
          
        
          <Webcam
            ref={videoRef}
            audio={false}
            
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserVideo}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {/* {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )} */}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


      <Box sx={{marginLeft:"40%"}}>
      


     {/* <Grid item xs={3}>
      <Button  onClick={() => {
        setUrl(null);
         setCamdisable(true)
         }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
      </Grid> */}


      
      {videoUrl && (
        <Button onClick={handleDownload}><CloudUploadIcon fontSize="large" sx={{color:'#239342'}}/></Button>
      )}
      
      
      {recording ? (
         <div className="recording-container">
         <div className="recording-symbol" />
         <div className="timer">{formatTime(timer)}</div>
        <Button onClick={handleStopRecording}><StopCircleIcon fontSize="large" sx={{color:'#1E1E1E'}}/> </Button>
      </div>
      ) : (
        <Button onClick={handleStartRecording}><RadioButtonCheckedIcon fontSize="large" sx={{color:'#E53D30',marginLeft:"20%"}}/></Button>
      )}

{/* <Grid item xs={3}>
<Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid> */}

      </Box>
      </Dialog>












            <Grid
              container
              spacing={1}
              sx={{
                color: "#4D5766",
                display: "flex",
                flexDirection: "column",
                marginTop: 2,
                marginLeft: 2,
              }}
            >
              <FormControl sx={{ marginLeft: 0.2 }}>
                <FormLabel id="informTeam" > <Typography className="contactstaticlabel"> {t("report12_inform_team")} </Typography></FormLabel>
                <RadioGroup aria-labelledby="informTeam" name="informTeam"

                //uncomment for dev
                onChange={(event, newValue) => {
                  const yesvalue = event.target.value;
                  if(yesvalue === "report12_contact_ops"){
                      setOpsbtn(true);                      
                      const name = "informTeam";
                      setValues({
                        ...values,
                        [name]: newValue,
                      });
                    console.log(values);
                    }
                    else{
                      setOpsbtn(false);                   
                      const name = "informTeam";
                      setValues({
                        ...values,
                        [name]: newValue,
                      });
                    console.log(values);
                    }
                  }}
                
                >
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_contact_ops"
                    control={<Radio />}
                    label={t("report12_contact_ops")}

                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_contact_ad"
                    control={<Radio />}
                    label={t("report12_contact_ad")}
                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_contact_expansion"
                    control={<Radio />}
                    label={t("report12_contact_expansion")}
                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_contact_marketing"
                    control={<Radio />}
                    label={t("report12_contact_marketing")}
                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_contact_tdm"
                    control={<Radio />}
                    label={t("report12_contact_tdm")}
                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                  value="report12_contact_qs"
                  control={<Radio />} 
                  label={t("report12_contact_qs")} 
                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                  value="report12_contact_incident" 
                  control={<Radio />} 
                  label={t("report12_contact_incident")} 
                  />
                  
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* {yesbtn == true ?  */}
            <Grid
              container
              spacing={1}
              sx={{
                color: "#4D5766",
                display: "flex",
                flexDirection: "column",
                marginTop: 2,
                marginLeft: 2,
              }}
            >
              {
                opsbtn === true?
              <FormControl sx={{ marginLeft: 0.2 }}>
                <FormLabel id="opsTeamOption"><Typography className="contactstaticlabel">{t("report12__contact_refined")} </Typography></FormLabel>
                <RadioGroup aria-labelledby="opsTeamOption" name="opsTeamOption"
                //uncomment for dev
                  onChange={(event, newValue) => {
                    //alert(newValue);
                    const name = "opsTeamOption";
                    setValues({
                      ...values,
                      [name]: newValue,
                    });
                    console.log(values);
                  }}
                >
                  <FormControlLabel value="report12_refined_rl" control={<Radio />} label="RL" />
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_refined_sr_gl"
                    control={<Radio />}
                    label={t("report12_refined_sr_gl")}
                  />
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_refined_gl"
                    control={<Radio />}
                    label={t("report12_refined_gl")}
                  /> 
                  <FormControlLabel className="settingsemployeenamelabel"
                    value="report12_refined_jr_gl"
                    control={<Radio />}
                    label={t("report12_refined_jr_gl")}
                  />
                </RadioGroup>
              </FormControl>
              :null 
              } 
            </Grid>

            {/* : null } */}
          </Grid>
          <Divider sx={{ marginTop: 2, marginBottom: 2}} />
          <Box
            m={1}
            display="flex"
            marginTop={2}
            justifyContent="center"
            alignItems="center"
          >
            <Link href="/Reports/Report11" sx={{ color: "#004bA0" }}>
              {" "}
              <Button
                sx={{
                  textTransform: "none",
                  backgroundColor: "#FFFFFF",
                  color: "#004bA0",
                  width: 120,
                  fontWeight: 600,
                  margin: 2,
                }}
                disableElevation
              >
                {t("report12_back")}
              </Button>
            </Link>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#004bA0",
                width: 156,
                fontWeight: 550,
              }}
              disableElevation
              onClick={handleOpen}
            >
              <DoneIcon />
              {t("report12_submit")}
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  align="center"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {t("report12_success_message_infotext1")}
                </Typography>
                <img
                  style={{
                    height: 80,
                    width: 80,
                    marginLeft: "38%",
                    marginTop: 20,
                  }}
                  src={S1}
                  alt="pic"
                />
                <Typography
                  align="center"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                 {t("report12_success_message_infotext2")}
                </Typography>
              </Box>
            </Modal>
            <Modal
              open={warning}
              onClose={handleWarningClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Card sx={style}>
                <CardContent>
                  <Typography
                    align="center"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {t("report12_warning_message_infotext1")}
                  </Typography>

                  <img
                    style={{
                      height: 80,
                      width: 80,
                      marginLeft: "36%",
                      marginTop: 20,
                    }}
                    src={W1}
                    alt="pic"
                  />
                  <br></br>
                  <Typography
                    align="center"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {t("report12_warning_message_infotext2")}
                  </Typography>
                </CardContent>

                <CardActions style={{ width: 80, marginTop: 0 }}>
                  <Link href="/Reports" sx={{ color: "#004bA0" }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#FFFFFF",
                        color: "#004bA0",
                        width: 156,
                        fontWeight: 600,
                        // margin:2
                      }}
                      disableElevation
                    >
                      {t("report12_warning_message_back")}
                    </Button>
                  </Link>
                  <Link href="/Reports/Report12" sx={{ color: "white" }}>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        width: 156,
                        fontWeight: 550,
                      }}
                      disableElevation
                    >
                   {t( "report12_warning_message_finish")}
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Modal>
          </Box>
        </Box>
       
      </Box>

     
    </>
  );
};