import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Link, Typography } from "@mui/material";
import {Card} from "@mui/material";
import { Box} from "@mui/system";
import React from "react";
import SubHeader from "../../Layout/Subheader";
import Fab from "@mui/material/Fab";
import { getTickets } from "../../Services/Tickets";
import * as tickets_service from "../../Services/Tickets"

import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';



export const Tickets = () => {

    const { t } = useTranslation();

    const [tickets, setTickets] = React.useState(null);
    const [ticketsOrder, setTicketsOrder] = React.useState(null);
  const posId = localStorage.getItem("session_posId")
  React.useEffect(() => {
    tickets_service.getTicketsOrder(setTicketsOrder, posId);
  }, []);
  console.log(ticketsOrder) 


  const access_token=localStorage.getItem("access_token")
    

  const refresh_token=localStorage.getItem("refresh_token")

  const dashurl = `/Dashboard?access_token=${access_token}&refresh_token=${refresh_token}`


    return(
        <>
        
        <SubHeader backbutton={true} btnUrl={dashurl} title={t("tickets_jira-tickets")}  />
        <Box sx={{marginTop:3,marginLeft:3,marginBottom:6,marginRight:3}}>
        {
            ticketsOrder?
            ticketsOrder.length !== 0 ?
            ticketsOrder.map((item,index)=>{
                return(<>
        <Card sx={{ borderRadius: 2, marginBottom:4, boxShadow:3 }}>
            <Box sx={{padding:1}}>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table aria-label="simple-table" sx={{marginTop:1}}>
                    {item ?
                    <TableBody>
                        <TableRow
                            key="1"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                            {t("tickets_date")}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
                                {item.dateCreated}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="2"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                            {t("tickets_user")}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
                                {item.contact?item.contact.fullName
                                :null
                            }
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="3"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                            {t("tickets_pos-type")}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
                                {item.posType}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="4"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                            {t("tickets_continue_sales")}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
                                {item.canSalesContinue}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="5"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                            {t("tickets_summary")}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{borderBottom: 0, padding:"8px"}}>
                                {item.summary}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="6"

                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{padding:"8px"}}>
                            {t("tickets_status")}
                            </TableCell>
                            <TableCell align="right" className="contactdatalabel" sx={{padding:"8px"}}>
                                {item.status}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : "Keine Tickets für POS vorhanden"}
                </Table>
            </TableContainer>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Link href={item.jiraTicketUrl} target="_blank" sx={{  color: "white" }}>
                <Button sx={{ marginTop: 1, marginBottom: 1, textTransform: "none", width: 156, fontWeight: 550, color:"#003DA6" }} disableElevation>
                {t("tickets_view_details")}
                </Button>
                </Link>
            </Box>
            </Box>
        </Card>
        </>)
            }):
            <Typography> {t("tickets_no_tickets_created")}</Typography>
            : 
            // "Kein Ticket für den Benutzer gefunden"

            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        }
        {/* <Card sx={{ borderRadius: 2, marginBottom:4, boxShadow:3 }}>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table aria-label="simple-table">
                    <TableBody>
                        <TableRow
                            key="1"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                Date
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                04.09.2022
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="2"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                User
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Florian Bierschenk
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="3"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                POS name
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Chest
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="4"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                POS defect
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Yes
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="5"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                Summary
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Chest no longer de-ices
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="6"
                        >
                            <TableCell component="th" scope="row" sx={{ fontWeight: 550, color: "#4D5766" }}>
                                Status
                            </TableCell>
                            <TableCell align="right">
                                In processing
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Button sx={{ marginTop: 1, marginBottom: 1, textTransform: "none", width: 156, fontWeight: 550 }} disableElevation>
                    Look at the details
                </Button>
            </Box>
        </Card> */}
        {/* <Card sx={{ borderRadius: 2, marginBottom:4, boxShadow:3 }}>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table aria-label="simple-table">
                    <TableBody>
                        <TableRow
                            key="1"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                Date
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                04.09.2022
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="2"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                User
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Florian Bierschenk
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="3"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                POS name
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Chest
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="4"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                POS defect
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Yes
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="5"
                            sx={{ borderBottom: 0 }}
                        >
                            <TableCell component="th" scope="row" className="contactstaticlabel" sx={{borderBottom: 0, padding:"8px"}}>
                                Summary
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0 }}>
                                Chest no longer de-ices
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key="6"
                        >
                            <TableCell component="th" scope="row" sx={{ fontWeight: 550, color: "#4D5766" }}>
                                Status
                            </TableCell>
                            <TableCell align="right">
                                In processing
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Button sx={{ marginTop: 1, marginBottom: 1, textTransform: "none", width: 156, fontWeight: 550 }} disableElevation>
                    Look at the details
                </Button>
            </Box>
        </Card> */}
        {/* <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={0}
        >
            <Link href="/Tickets/form" sx={{  color: "white" }}><Button
                variant="contained" 
                sx={{textTransform:"none", 
                    backgroundColor:"#004bA0",
                    width:360,
                    fontWeight:550}} 
                disableElevation
            >
                 New ticket
            </Button></Link>
        </Box> */}
        </Box>
        <Box sx={{marginRight:1}}>
      <Fab
        sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(0),
            borderRadius:0,
            height:50,
            width: '100%',
            backgroundColor:"white"
          }}
          
        variant='extended'
        size="small"
        
      >
        <Link 
        // href="https://forms.microsoft.com/r/srfzTjQWjD" 
        href="/Tickets/form2"
        // target="_blank" 
        sx={{  color: "white" }}>
          <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#004bA0",
            width: 360,
            fontWeight: 550,
          }}
          disableElevation
        >
        {t("tickets_new_ticket")}
        </Button></Link>
      </Fab>
      </Box>
        </>
    );
}