import { useRef } from "react";
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { Button, Typography } from "@mui/material";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../Layout/Subheader";
import { Box, Container } from "@mui/system";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Fab from "@mui/material/Fab";
import {Grid} from "@mui/material";
import Modal from '@mui/material/Modal';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  width: 500,
  
  facingmode: FACING_MODE_ENVIRONMENT,
};
const Camerashortreport = () => {
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

  const webcamRef = useRef(null);

  const [url, setUrl] = useState(null);
  const [camdisable,setCamdisable] = useState(true);


  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
    setCamdisable(false);
  }, [webcamRef]);

    

  const onUserMedia = (e) => {
    console.log(e);
  };

  const handleClick = React.useCallback(() => {
    setUrl(null);
    setCamdisable(true);
    setFacingMode((prevState) =>
      prevState === FACING_MODE_ENVIRONMENT
        ? FACING_MODE_USER
        : FACING_MODE_ENVIRONMENT
    );
  }, []);

  return (
    <>
    
      
      <SubHeader
      // backbutton={true} btnUrl="/Tickets/form2" 
      
      // title="Camera" 
      />
      
        <Box >
        
        {camdisable?
          
        
          <Webcam
            ref={webcamRef}
            // audio={true}
            screenshotFormat="image/png"
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            onUserMedia={onUserMedia}
            mirrored={false}
            screenshotQuality={1}
          />
        : null
          }
        
        {url && (
            <div >
              <img src={url} alt="screenshot" />
            </div>
          )}
    
          </Box>
      
<br></br>
<br></br>
<br></br>
<br></br>


      <Box>
        {/* <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(0),
            borderRadius: 0,
            height: 80,
            width: "100%",
            backgroundColor: "white",
          }}
          variant="extended"
          size="small"
        > */}
          {/* <Button
            size="large"
            color="primary"
            variant="contained"
            component="span"
            onClick={() => setUrl(null)}
            sx={{
              textTransform: "none",
              fontWeight: 550,
              marginRight: "10%",
              marginTop: 2,
              marginBottom: 2,
              borderRadius: 500,
            }}
          ><Box>
            <LoopOutlinedIcon  />
            <Typography> Retake </Typography> </Box>
          </Button>

          <Button
            size="large"
            color="primary"
            variant="contained"
            component="span"
            onClick={capturePhoto}
            sx={{
              textTransform: "none",
              fontWeight: 550,
              marginRight: "10%",
              marginTop: 2,
              marginBottom: 2,
              borderRadius: 500,
            }}
          ><Box>
            <AddAPhotoOutlinedIcon />
          <Typography>  Click </Typography></Box>
          </Button> */}
        
<Grid container spacing={3.5} >
<Grid item xs={3}><Link href="/Reports/Report12">
<Button ><CloseIcon fontSize="large" sx={{color:'#004bA0'}}/></Button></Link>
</Grid>
<Grid item xs={3}>
<Button  onClick={() => {
  setUrl(null);
  setCamdisable(true)
  }}  ><DeleteForeverIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid>
<Grid item xs={3}>
<Button  onClick={capturePhoto}><LinkedCameraIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid>
<Grid item xs={3}>
<Button onClick={handleClick}><CameraswitchIcon fontSize="large" sx={{color:'#004bA0'}}/></Button>
</Grid>
</Grid>


          {/* <Button
            size="large"
            color="primary"
            variant="contained"
            component="span"
            onClick={handleClick}
            sx={{
              textTransform: "none",
              fontWeight: 550,
           
              marginTop: 2,
              marginBottom: 2,
              borderRadius: 500,
            }}
          ><Box>
            <CameraswitchIcon  />
           <Typography> Switch </Typography></Box>
          </Button> */}
        {/* </Fab> */}
      </Box>
      
    </>
  );
};
export default Camerashortreport;