import React from "react";
import { Button, Divider, Grid,TextField, Typography,Link } from "@mui/material";
import { Box } from "@mui/system";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SubHeader from "../../Layout/Subheader";
export const Report2 = () => {
    return(
        <><SubHeader backbutton={true} btnUrl="/Reports/Report1" title="New report" />
        <Box sx={{marginLeft:3,marginBottom:3,marginRight:3}}>
          <Box marginLeft={1} marginTop={2}>
                <Typography sx={{ fontWeight: 700, fontFamily: "Moderat", fontSize: "large" }}>
                    Appearance
                </Typography>
            </Box>
            <Grid container spacing={1} sx={{ display: "flex", flexDirection: "column", marginTop: 2, marginLeft: 1 }}>
                <FormControl sx={{ marginLeft: 0.2 }}>
                    <FormLabel id="POS-clean">POS clean</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="POS-clean"
                        name="POS-clean"
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Box marginTop={2} marginLeft={1}>
            <FormLabel>Reasons</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Surroundings dirty" />
                    <FormControlLabel control={<Checkbox />} label="Glass dirty" />
                    <FormControlLabel control={<Checkbox />} label="Shelfs dirty" />
                    <FormControlLabel control={<Checkbox />} label="Product dirty" />
                    <FormControlLabel control={<Checkbox />} label="Counter/Material dirty" />
                </FormGroup> 
            </Box>
            <Grid container spacing={1} sx={{ display: "flex", flexDirection: "column", marginTop: 2, marginLeft: 1 }}>
                <FormControl sx={{ marginLeft: 0.2 }}>
                    <FormLabel id="POS-manual">POS cleaned manually?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="POS-manual"
                        name="POS-manual"
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid container spacing={1} sx={{ display: "flex", flexDirection: "column", marginTop: 2, marginLeft: 1 }}>
                <FormControl sx={{ marginLeft: 0.2 }}>
                    <FormLabel id="material">Material to replace?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="material"
                        name="material"
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Box marginTop={2} marginLeft={1}>
            <FormLabel>Replacements</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Display" />
                    <FormControlLabel control={<Checkbox />} label="Wobbler" />
                    <FormControlLabel control={<Checkbox />} label="Counter signs" />
                    <FormControlLabel control={<Checkbox />} label="Satchet container" />
                    <FormControlLabel control={<Checkbox />} label="Chopsticks container" />
                </FormGroup> 
            </Box>
            <Grid container spacing={1} sx={{ display: "flex", flexDirection: "column", marginTop: 2, marginLeft: 1 }}>
                <FormControl sx={{ marginLeft: 0.2 }}>
                    <FormLabel id="inform">Inform relevant team?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="inform"
                        name="inform"
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Divider sx={{ marginTop: 2}} />
            <Box
                m={1}
                display="flex"
                marginTop={2}
                justifyContent="center"
                alignItems="center"
            >
                <Link href="/Reports/Report1" sx={{  color: "#004bA0" }}><Button 
                 
                    sx={{textTransform:"none", 
                    backgroundColor:"#FFFFFF", 
                    color:"#004bA0",
                    width:156,
                    fontWeight:600,
                    margin:2}} 
                    disableElevation
                >
                     Back
                </Button></Link>
                <Link href="/Reports/Report3" sx={{  color: "white" }}><Button 
                    variant="contained" 
                    sx={{textTransform:"none", 
                        backgroundColor:"#004bA0",
                        width:156,
                        fontWeight:550}} 
                    disableElevation
                 >
                      Next
                </Button></Link>
            </Box>
            </Box>
        </>
    );
}