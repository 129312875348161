export const chillerImages = [
    require('../Assets/Images/Chillers/chiller1.jpg'),
    require('../Assets/Images/Chillers/chiller2.jpg'),
    require('../Assets/Images/Chillers/chiller3.jpg'),
    require('../Assets/Images/Chillers/chiller4.jpg'),
    require('../Assets/Images/Chillers/chiller5.jpg'),
    require('../Assets/Images/Chillers/chiller6.jpg'),
    require('../Assets/Images/Chillers/chiller7.jpg'),
    require('../Assets/Images/Chillers/chiller8.jpg'),
    require('../Assets/Images/Chillers/chiller9.jpg'),
    require('../Assets/Images/Chillers/chiller10.jpg'),

]